
import { Component, Watch } from 'vue-property-decorator';
import SettingBase from './SettingBase';
import moment from 'moment';

@Component
export default class SettingDate extends SettingBase {
  private menu = false;
  private date: string | null = null;

  created() {
    this.date = this.setting.value as string;
  }

  get dateFormatted(): string {
    if (!this.date) return '';
    return moment(this.date).format('MMM D, YYYY')
  }

  @Watch('date')
  onDateChange(val: string | null) {
    if (val) {
      this.setting.value = moment(val).format('YYYY-MM-DD');
    }
  }
}

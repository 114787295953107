import List from './list';
import { SettingModel } from '@/models/setting';

export class SettingList extends List<SettingModel> {
  public mapData(data: any): SettingList {
    if (data && data.length > 0) {
      for (const s of data) {
        const setting = new SettingModel();
        setting.mapData(s);
        this.add(setting);
      }
    }
    return this;
  }
}


import { Component, Vue, Watch } from 'vue-property-decorator';
import { BuyingCalendarModel } from '@/models/buying_calendar';
import {
  IBuyingListItem,
  IBuyingList,
  IProduct,
  ISupplier,
  IViewMode,
  ISupplierGroup,
  DateRange
} from '@/models/buying_list';
import { StoreLocationList } from '@/collections/store_locations';
import { StoreLocationModel } from '@/models/store_location';
import { BuyingListModel } from '@/models/buying_list';
import { EventBus } from "@/main";

@Component({
  name: 'BuyingListSuggestion'
})
export default class BuyingListSuggestion extends Vue {
  private loading = false;
  private loadingLocations = false;
  private locations: StoreLocationModel[] = [];
  private storeLocationList = new StoreLocationList();
  private selectedLocationId: number | 'all-location' = 'all-location';
  // Data properties
  private viewMode: IViewMode = 'week';

  private suggestions: any = [];

  // Add this property to track loading state
  private creatingList: { [key: number]: boolean } = {};

  // Add this property to track expanded groups
  private expandedGroups: { [key: number]: boolean } = {};

  // Initialize with empty array instead of calling getDateRange()
  private _selectedDates: string[] = [];

  // Add new property for calendar loading state
  private calendarLoading = false;

  private displayMode: 'list' | 'calendar' = 'calendar';


  // Computed properties
  get selectedDateRange(): DateRange {
    return {
      from: new Date(this.selectedDates[0]),
      to: new Date(this.selectedDates[1])
    };
  }

  get selectedLocationName(): string {
    if (!this.selectedLocationId || this.selectedLocationId === 'all-location') {
      return 'all-location';
    }
    return this.locations.find(l => l.id === this.selectedLocationId)?.name || 'all-location';
  }

  public async created() {
    this.loading = true;
    try {
      this._selectedDates = this.getDateRange();
      await this.fetchLocations();
      await this.fetchBuyingCalendar();
    } finally {
      this.loading = false;
    }
  }

  public async fetchBuyingCalendar(): Promise<void> {
    this.loading = true;
    try {
      const buyingCalendarModel = new BuyingCalendarModel();
      this.suggestions = await buyingCalendarModel.fetchBuyingCalendar(
        this.viewMode, 
        this.selectedDateRange,
        this.selectedLocationName
      );
    } finally {
      this.loading = false;
    }
  }

  get getFormattedDateRange(): string {
    const [start, end] = this.selectedDates;
    return `${this.formatDate(new Date(start), 'short')} - ${this.formatDate(new Date(end), 'short')}`;
  }

  // Methods
  private formatDate(date: Date | string | null, format: 'short' | 'full' = 'short'): string {
    if (!date) return '-';
    try {
      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) return '-';
      
      const options: Intl.DateTimeFormatOptions = format === 'full' 
        ? { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        : { year: 'numeric', month: 'short', day: 'numeric' };
      
      return dateObj.toLocaleDateString('en-US', options);
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  }

  private formatDateKey(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  private formatCurrency(value: any): string {
    if (!value || value === 'null') return '-';
    try {
      const numValue = typeof value === 'string' ? parseFloat(value) : value;
      if (isNaN(numValue)) return '-';
      
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      }).format(numValue);
    } catch (error) {
      console.error('Error formatting currency:', error);
      return '-';
    }
  }

  private calculateOrderValue(quantity: number, unitPrice: number): string {
    return this.formatCurrency(quantity * unitPrice);
  }

  private calculateSupplierTotalOrderValue(products: IProduct[]): string {
    const total = products.reduce((sum, product) => 
      sum + (product.reorderQuantity * product.unitPrice), 0
    );
    return this.formatCurrency(total);
  }

  private getPeriodKey(date: Date): string {
    switch (this.viewMode) {
      case 'week':
        // Get the Monday of the week
        const monday = new Date(date);
        monday.setDate(monday.getDate() - monday.getDay() + 1);
        return this.formatDateKey(monday);
      case 'month':
        // Get the first day of the month
        return date.toISOString().slice(0, 7); // YYYY-MM
      default:
        return this.formatDateKey(date);
    }
  }

  private getPeriodRange(date: Date): { start: Date; end: Date } {
    const start = new Date(date);
    const end = new Date(date);

    switch (this.viewMode) {
      case 'week':
        // Set to Monday
        start.setDate(start.getDate() - start.getDay() + 1);
        // Set to Sunday
        end.setDate(end.getDate() - end.getDay() + 7);
        break;
      case 'month':
        // Set to first day of month
        start.setDate(1);
        // Set to last day of month
        end.setMonth(end.getMonth() + 1);
        end.setDate(0);
        break;
      default:
        // For daily view, start and end are the same
        break;
    }

    return { start, end };
  }

  private getFormattedPeriodLabel(date: Date): string {
    switch (this.viewMode) {
      case 'week':
        const { start, end } = this.getPeriodRange(date);
        return `Week of ${this.formatDate(start, 'short')} - ${this.formatDate(end, 'short')}`;
      case 'month':
        return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
      default:
        return this.formatDate(date);
    }
  }

  private async createBuyingList(supplier: any): Promise<void> {
    this.$set(this.creatingList, supplier.id, true);
    try {
      const newList = new BuyingListModel({
        name: `${supplier.name} - ${new Date().toLocaleDateString()}`,
        description: `Auto-generated from buying calendar suggestion`,
        supplierId: supplier.id,
        status: 'active',
        totalValue: supplier.totalOrderValue,
        locationId: this.selectedLocationId === 'all-location' ? undefined : this.selectedLocationId,
        items: supplier.products.map((product: any) => ({
          variantId: product.variant_id,
          productName: product['Item[Item Description]'],
          sku: product.product_sku,
          quantity: product['[inventory_replenishment]'],
          unitPrice: product['[average_unit_cost]'],
          totalPrice: product['[inventory_replenishment]'] * product['[average_unit_cost]']
        }))
      });

      await newList.save();
      this.$emit('success', 'Buying list created successfully');
      this.$router.push(`/buying-lists/${newList.id}`);
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Failed to create buying list',
        color: 'error'
      });
    } finally {
      this.$set(this.creatingList, supplier.id, false);
    }
  }

  private toggleGroup(supplierId: number): void {
    this.$set(this.expandedGroups, supplierId, !this.expandedGroups[supplierId]);
  }

  // on viewMode change
  @Watch('viewMode')
  async onViewModeChange(): Promise<void> {
    this._selectedDates = this.getDateRange();
    await this.fetchBuyingCalendar();
  }

  private isReorderDateNear(dateStr: string): boolean {
    const reorderDate = new Date(dateStr);
    const today = new Date();
    const diffDays = Math.ceil((reorderDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
    return diffDays <= 7;
  }

  private async fetchLocations(): Promise<void> {
    this.loadingLocations = true;
    try {
      await this.storeLocationList.fetch();
      this.locations = this.storeLocationList.items;
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Failed to load store locations',
        color: 'error'
      });
    } finally {
      this.loadingLocations = false;
    }
  }

  get locationOptions() {
    return [
      { id: 'all-location' as const, name: 'All Locations' },
      ...this.locations
    ];
  }

  private async handleLocationChange(): Promise<void> {
    await this.fetchBuyingCalendar();
  }

  // Add this computed property
  private isUnassignedSupplier(supplier: any): boolean {
    return supplier.id === 0 || !supplier.id || supplier.id === 'unassigned';
  }

  private goToSuppliers(): void {
    this.$router.push('/suppliers');
  }

  private showSupplierDialog = false;
  private selectedSupplierDetails: any = null;

  get weekDays(): string[] {
    switch (this.viewMode) {
      case 'day':
        return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      case 'week':
        return Array.from({ length: 8 }, (_, i) => {
          const startDate = new Date(this.selectedDates[0]);
          const weekStart = new Date(startDate);
          weekStart.setDate(startDate.getDate() + (i * 7));
          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);
          return `Week ${i + 1} (${this.formatDate(weekStart, 'short')} - ${this.formatDate(weekEnd, 'short')})`;
        });
      case 'month':
        return Array.from({ length: 6 }, (_, i) => {
          const date = new Date(this.selectedDates[0]);
          date.setMonth(date.getMonth() + i);
          return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
        });
    }
  }

  get calendarTitle(): string {
    const startDate = new Date(this.selectedDates[0]);
    const endDate = new Date(this.selectedDates[this.selectedDates.length - 1]);
    return `${this.formatDate(startDate, 'short')} - ${this.formatDate(endDate, 'short')}`;
  }

  get calendarDays(): Array<Array<{ 
    date: string; 
    dayNumber: number; 
    currentMonth: boolean;
    isToday: boolean;
    isSelected: boolean;
    dateRange?: { start: Date; end: Date };
  }>> {
    const weeks: Array<Array<any>> = [];
    const today = new Date();
    const startDate = new Date(this.selectedDates[0]);
    
    switch (this.viewMode) {
      case 'day':
        // Show next 7 days in a single row
        const dayWeek = [{
          date: this.formatDateKey(today),
          dayNumber: today.getDate(),
          currentMonth: true,
          isToday: true,
          isSelected: true
        }];
        
        for (let i = 1; i < 7; i++) {
          const nextDay = new Date(today);
          nextDay.setDate(today.getDate() + i);
          dayWeek.push({
            date: this.formatDateKey(nextDay),
            dayNumber: nextDay.getDate(),
            currentMonth: nextDay.getMonth() === today.getMonth(),
            isToday: false,
            isSelected: false
          });
        }
        weeks.push(dayWeek);
        break;

      case 'week':
        // Show next 8 weeks in a grid (2x4)
        let weekRow: any[] = [];
        for (let i = 0; i < 8; i++) {
          const weekStart = new Date(today);
          const dayOfWeek = weekStart.getDay();
          const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
          weekStart.setDate(weekStart.getDate() + daysToMonday + (i * 7));
          const weekEnd = new Date(weekStart);
          weekEnd.setDate(weekStart.getDate() + 6);

          weekRow.push({
            date: this.formatDateKey(weekStart),
            dayNumber: this.getWeekNumber(weekStart),
            currentMonth: true,
            isToday: i === 0,
            isSelected: i === 0,
            dateRange: { start: weekStart, end: weekEnd }
          });

          if (weekRow.length === 4) {
            weeks.push(weekRow);
            weekRow = [];
          }
        }
        if (weekRow.length > 0) {
          weeks.push(weekRow);
        }
        break;

      case 'month':
        // Show next 6 months in a grid (2x3)
        let monthRow: any[] = [];
        for (let i = 0; i < 6; i++) {
          const monthDate = new Date(today.getFullYear(), today.getMonth() + i, 1);
          const monthEnd = new Date(today.getFullYear(), today.getMonth() + i + 1, 0);
          
          // Format as YYYY-MM to match the suggestion period format
          const monthKey = `${monthDate.getFullYear()}-${String(monthDate.getMonth() + 1).padStart(2, '0')}`;

          monthRow.push({
            date: monthKey,
            dayNumber: monthDate.getMonth() + 1,
            currentMonth: true,
            isToday: i === 0,
            isSelected: i === 0,
            dateRange: { 
              start: monthDate, 
              end: monthEnd 
            }
          });

          if (monthRow.length === 3) {
            weeks.push(monthRow);
            monthRow = [];
          }
        }
        break;
    }
    
    return weeks;
  }

  private isToday(date: Date): boolean {
    const today = new Date();
    return this.formatDateKey(date) === this.formatDateKey(today);
  }

  private getDateRange(): string[] {
    const today = new Date();
    let start: Date;
    let end: Date;

    switch (this.viewMode) {
      case 'day':
        // Start from today, show next 7 days
        start = today;
        end = new Date(today);
        end.setDate(today.getDate() + 6);
        break;

      case 'week':
        // Start from Monday of current week, show next 8 weeks
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() + 1); // Monday
        end = new Date(start);
        end.setDate(start.getDate() + (8 * 7) - 1); // End of 8th week
        break;

      case 'month':
        // Start from first day of current month, show next 6 months
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 6, 0);
        break;
    }

    return [
      this.formatDateKey(start),
      this.formatDateKey(end)
    ];
  }

  get selectedDates(): string[] {
    return this._selectedDates;
  }

  set selectedDates(value: string[]) {
    this._selectedDates = value;
  }

  private hasSuggestionsForDate(date: string): boolean {
    if (this.viewMode === 'month') {
      return this.suggestions.some((s: any) => s.period === date);
    }
    
    if (this.viewMode === 'week') {
      // For week view, check if any suggestion falls within the week's date range
      return this.suggestions.some((s: any) => {
        const suggestionDate = new Date(s.period);
        const weekStart = new Date(date);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        
        return suggestionDate >= weekStart && suggestionDate <= weekEnd;
      });
    }
    
    // For day view
    return this.suggestions.some((s: any) => 
      this.formatDateKey(new Date(s.period)) === date
    );
  }

  private getSuggestionsForDate(date: string): any[] {
    if (this.viewMode === 'month') {
      return this.suggestions.filter((s: any) => s.period === date);
    }
    
    if (this.viewMode === 'week') {
      // For week view, get suggestions that fall within the week's date range
      return this.suggestions.filter((s: any) => {
        const suggestionDate = new Date(s.period);
        const weekStart = new Date(date);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);
        
        return suggestionDate >= weekStart && suggestionDate <= weekEnd;
      });
    }
    
    // For day view
    return this.suggestions.filter((s: any) => 
      this.formatDateKey(new Date(s.period)) === date
    );
  }

  private showSupplierDetails(supplier: any, date: string): void {
    this.selectedSupplierDetails = supplier;
    this.showSupplierDialog = true;
  }

  // Add this helper method to get week number
  private getWeekNumber(date: Date): number {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
  }

  // Add this method to handle item deletion
  private removeProductFromSupplier(product: any): void {
    if (this.selectedSupplierDetails) {
      // Remove product from the supplier's products array
      this.selectedSupplierDetails.products = this.selectedSupplierDetails.products.filter(
        (p: any) => p.variant_id !== product.variant_id
      );

      // Recalculate total order value
      this.selectedSupplierDetails.totalOrderValue = this.selectedSupplierDetails.products.reduce(
        (total: number, p: any) => total + (p['[inventory_replenishment]'] * p.unit_cost), 
        0
      );

      EventBus.$emit('show-snackbar', {
        message: 'Product removed successfully',
        color: 'success'
      });

      // If no products left, close the dialog
      if (this.selectedSupplierDetails.products.length === 0) {
        this.showSupplierDialog = false;
      }

      // Update the suggestions array to reflect the changes
      this.updateSuggestionsAfterDelete(product);
    }
  }

  // Add this method to update the main suggestions array
  private updateSuggestionsAfterDelete(deletedProduct: any): void {
    this.suggestions = this.suggestions.map((suggestion: any) => {
      // Find and update the supplier in this suggestion period
      suggestion.suppliers = suggestion.suppliers.map((supplier: any) => {
        if (supplier.id === this.selectedSupplierDetails?.id) {
          return {
            ...supplier,
            products: supplier.products.filter((p: any) => p.variant_id !== deletedProduct.variant_id),
            totalOrderValue: supplier.products
              .filter((p: any) => p.variant_id !== deletedProduct.variant_id)
              .reduce((total: number, p: any) => total + (p['[inventory_replenishment]'] * p.unit_cost), 0)
          };
        }
        return supplier;
      }).filter((supplier: any) => supplier.products.length > 0);

      // Recalculate total order value for the suggestion period
      suggestion.totalOrderValue = suggestion.suppliers.reduce(
        (total: number, s: any) => total + s.totalOrderValue, 
        0
      );

      return suggestion;
    }).filter((suggestion: any) => suggestion.suppliers.length > 0);
  }

  // Add these methods to the component
  private getStockLevelColor(product: any): string {
    const stockLevel = product['[Actual Inventory Quantity]'] || 0;
    const reorderQuantity = product['[inventory_replenishment]'] || 0;
    
    // If we have less than half of the recommended reorder quantity, it's critical
    if (stockLevel <= (reorderQuantity * 0.5)) return 'error';
    
    // If we have less than the recommended reorder quantity, it's a warning
    if (stockLevel <= reorderQuantity) return 'warning';
    
    // Otherwise, we're good
    return 'success';
  }

  private formatNumber(value: any): string {
    if (value === null || value === undefined) return '0.00';
    if (typeof value === 'string') value = parseFloat(value);
    if (typeof value !== 'number' || isNaN(value)) return '0.00';
    return value.toFixed(2);
  }
}

import { get, post, put, del } from '@/services/http';

export interface IPurchaseOrderItem {
  id?: number;
  purchaseOrderId?: number;
  variantId: number;
  productName: string;
  sku: string;
  orderedQuantity: number;
  receivedQuantity: number;
  unitPrice: number;
  totalPrice: number;
  _action?: 'add' | 'update' | 'remove';
}

export interface IPurchaseReceivedItem {
  id: number;
  purchaseReceivedId: number;
  purchaseOrderItemId: number;
  receivedQuantity: number;
  createdAt: string;
  updatedAt: string;
}

export interface IPurchaseReceived {
  id: number;
  purchaseOrderId: number;
  receivedBy: number;
  receivedDate: string;
  deliveryNoteNumber?: string;
  notes?: string;
  merchantId: number;
  locationId?: number;
  items: IPurchaseReceivedItem[];
  createdAt: string;
  updatedAt: string;
}

export interface IPurchaseOrder {
  id?: number;
  poNumber: string;
  buyingListId?: number;
  supplierId: number;
  supplierName?: string;
  status: 'draft' | 'sent' | 'confirmed' | 'partial_received' | 'received' | 'canceled';
  orderDate: string;
  expectedDeliveryDate?: string;
  locationId?: number;
  locationName?: string;
  totalAmount: number;
  notes?: string;
  items: IPurchaseOrderItem[];
  receiveds?: IPurchaseReceived[];
  updatedAt?: string;
  createdAt?: string;
}

interface ISendEmailOptions {
  emailSubject: string;
  customMessage: string;
  attachmentFormat: 'pdf' | 'csv';
  recipients: string[];
}

interface EmailOptions {
  subject: string;
  recipients: string[];
  message: string;
  format: string;
}

export class PurchaseOrderModel {
  id?: number;
  poNumber: string;
  buyingListId?: number;
  supplierId: number;
  supplierName?: string;
  status: 'draft' | 'sent' | 'confirmed' | 'partial_received' | 'received' | 'canceled';
  orderDate: string;
  expectedDeliveryDate?: string;
  locationId?: number;
  locationName?: string;
  totalAmount: number;
  notes?: string;
  updatedAt?: string;
  createdAt?: string;
  items: IPurchaseOrderItem[];
  receiveds: IPurchaseReceived[];
  private itemChanges: IPurchaseOrderItem[] = [];

  constructor(data: Partial<IPurchaseOrder>) {
    this.id = data.id;
    this.poNumber = data.poNumber || '';
    this.buyingListId = data.buyingListId;
    this.supplierId = data.supplierId || 0;
    this.supplierName = data.supplierName || '';
    this.status = data.status || 'draft';
    this.orderDate = data.orderDate || new Date().toISOString();
    this.expectedDeliveryDate = data.expectedDeliveryDate;
    this.locationId = data.locationId;
    this.locationName = data.locationName;
    this.totalAmount = data.totalAmount || 0;
    this.notes = data.notes;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
    this.items = data.items || [];
    this.receiveds = data.receiveds || [];
  }

  public mapData(data: IPurchaseOrder): void {
    this.id = data.id;
    this.poNumber = data.poNumber;
    this.buyingListId = data.buyingListId;
    this.supplierId = data.supplierId;
    this.supplierName = data.supplierName;
    this.status = data.status;
    this.orderDate = data.orderDate;
    this.expectedDeliveryDate = data.expectedDeliveryDate;
    this.locationId = data.locationId;
    this.locationName = data.locationName;
    this.totalAmount = data.totalAmount;
    this.notes = data.notes;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
    this.items = data.items;
    this.receiveds = data.receiveds || [];
  }

  async save(): Promise<void> {
    const data = {
      poNumber: this.poNumber,
      buyingListId: this.buyingListId,
      supplierId: this.supplierId,
      status: this.status,
      orderDate: this.orderDate,
      expectedDeliveryDate: this.expectedDeliveryDate,
      locationId: this.locationId,
      notes: this.notes,
      items: this.items.map(item => ({
        variantId: item.variantId,
        productName: item.productName,
        sku: item.sku,
        orderedQuantity: item.orderedQuantity,
        receivedQuantity: item.receivedQuantity || 0,
        unitPrice: item.unitPrice,
        totalPrice: item.orderedQuantity * item.unitPrice
      }))
    };

    if (this.id) {
      await put(`/purchase_orders/${this.id}`, data);
    } else {
      const response: any = await post('/purchase_orders', data);
      this.id = response.data.id;
      this.poNumber = response.data.poNumber || this.poNumber;
    }
  }

  async updateStatus(newStatus: IPurchaseOrder['status']): Promise<void> {
    if (!this.id) return;
    
    // Validate status transitions
    if (!this.isValidStatusTransition(newStatus)) {
      throw new Error(`Invalid status transition from ${this.status} to ${newStatus}`);
    }
    
    await put(`/purchase_orders/${this.id}/status`, { status: newStatus });
    this.status = newStatus;
  }

  private isValidStatusTransition(newStatus: IPurchaseOrder['status']): boolean {
    // Define allowed transitions
    const allowedTransitions: Record<IPurchaseOrder['status'], IPurchaseOrder['status'][]> = {
      'draft': ['sent', 'canceled'],
      'sent': ['confirmed', 'canceled'],
      'confirmed': ['partial_received', 'received', 'canceled'],
      'partial_received': ['received', 'canceled'],
      'received': [],
      'canceled': []
    };
    
    return allowedTransitions[this.status].includes(newStatus);
  }

  async delete(): Promise<void> {
    if (this.id) {
      await del(`/purchase_orders/${this.id}`);
    }
  }

  async fetchItems(): Promise<void> {
    if (!this.id) return;
    const response: any = await get(`/purchase_orders/${this.id}/items`);
    this.items = response.data.items || [];
  }

  async receiveItems(itemUpdates: { id: number, receivedQuantity: number }[], notes?: string, deliveryNoteNumber?: string): Promise<void> {
    if (!this.id) return;
    
    // First validate the updates
    for (const update of itemUpdates) {
      const item = this.items.find(i => Number(i.id) === Number(update.id));
      if (!item) {
        throw new Error(`Item ${update.id} not found in purchase order`);
      }
      
      if (update.receivedQuantity > item.orderedQuantity) {
        throw new Error(`Cannot receive more than ordered quantity for ${item.productName}`);
      }
    }
    
    const response: any = await post(`/purchase_orders/${this.id}/receive`, {
      items: itemUpdates,
      notes,
      deliveryNoteNumber
    });
    
    // Update local items with received quantities
    this.mapData(response.data);
  }

  async sendToSupplier(options: ISendEmailOptions): Promise<void> {
    if (!this.id) throw new Error('Purchase order not initialized');
    
    // Validate required fields
    if (!options.recipients || options.recipients.length === 0) {
      throw new Error('At least one recipient is required');
    }
    
    if (!options.emailSubject || !options.customMessage || !options.attachmentFormat) {
      throw new Error('Email subject, message and attachment format are required');
    }
    
    await post(`/purchase_orders/${this.id}/send`, {
      emailSubject: options.emailSubject,
      customMessage: options.customMessage,
      attachmentFormat: options.attachmentFormat,
      recipients: options.recipients
    });
    
    // If in draft status, update to sent
    if (this.status === 'draft') {
      await this.updateStatus('sent');
    }
  }

  async sendEmail(options: EmailOptions): Promise<void> {
    await post(`/purchase_orders/${this.id}/send`, options);
  }

  static async createFromBuyingList(buyingListId: number, data: {
    expectedDeliveryDate?: string,
    notes?: string,
    locationId?: number
  } = {}): Promise<PurchaseOrderModel> {
    const response: any = await post(`/buying_lists/${buyingListId}/purchase_orders`, data);
    return new PurchaseOrderModel(response.data);
  }

  calculateTotalAmount(): number {
    const total = this.items.reduce((sum, item) => {
      return sum + parseFloat((item.totalPrice || 0).toString());
    }, 0);
    this.totalAmount = total;
    return total;
  }

  getStatusText(): string {
    const statusMap: Record<IPurchaseOrder['status'], string> = {
      'draft': 'Draft',
      'sent': 'Sent to Supplier',
      'confirmed': 'Confirmed',
      'partial_received': 'Partially Received',
      'received': 'Fully Received',
      'canceled': 'Canceled'
    };
    
    return statusMap[this.status] || this.status;
  }

  getStatusColor(): string {
    const colorMap: Record<IPurchaseOrder['status'], string> = {
      'draft': 'grey',
      'sent': 'blue',
      'confirmed': 'amber',
      'partial_received': 'light-green',
      'received': 'green',
      'canceled': 'red'
    };
    
    return colorMap[this.status] || 'grey';
  }

  canEdit(): boolean {
    return this.status === 'draft';
  }

  canCancel(): boolean {
    return ['draft', 'sent', 'confirmed', 'partial_received'].includes(this.status);
  }

  canReceiveItems(): boolean {
    return ['confirmed', 'partial_received'].includes(this.status);
  }

  canSendToSupplier(): boolean {
    return this.status === 'draft';
  }

  toJSON(): IPurchaseOrder {
    return {
      id: this.id,
      poNumber: this.poNumber,
      buyingListId: this.buyingListId,
      supplierId: this.supplierId,
      supplierName: this.supplierName,
      status: this.status,
      orderDate: this.orderDate,
      expectedDeliveryDate: this.expectedDeliveryDate,
      locationId: this.locationId,
      locationName: this.locationName,
      totalAmount: this.totalAmount,
      notes: this.notes,
      items: this.items,
      receiveds: this.receiveds,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt
    };
  }

  static async getById(id: number): Promise<PurchaseOrderModel> {
    const response: any = await get(`/purchase_orders/${id}`);
    return new PurchaseOrderModel(response.data);
  }
} 
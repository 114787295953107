
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { GoogleSpreadsheetTemplateModel } from '@/models/google_spreadsheet_template';
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
  },
})
export default class GoogleSpreadsheetTemplateDetail extends Vue {
  public isLoading = false;
  public cloning = false;
  public template: GoogleSpreadsheetTemplateModel = new GoogleSpreadsheetTemplateModel();
  public showCloneDialogFlag = false;
  public isCloneFormValid = false;
  public cloneData = {
    name: '',
    emails: [] as string[]
  };

  public async created() {
    this.isLoading = true;
    const templateId = this.$route.params.id;
    await this.fetchTemplate(templateId);
    this.isLoading = false;
  }

  public async fetchTemplate(id: string) {
    try {
      this.template.id = this.$route.params.id;
      await this.template.fetch();
    } catch (error) {
      EventBus.$emit("show-snackbar", "An error occurred while fetching the template details.", "error");
    }
  }

  public goBack() {
    this.$router.push({ name: 'GoogleSpreadsheetTemplates' });
  }

  public showCloneDialog() {
    this.cloneData = {
      name: `${this.template.name} - Copy`,
      emails: []
    };
    this.showCloneDialogFlag = true;
  }

  public validateEmail(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  public async cloneTemplate() {
    if (!(this.$refs.cloneForm as any).validate()) {
      return;
    }

    this.cloning = true;
    try {
      const file = await this.template.clone({
        name: this.cloneData.name,
        emails: this.cloneData.emails
      });
      this.showCloneDialogFlag = false;
      EventBus.$emit("show-snackbar", "The template has been cloned successfully.", "success");
      this.$router.push({ 
        name: 'GoogleSpreadsheetFileDetail',
        params: { id: file.id.toString() }
      });
    } catch (e) {
      EventBus.$emit("show-snackbar", "An error occurred while cloning the template.", "error");
    }
    this.cloning = false;
  }

  public getSettingLabel(setting: any): string {
    if (setting.label) {
      return setting.label;
    }
    return this.convertSnakeCaseToNormalText(setting.name);
  }

  public convertSettingType(settingType: string): string {
    switch (settingType) {
      case 'boolean':
        return 'On/Off';
      case 'text':
        return 'Text input'
      case 'dropdown':
        return 'Selections'
      case 'number':
        return 'Number';
      case 'date':
        return 'Date'
      default:
        return settingType.charAt(0).toUpperCase() + settingType.slice(1);
    }
  }

  private convertSnakeCaseToNormalText(text: string): string {
    return text.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import Installing from '@/components/Installing.vue';
import { StoreModel } from '@/models/store';
import { BillingPackageModel } from '@/models/billing_package';
import { UserPackageModel } from '@/models/user_package';
import { BillingPackageList } from '@/collections/billing_packages';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History } from '@shopify/app-bridge/actions';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import { DiscountList } from '@/collections/discounts';
import FeatureComparisonTable from '@/components/FeatureComparisonTable.vue';

// Add interface for feature description
interface FeatureDescription {
  text: string;
  index: number;
}

@Component({
  components: {
    HeaderTitle,
    Installing,
    FeatureComparisonTable,
  },
})
export default class OnboardPricing extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public billingPackages: BillingPackageList = new BillingPackageList();
  public filteredBillingPackages: BillingPackageList = new BillingPackageList();
  public newUserPackage: UserPackageModel = new UserPackageModel();
  public freeBillingPackage: BillingPackageModel = new BillingPackageModel();
  public paidBillingPackage: BillingPackageModel = new BillingPackageModel();
  public paidBillingPackageList: BillingPackageList = new BillingPackageList();
  public backupUserPackage: UserPackageModel = new UserPackageModel();
  public isYearly: boolean = false;
  public submitting: boolean = false;
  public maxFeatureLines: number = 7;
  public viewAllFeatures: boolean = false;
  public canSubscribePaidPlan: boolean = true;
  public discountMessage: string = '';
  public reviews: any = [
    { src: 'review1.png' },
    { src: 'review2.png' },
    { src: 'review3.png' },
    { src: 'review4.png' },
    { src: 'review5.png' },
    { src: 'review6.png' },
    { src: 'review7.png' },
    { src: 'review8.png' },
    { src: 'review9.png' },
  ];
  public features: any = [
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.38.09.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.18.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.28.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.34.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.41.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.48.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.55.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.41.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.29.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.18.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.56.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.24.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.08.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.51.14.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.50.43.png',
    },
  ];

  public async created() {
    localStorage.setItem('onboard_page', '/onboard-pricing');
    this.loading = true;
    try {
      this.canSubscribePaidPlan = await this.store.canSubscribePaidPlan();
      await this.billingPackages.fetch();
      await this.getBillingPackage();
      this.error = false;
      this.success = true;
    } catch (e: any) {
      this.error = true;
      this.success = false;
    }
    this.loading = false;
  }

  // Helper method to get filtered features for a package
  public getFilteredFeatures(packageItem: BillingPackageModel): FeatureDescription[] {
    if (!packageItem || !packageItem.description) { return []; }

    const features = packageItem.description.split('\n')
      .map((text, index) => ({ text, index }))
      .filter((item) => item.text !== '' && item.index > 0);

    if (!this.viewAllFeatures) {
      return features.filter((item) => item.index < this.maxFeatureLines);
    }

    return features;
  }

  public calcualteYearlyPrice(packagePrice: number) {
    let price: number = packagePrice;
    if (this.isYearly) {
      price = (10 * price) / 12;
      return price.toFixed(1);
    }
    return price;
  }

  public async getBillingPackage() {
    this.paidBillingPackageList = new BillingPackageList();
    this.filteredBillingPackages = new BillingPackageList();
    let discount: any;
    try {
      const discounts = new DiscountList();
      await discounts.fetch();
      if (discounts.size() > 0) {
        discount = discounts.items[0];
      }
    } catch (e) {
      // ignore this
    }
    const assistyPlan = this.store.assistyPlan;
    for (const item of this.billingPackages.items) {
      if (item.price === 0) {
        this.freeBillingPackage = item;
      } else if (assistyPlan === item.shopifyPlanName) {
        this.paidBillingPackageList.add(item);
      }
    }
    // sort the paid billing packages by price in descending order
    this.paidBillingPackageList.items.sort((a, b) => b.price - a.price);
    
    this.filteredBillingPackages = new BillingPackageList();
    if (this.canSubscribePaidPlan) {
      if (discount) {
        this.discountMessage = `included ${discount.rate}% off`;
        this.paidBillingPackageList.items.forEach((item) => {
          item.price = item.price * (1 - discount.rate / 100);
        });
      }
      for (const item of this.paidBillingPackageList.items) {
        this.filteredBillingPackages.add(item);
      }
    } else {
      this.filteredBillingPackages.add(this.freeBillingPackage);
    }
  }

  public getTranslatedLabel(module: string, text: string) {
    if (this.$te(`${module}.${text}`)) {
      return this.$t(`${module}.${text}`);
    } else {
      return text;
    }
  }

  public isMaxFeature(index: number) {
    if (this.viewAllFeatures) {
      return false;
    }
    if (index < this.maxFeatureLines) {
      return false;
    }
    return true;
  }

  public async submitPackage(
    item: BillingPackageModel,
    trialDays: number = 14,
  ) {
    this.submitting = true;
    this.newUserPackage.billingPackageId = item.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    }
    const confirmationUrl = await this.newUserPackage.create(
      trialDays,
      0,
      returnUrl,
      [],
      this.isYearly
    );

    if (this.newUserPackage.status === 'CONFIRMING' && confirmationUrl) {
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        await eventHandler.track('ONBOARDING_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
        await this.store.sendEvent('ONBOARDING_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }

      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    } else {
      await this.store.updateInstallationStatus();
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        await eventHandler.track('ONBOARDING_START_FREE_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
        await this.store.sendEvent('ONBOARDING_START_FREE_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }
      EventBus.$emit('check-athena-finish-interval');
      this.$router.push('/subscription');
    }
  }

  public isPremiumPlan(item: BillingPackageModel): boolean {
    // Find the highest priced package in the list
    const highestPrice = Math.max(...this.filteredBillingPackages.items.map(pkg => pkg.price));
    // Return true if this item has the highest price
    return item.price === highestPrice && highestPrice > 0;
  }

  public async submitFreePackage() {
    if (this.freeBillingPackage && this.freeBillingPackage.id) {
      await this.submitPackage(this.freeBillingPackage, 0);
    }
  }
  
  // Helper method for feature toggle button
  public handleFeatureToggleClick(event: MouseEvent, item: BillingPackageModel) {
    // If shift key is pressed, show full comparison dialog
    if (event.shiftKey) {
      this.openFeatureComparison();
    } else {
      // Otherwise toggle view all features as usual
      this.viewAllFeatures = !this.viewAllFeatures;
    }
  }
  
  // Methods for feature comparison table
  public openFeatureComparison() {
    if (this.$refs.featureComparisonTable) {
      (this.$refs.featureComparisonTable as any).showFullComparisonDialog = true;
    }
  }
  
  public getProfessionalPriceDisplay(): string {
    const paidPlans = this.filteredBillingPackages.items.filter(item => item.price > 0);
    
    if (paidPlans.length > 0) {
      // Find plan with lowest price
      const lowestPricePlan = paidPlans.reduce((min, plan) => 
        plan.price < min.price ? plan : min, paidPlans[0]);
      
      const price = this.calcualteYearlyPrice(lowestPricePlan.price);
      return `$${price}`;
    }
    
    return 'Varies by Shopify plan';
  }

  public getEnterprisePriceDisplay(): string {
    const paidPlans = this.filteredBillingPackages.items.filter(item => item.price > 0);
    
    if (paidPlans.length > 0) {
      // Find plan with highest price
      const highestPricePlan = paidPlans.reduce((max, plan) => 
        plan.price > max.price ? plan : max, paidPlans[0]);
      
      const price = this.calcualteYearlyPrice(highestPricePlan.price);
      return `$${price}`;
    }
    
    return 'Varies by Shopify plan';
  }

  // Add computed property to check if we should show feature comparison
  get shouldShowFeatureComparison(): boolean {
    // Count the number of billing packages with price > 0
    const paidPackagesCount = this.filteredBillingPackages.items.filter(item => item.price > 0).length;
    // Show feature comparison only if there are at least 2 paid plans
    return paidPackagesCount >= 2;
  }
}

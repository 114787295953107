
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SettingModel } from '@/models/setting';
import SettingText from './SettingText.vue';
import SettingBoolean from './SettingBoolean.vue';
import SettingDropdown from './SettingDropdown.vue';
import SettingDateRange from './SettingDateRange.vue';
import SettingNumber from './SettingNumber.vue';
import SettingMultiple from './SettingMultiple.vue';
import SettingDate from './SettingDate.vue';

@Component({
  components: {
    SettingText,
    SettingBoolean,
    SettingDateRange,
    SettingNumber,
    SettingMultiple,
    SettingDate,
  },
})
export default class SettingComponent extends Vue {
  @Prop({ required: true }) public setting!: SettingModel;

  get currentComponent() {
    switch (this.setting.settingType) {
      case 'text':
        return 'SettingText';
      case 'boolean':
        return 'SettingBoolean';
      case 'dropdown':
        return 'SettingDropdown';
      case 'daterange':
        return 'SettingDateRange';
      case 'number':
        return 'SettingNumber';
      case 'multiple':
        return 'SettingMultiple';
      case 'date':
        return 'SettingDate';
      default:
        return null;
    }
  }
}


import { Component } from 'vue-property-decorator';
import SettingBase from './SettingBase';
import { DateRange } from '@/models/setting';
import DateFilter from "@/components/DateFilter.vue";

@Component({
  components: {
    DateFilter,
  },
})
export default class SettingDateRange extends SettingBase {
  get dates(): [string, string, string] {
    const value = this.setting.value as DateRange;
    return [value.startDate, value.endDate, value.type];
  }

  set dates(value: [string, string, string]) {
    this.setting.value = {
      startDate: value[0],
      endDate: value[1],
      type: value[2]
    };
  }
}

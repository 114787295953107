import { get, post } from '@/services/http';
import { StoreLocationModel } from '@/models/store_location';
import List from './list';
import axios from 'axios';

export class StoreLocationList extends List<StoreLocationModel> {
  public source?: any;
  public loading: boolean = false;
  public mapData(data: any = []) {
    for (const item of data) {
      const storeLocation = new StoreLocationModel();
      storeLocation.mapData(item);
      this.add(storeLocation);
    }
  }

  public async fetch(params = {}) {
      if (!this.source) {
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      if (this.loading) {
        this.source.cancel();
        this.loading = false;
        const CancelToken = axios.CancelToken;
        this.source = CancelToken.source();
      }
      this.loading = true;
      const res: any = await get(`/dimensions/by_location`, params, this.source.token);
      this.items = [];
      for (const value of res.data) {
        const storeLocation: StoreLocationModel = new StoreLocationModel();
        storeLocation.mapData(value);
      this.add(storeLocation);
    }
    this.loading = false;
  }
}

<template>
  <div class="feature-comparison-container">
    <v-card elevation="2" class="feature-comparison-card">
      <!-- Comparison Button Card -->
      <div class="d-flex justify-center align-center pa-6 comparison-header">
        <v-btn
          color="primary"
          outlined
          @click="showFullComparisonDialog = true"
          class="comparison-button"
        >
          <v-icon left>mdi-compare</v-icon>
          View Full Feature Comparison
        </v-btn>
      </div>
    </v-card>
    
    <!-- Full Comparison Dialog -->
    <v-dialog v-model="showFullComparisonDialog" max-width="900" content-class="comparison-dialog">
      <v-card class="comparison-dialog-card">
        <!-- Sticky Dialog Header -->
        <div class="sticky-dialog-header">
          <div class="d-flex align-center">
            <h2 class="headline primary--text">Complete Feature Comparison</h2>
            <v-spacer></v-spacer>
            <v-btn icon @click="showFullComparisonDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="table-container">
          <!-- Sticky Table Header -->
          <div class="sticky-table-header">
            <table class="feature-comparison-table">
              <tr>
                <th class="feature-column">FEATURES</th>
                <th class="feature-column">FREE</th>
                <th class="feature-column">PROFESSIONAL</th>
                <th class="feature-column">ENTERPRISE</th>
              </tr>
            </table>
          </div>
          
          <!-- Scrollable Table Body -->
          <div class="scrollable-table-body">
            <table class="feature-comparison-table">
              <!-- Enterprise Features Section -->
              <tr class="feature-category-row">
                <td colspan="4" class="font-weight-bold">Enterprise-Only Features</td>
              </tr>
              <tr v-for="feature in fullEnterpriseOnlyFeatures" :key="feature.name" class="feature-row">
                <td class="feature-column">{{ feature.name }}</td>
                <td class="feature-column">{{ feature.free }}</td>
                <td class="feature-column">{{ feature.professional }}</td>
                <td class="feature-column">{{ feature.enterprise }}</td>
              </tr>
              
              <!-- Professional & Enterprise Features Section -->
              <tr class="feature-category-row">
                <td colspan="4" class="font-weight-bold">Professional & Enterprise Only Features</td>
              </tr>
              <tr v-for="feature in fullProfessionalAndEnterpriseFeatures" :key="feature.name" class="feature-row">
                <td class="feature-column">{{ feature.name }}</td>
                <td class="feature-column">{{ feature.free }}</td>
                <td class="feature-column">{{ feature.professional }}</td>
                <td class="feature-column">{{ feature.enterprise }}</td>
              </tr>
              
              <!-- Available for All Plans Section -->
              <tr class="feature-category-row">
                <td colspan="4" class="font-weight-bold">Available for All Plans</td>
              </tr>
              <tr v-for="feature in fullAllPlansFeatures" :key="feature.name" class="feature-row">
                <td class="feature-column">{{ feature.name }}</td>
                <td class="feature-column">{{ feature.free }}</td>
                <td class="feature-column">{{ feature.professional }}</td>
                <td class="feature-column">{{ feature.enterprise }}</td>
              </tr>
            </table>
          </div>
          
          <!-- Sticky Price Footer -->
          <div class="sticky-table-footer">
            <table class="feature-comparison-table">
              <tr class="pricing-row">
                <td class="feature-column font-weight-bold">Monthly Price*</td>
                <td class="feature-column price-cell">$0</td>
                <td class="feature-column price-cell">
                  <span v-if="professionalPrice">{{ professionalPrice }}</span>
                  <span v-else>Varies</span>
                </td>
                <td class="feature-column price-cell">
                  <span v-if="enterprisePrice">{{ enterprisePrice }}</span>
                  <span v-else>Varies</span>
                </td>
              </tr>
            </table>
            <div class="price-footnote">
              * Pricing may vary based on your Shopify plan and billing cycle. Yearly subscriptions receive a 20% discount.
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'FeatureComparisonTable',
  props: {
    professionalPrice: {
      type: String,
      default: null
    },
    enterprisePrice: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showFullComparisonDialog: false,
      
      // Full comparison for the dialog
      fullEnterpriseOnlyFeatures: [
        { name: 'AI & ML Demand Forecasting', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Replenishment Center', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Advanced Buying Table', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Buying Calendar', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Supplier Management', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Purchase Orders & Receiving', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Replenish from Warehouse', free: '−', professional: '−', enterprise: '✓' },
        { name: 'Advanced Spreadsheet Integration', free: '−', professional: '−', enterprise: '✓' },
        { name: 'QuickBooks Integration', free: '−', professional: '−', enterprise: '✓' },
      ],
      fullProfessionalAndEnterpriseFeatures: [
        { name: 'Advanced Inventory Tracking', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Inventory Optimization', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Reorder Suggestion Report', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Product Segmentation', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Report Scheduling', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Basic Google Spreadsheet Integration', free: '−', professional: '✓', enterprise: '✓' },
        { name: 'Export Data', free: 'Current page only', professional: 'Full data export', enterprise: 'Full data export' }
      ],
      fullAllPlansFeatures: [
        { name: 'Basic Sales Analytics', free: '✓', professional: '✓', enterprise: '✓' },
        { name: 'Basic Dashboard', free: '✓', professional: '✓', enterprise: '✓' },
        { name: 'Custom Reports', free: '✓', professional: '✓', enterprise: '✓' },
        { name: 'Basic Inventory Tracking', free: '✓', professional: '✓', enterprise: '✓' }
      ]
    }
  }
}
</script>

<style scoped>
.feature-comparison-container {
  margin: 24px 0;
}

.feature-comparison-card {
  border-radius: 8px;
  overflow: hidden;
  background-color: #f5f7fa;
  transition: all 0.3s ease;
}

.feature-comparison-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.comparison-header {
  min-height: 80px;
}

.comparison-button {
  font-weight: 500;
  letter-spacing: 0.5px;
  min-width: 240px;
  height: 48px;
}

.comparison-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Dialog styling */
.comparison-dialog {
  overflow: hidden;
}

.comparison-dialog-card {
  display: flex;
  flex-direction: column;
  height: 90vh;
  max-height: 900px;
  overflow: hidden;
  border-radius: 8px;
}

/* Table container structure */
.table-container {
  display: flex;
  flex-direction: column;
  height: calc(90vh - 60px); /* Subtract header height */
  position: relative;
}

/* Sticky Dialog Header */
.sticky-dialog-header {
  background-color: #f5f7fa;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 60px;
}

/* Sticky Table Header */
.sticky-table-header {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #f8f9fa;
  border-bottom: 2px solid #e0e0e0;
}

.sticky-table-header th {
  padding: 16px;
  font-weight: 600;
  color: #333;
  font-size: 15px;
  text-align: center;
}

.sticky-table-header th:first-child {
  text-align: left;
}

/* Scrollable Table Body */
.scrollable-table-body {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Sticky Table Footer */
.sticky-table-footer {
  position: sticky;
  bottom: 0;
  z-index: 5;
  background-color: white;
  border-top: 2px solid #e0e0e0;
}

/* Consistent Table Structure */
.feature-comparison-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

/* Column widths - fixed and consistent */
.feature-column {
  width: 50%;
  text-align: center;
  padding: 16px;
}

.feature-column:first-child {
  text-align: left;
}

.plan-column {
  width: 16.67%;
  text-align: center;
  padding: 16px;
}

/* Row styling */
.feature-category-row {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 16px;
}

.feature-category-row td {
  color: #333;
  font-size: 15px;
  font-weight: 700;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-left: 16px;
}

.feature-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.pricing-row {
  background-color: rgba(232, 245, 233, 0.3);
}

.price-cell {
  font-weight: 600;
  color: #2e7d32;
}

.price-footnote {
  font-size: 12px;
  color: #757575;
  padding: 12px 16px;
  border-top: 1px solid #e0e0e0;
  text-align: left;
  background-color: white;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .feature-column {
    width: 40%;
    padding: 12px 8px;
    font-size: 14px;
  }
  
  .plan-column {
    width: 20%;
    padding: 12px 8px;
    font-size: 14px;
  }
  
  .sticky-table-header th,
  .feature-row td,
  .pricing-row td {
    padding: 12px 8px;
    font-size: 14px;
  }
}
</style> 
import { get, post } from '@/services/http';
import { SupplierProductModel } from '@/models/supplier_product';
import List from './list';

export class SupplierProductList extends List<SupplierProductModel> {
  
  public async fetch(params: { supplierId?: number; variantId?: number; page?: number; limit?: number } = {}) {
    try {
      // fetch data from api
      const response: any = await get('/supplier-products', { params });
      this.mapData(response.data);
      return response.data.pagination;
    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  public mapData(data: any) {
    // Clear existing items
    this.items = [];
    
    if (data.products) {
      for (const product of data.products) {
        const supplierProduct = new SupplierProductModel(product);
        this.add(supplierProduct);
      }
    }
  }

  public async save() {
    try {
      console.log('Saving supplier products:', this.items.map(item => item.toJSON()));
      return true;
    } catch (e) {
      console.error('Error saving supplier products:', e);
      return false;
    }
  }

  public async bulkUpdate(updates: Array<Partial<SupplierProductModel> & { id: number }>) {
    try {
      const response = await SupplierProductModel.bulkUpdateSettings(updates);
      // Refresh the list after bulk update
      await this.fetch();
      return response;
    } catch (error) {
      console.error('Error performing bulk update:', error);
      throw error;
    }
  }

  public async addVariantsToSupplier(supplierId: number, variants: Array<{ variantId: number }>) {
    try {
      const response = await SupplierProductModel.addVariantsToSupplier(supplierId, variants);
      // Refresh the list after adding variants
      await this.fetch({ supplierId });
      return response;
    } catch (error) {
      console.error('Error adding variants to supplier:', error);
      throw error;
    }
  }

  public async removeVariantsFromSupplier(supplierId: number, variantIds: number[]) {
    try {
      const response = await SupplierProductModel.removeVariantsFromSupplier(supplierId, variantIds);
      // Refresh the list after removing variants
      await this.fetch({ supplierId });
      return response;
    } catch (error) {
      console.error('Error removing variants from supplier:', error);
      throw error;
    }
  }

  public getPreferredProducts(): SupplierProductModel[] {
    return this.items.filter(product => product.isPreferred);
  }

  public getActiveProducts(): SupplierProductModel[] {
    return this.items.filter(product => 
      product.status === 'ACTIVE' && !product.isDeleted
    );
  }

  public getProductsBySupplier(supplierId: number): SupplierProductModel[] {
    return this.items.filter(product => 
      product.supplierId === supplierId && !product.isDeleted
    );
  }

  public getProductsByVariant(variantId: number): SupplierProductModel[] {
    return this.items.filter(product => 
      product.variantId === variantId && !product.isDeleted
    );
  }

  public async fetchBySupplier(supplierId: number, page: number = 1, limit: number = 20) {
    try {
      const response: any = await get(`/supplier-products/supplier/${supplierId}`, {
        params: { page, limit }
      });
      console.log('Response from fetchBySupplier assigned products:', response.data);
      this.mapData(response.data);
      return response.data.pagination;
    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  public async fetchByVariant(variantId: number) {
    try {
      const response: any = await get(`/supplier-products/variant/${variantId}`);
      this.mapData(response.data);
    } catch (error) {
      console.error('Error fetching variant suppliers:', error);
      throw error;
    }
  }

  public getProductsNeedingReorder(): SupplierProductModel[] {
    return this.items.filter(product => 
      product.status === 'ACTIVE' && 
      !product.isDeleted &&
      !product.replenishmentExclusion
    );
  }
} 
import { get, post, put } from '@/services/http';
import { RuleList } from '@/collections/rules';

export class BillingPackageModel {
  public id: number;
  public name: string;
  public price: number;
  public comparedPrice: number;
  public description: string;
  public rules: RuleList;
  public status: string;
  public shopifyPlanName: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || 'Default Title';
    this.price = data.price || 29.00;
    this.comparedPrice = data.comparedPrice || 59.00;
    this.description = data.description || '';
    this.rules = data.rules || new RuleList();
    this.status = data.status || 'INACTIVE';
    this.shopifyPlanName = data.shopifyPlanName || '';
  }

  public async fetch() {
    const res: any = await get(`/billing_packages/${this.id}`);
    this.mapData(res.data);
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.comparedPrice = data.comparedPrice;
    this.description = data.description;
    this.rules.mapData(data.rules);
    this.status = data.status;
    this.shopifyPlanName = data.shopifyPlanName;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}

import { get, post, put, del } from '@/services/http';

export class InventoryParam {
  public replenishmentExclusion: boolean = false;
  public leadtime: number = 0;
  public supplierId: number = 0;
  public orderCycle: number = 0;
  public fixOrderQuantity: number = 0;
  public minOrderQuantity: number = 0;
  public maxOrderQuantity: number = 0;
  public minInventoryQuantity: number = 0;
  public maxInventoryQuantity: number = 0;
  public minStockCoverDays: number = 0;
  public maxStockCoverDays: number = 0;

  constructor(data: any = {}) {
      this.replenishmentExclusion = data.replenishmentExclusion;
      this.leadtime = data.leadtime;
      this.supplierId = data.supplierId;
      this.orderCycle = data.orderCycle;
      this.fixOrderQuantity = data.fixOrderQuantity;
      this.minOrderQuantity = data.minOrderQuantity;
      this.maxOrderQuantity = data.maxOrderQuantity;
      this.minInventoryQuantity = data.minInventoryQuantity;
      this.maxInventoryQuantity = data.maxInventoryQuantity;
      this.minStockCoverDays = data.minStockCoverDays;
      this.maxStockCoverDays = data.maxStockCoverDays;
  }

  public async save(reportRequestParams: any, setting: any) {
    const data = {
      options: reportRequestParams,
      replenishment_exclusion: this.replenishmentExclusion? 1 : 0,
      leadtime: Number(this.leadtime),
      supplier_id: this.supplierId,
      order_cycle: Number(this.orderCycle),
      fix_order_quantity: Number(this.fixOrderQuantity),
      min_order_quantity: Number(this.minOrderQuantity),
      max_order_quantity: Number(this.maxOrderQuantity),
      min_inventory_quantity: Number(this.minInventoryQuantity),
      max_inventory_quantity: Number(this.maxInventoryQuantity),
      min_stock_cover_days: Number(this.minStockCoverDays),
      max_stock_cover_days: Number(this.maxStockCoverDays),
      setting: setting,
    };
    const res: any = await put('/replenishment_settings', data);
  }

  public async saveSupplierProduct(reportRequestParams: any, setting: any) {
    const data = {
      options: reportRequestParams,
      supplier_id: this.supplierId,
      setting: setting,
    };
    const res: any = await put('/replenishment_settings', data);
  }
  /**
   * Resets specified fields in the replenishment settings
   * @param fields - Array of field names to reset
   * @param reportRequestParams - Report parameters to identify which records to reset
   * @returns Promise that resolves when the reset is complete
   */
  public async reset(fields: any, reportRequestParams: any) {
    const data = {
      options: reportRequestParams,
      fields: fields,
    };
    const res: any = await del('/replenishment_settings', data);
  }



  public mappData(data: any = {}) {
    this.replenishmentExclusion = data.replenishmentExclusion;
    this.leadtime = data.leadtime;
    this.supplierId = data.supplierId;
    this.orderCycle = data.orderCycle;
    this.fixOrderQuantity = data.fixOrderQuantity;
    this.minOrderQuantity = data.minOrderQuantity;
    this.maxOrderQuantity = data.maxOrderQuantity;
    this.minInventoryQuantity = data.minInventoryQuantity;
    this.maxInventoryQuantity = data.maxInventoryQuantity;
    this.minStockCoverDays = data.minStockCoverDays;
    this.maxStockCoverDays = data.maxStockCoverDays;
  }

  public canSave() {
    if (!this.supplierId) { 
      return false;
    }
    return true;
  }
}
import { get, post, put } from '@/services/http';

export class StoreLocationModel {
  public id: number = 0;
  public name: string = '';
  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
  }
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ReplenishmentParam from './ReplenishmentParam.vue';
import { InventoryParam } from '@/models/inventory_param';
import { SupplierModel } from '@/models/supplier';

@Component({
  components: {
    ReplenishmentParam
  }
})
export default class ReplenishmentParamDialog extends Vue {
  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: 'individual' })
  mode!: 'bulk' | 'individual';

  @Prop({ default: () => new InventoryParam({}) })
  currentParams!: InventoryParam;

  @Prop({ default: () => new SupplierModel({}) })
  supplier!: SupplierModel;

  @Prop({ default: false })
  hasSelectedProducts!: boolean;

  @Prop({ default: false })
  hasFilteredProducts!: boolean;
  
  @Prop({ default: 0 })
  allProductsCount!: number;
  
  @Prop({ default: 0 })
  selectedProductsCount!: number;

  private dialog = false;
  private updateScope = 'all';
  private saving = false;

  @Watch('value')
  onValueChange(val: boolean) {
    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChange(val: boolean) {
    this.$emit('input', val);
  }

  private closeDialog() {
    this.dialog = false;
    this.updateScope = 'all';
    this.$emit('close');
  }

  private async handleApply(params: InventoryParam) {
    this.saving = true;
    try {
      await this.$emit('apply', {
        params,
        scope: this.updateScope
      });
      this.closeDialog();
    } catch (error) {
      console.error('Failed to apply parameters:', error);
    } finally {
      this.saving = false;
    }
  }
}

import { get, post, put, del } from '@/services/http';

export interface IBuyingListItem {
  id?: number;
  buyingListId?: number;
  variantId: number;
  productName: string;
  sku: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  _action?: 'add' | 'update' | 'remove';
}

export interface IBuyingList {
  id?: number;
  name: string;
  description?: string;
  status: 'active' | 'finalized' | 'completed' | 'canceled';
  supplierId?: number;
  locationId?: number;
  totalValue: number;
  items: IBuyingListItem[];
  updatedAt?: string;
  createdAt?: string;
}

interface ISendEmailOptions {
  emailSubject: string;
  customMessage: string;
  attachmentFormat: 'pdf' | 'csv';
  recipients: string[];
}

export interface IProduct {
  id: number;
  name: string;
  currentStock: number;
  reorderPoint: number;
  reorderQuantity: number;
  leadTime: number;
  buyingCycle: number;
  nextReorderDate: string;
  unitPrice: number;
  category: string;
}

export interface ISupplier {
  id: number;
  name: string;
  buyingCycle: number;
  products: IProduct[];
}

export interface ISupplierGroup {
  supplierId: number;
  supplierName: string;
  supplierBuyingCycle: number;
  products: IProduct[];
}

export type IViewMode = 'day' | 'week' | 'month';

export interface DateRange {
  from: Date;
  to: Date;
}

export class BuyingListModel {
  id?: number;
  name: string;
  description?: string;
  status: 'active' | 'finalized' | 'completed' | 'canceled';
  supplierId?: number;
  locationId?: number;
  totalValue: number;
  updatedAt?: string;
  createdAt?: string;
  items: IBuyingListItem[];
  private itemChanges: IBuyingListItem[] = [];

  constructor(data: Partial<IBuyingList>) {
    this.id = data.id || undefined;
    this.name = data.name || '';
    this.description = data.description;
    this.status = data.status || 'active';
    this.supplierId = data.supplierId;
    this.locationId = data.locationId;
    this.totalValue = data.totalValue || 0;
    this.items = data.items || [];
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  public mapData(data: IBuyingList): void {
    this.id = data.id || undefined;
    this.name = data.name;
    this.description = data.description;
    this.status = data.status;
    this.supplierId = data.supplierId;
    this.locationId = data.locationId;
    this.totalValue = data.totalValue;
    this.items = data.items;
    this.updatedAt = data.updatedAt;
    this.createdAt = data.createdAt;
  }

  async save(): Promise<void> {
    const data = {
      name: this.name,
      description: this.description,
      supplierId: this.supplierId,
      locationId: this.locationId,
      items: this.items.map(item => ({
        variantId: item.variantId,
        productName: item.productName,
        sku: item.sku,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        totalPrice: item.quantity * item.unitPrice
      }))
    };
    if (this.id) {
      await put(`/buying_lists/${this.id}`, data);
    } else {
      const response: any = await post('/buying_lists', data);
      this.id = response.data.id;
    }
  }

  async updateStatus(newStatus: IBuyingList['status']): Promise<void> {
    if (!this.id) return;
    await put(`/buying_lists/${this.id}`, { status: newStatus });
    this.status = newStatus;
  }

  async delete(): Promise<void> {
    if (this.id) {
      await del(`/buying_lists/${this.id}`);
    }
  }

  async fetchItems(): Promise<void> {
    if (!this.id) return;
    const response: any = await get(`/buying_lists/${this.id}/items`);
    this.items = response.data.items || [];
  }

  async updateItemQuantity(itemId: number, quantity: number): Promise<void> {
    const item = this.items.find(i => i.id === itemId);
    if (item) {
      item.quantity = quantity;
      item.totalPrice = item.unitPrice * quantity;
      this.itemChanges.push({ ...item, _action: 'update' });
      await this.saveItemChanges();
    }
  }

  async removeItem(itemId: number): Promise<void> {
    const index = this.items.findIndex(i => i.id === itemId);
    if (index !== -1) {
      const item = this.items[index];
      this.items.splice(index, 1);
      this.itemChanges.push({ ...item, _action: 'remove' });
      await this.saveItemChanges();
    }
  }

  async addItems(items: IBuyingListItem[]): Promise<void> {
    const newItems = items.map(item => ({
      ...item,
      _action: 'add' as const
    }));
    this.itemChanges.push(...newItems);
    await this.saveItemChanges();
  }

  setItemChanges(changes: IBuyingListItem[]): void {
    this.itemChanges = changes;
  }

  async saveItemChanges(): Promise<void> {
    if (!this.id || !this.itemChanges.length) return;

    try {
      const response: any = await put(`/buying_lists/${this.id}/items`, {
        items: this.itemChanges.map(item => ({
          ...item,
          totalPrice: item.totalPrice
        }))
      });
      
      this.items = response.data.items;
      this.itemChanges = [];
      this.totalValue = this.items.reduce((sum, item) => sum + parseFloat(item.totalPrice.toString()), 0);
    } catch (error) {
      await this.fetchItems();
      throw error;
    }
  }

  async cancelList(): Promise<void> {
    if (!this.id) return;
    await this.updateStatus('canceled');
    this.status = 'canceled';
  }

  toJSON(): IBuyingList {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      status: this.status,
      supplierId: this.supplierId,
      locationId: this.locationId,
      totalValue: this.totalValue,
      items: this.items
    };
  }

  async sendEmail(options: ISendEmailOptions): Promise<void> {
    if (!this.id) throw new Error('Buying list not initialized');
    
    // Validate required fields
    if (!options.recipients || options.recipients.length === 0) {
      throw new Error('At least one recipient is required');
    }
    
    if (!options.emailSubject || !options.customMessage || !options.attachmentFormat) {
      throw new Error('Email subject, message and attachment format are required');
    }
    
    await post(`/buying_lists/${this.id}/send`, {
      emailSubject: options.emailSubject,
      customMessage: options.customMessage,
      attachmentFormat: options.attachmentFormat,
      recipients: options.recipients
    });
  }

  public async fetchBuyingCalendar(periodType: string, dateRange: DateRange): Promise<any> {
   // call to getBuyingCalendar buying_calendar?from=2024-11-19&to=2025-02-18&period=week
   const response: any = await get(`/buying_calendar?from=${dateRange.from.toISOString()}&to=${dateRange.to.toISOString()}&period=${periodType}`);
   return response.data;
  }
} 
import { get } from '@/services/http';
import { LegacySupplierModel } from '@/models/legacy_supplier';
import List from './list';

export class LegacySupplierList extends List<LegacySupplierModel> {

  public async fetch() {
    const res: any = await get(`/legacy/suppliers`, {});
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    for (const s of data) {
      const supplier: LegacySupplierModel = new LegacySupplierModel();
      supplier.mapData(s);
      this.add(supplier);
    }
  }
}


import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventBus } from '@/main';
import HeaderTitle from '@/components/HeaderTitle.vue';
import DateFilter from '@/components/DateFilter.vue';
import FilterColumn from '@/components/FilterColumn.vue';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { ProductSegmentModel } from '@/models/product_segment';
import { FilterColumnList } from '@/collections/filter_columns';
import { StoreModel } from '@/models/store';
import { ReportModel } from '@/models/report';
import ActionButton from '@/components/ActionButton.vue';
import LoadingSlider from '@/components/LoadingSlider.vue';
import ProductSelector from '@/components/ProductSelector.vue';
import moment from 'moment';

interface TableHeader {
  text: string;
  value: string;
  sortable?: boolean;
  filterable?: boolean;
}

@Component({
  components: {
    HeaderTitle,
    DateFilter,
    FilterColumn,
    ActionButton,
    LoadingSlider,
    ProductSelector,
  },
})
export default class ProductSegment extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  // Properties
  private report: ReportModel = new ReportModel();
  private loading: boolean = false;
  private saveLoading: boolean = false;
  private editProductSegmentDialog: boolean = false;
  private newProductSegmentName: string = '';
  private newProductSegmentDescription: string = '';
  private dates: string[] = [];
  private defaultRange: string = '3m';
  private dateRangeMenu: boolean = false;
  private measure: MeasureModel = new MeasureModel();
  private dimension: DimensionModel = new DimensionModel();
  private analyticsType: string = '';
  private filterColumns: FilterColumnList = new FilterColumnList();
  private reportColumns: any[] = [];
  private initFilterColumns: FilterColumnList = new FilterColumnList();
  private productSegment: ProductSegmentModel = new ProductSegmentModel();
  private products: any[] = [];
  private totalProducts: number = 0;
  private isFullLoaded: boolean = false;
  private isLoadingSegment: boolean = false;
  private isLoadingReport: boolean = false;
  private isLoadingProductCount: boolean = false;
  private isShowProductSelector: boolean = false;
  private tableOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

  private isEditingCondition: boolean = false;
  private isProcessing: boolean = false;
  
  // Table headers matching ProductSelector
  private get headers(): TableHeader[] {
    return this.reportColumns.map((column: any) => ({
      text: column.name,
      value: column.code,
      sortable: true,
      filterable: true,
    }));
  }

  @Prop({ default: false })
  public isAthenaFinished!: boolean;

  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];

  private pollTimer: number | null = null;

  // Add computed property for formatted date range
  private get formatDateRange(): string {
    if (!this.dates || this.dates.length < 2) return 'No date range selected';
    return `${moment(this.dates[0]).format('MMM D, YYYY')} - ${moment(this.dates[1]).format('MMM D, YYYY')}`;
  }

  // Add computed property for formatted last updated date
  private get formatLastUpdated(): string {
    if (!this.productSegment.updated) return '';
    return moment(this.productSegment.updated).format('MMM D, YYYY h:mm A');
  }

  async created() {
    this.loading = true;
    try {
      await this.loadEditingSegment();
      await this.initializeReport();
      this.loading = false;
      await this.loadData();
    } catch (error) {
      console.error('Error initializing segment:', error);
      EventBus.$emit('show-snackbar', {
        message: 'Error loading segment data',
        color: 'error',
      });
    } finally {
      this.loading = false;
    }
    this.isFullLoaded = true;
  }

  public showProductSelector() {
    EventBus.$emit('show-product-selector');
  }

  public async loadEditingSegment() {
    this.isLoadingSegment = true;
    const type = this.$route.params.type;
    if (type) {
      this.productSegment.type = type;
    }
    const segmentId = this.$route.params.segment_id;
    this.productSegment.id = Number(segmentId);
    await this.productSegment.fetch();
    this.totalProducts = this.productSegment.count;
    if (this.productSegment.status === 'PROCESSING') {
      this.isProcessing = true;
      // this.startPollingSegmentStatus();
    }
    this.dates = this.productSegment.timerange;
    this.defaultRange = this.productSegment.timerange[0];
    this.filterColumns = this.productSegment.filters;
    this.measure = this.productSegment.measure;
    if (this.productSegment.pageOptions) {
      this.tableOptions = {
        page: Number(this.productSegment.pageOptions.page) || 1,
        itemsPerPage: Number(this.productSegment.pageOptions.itemsPerPage) || 50,
        sortBy: this.productSegment.pageOptions.sortBy || [],
        sortDesc: this.productSegment.pageOptions.sortDesc || []
      };
    } else {
      this.tableOptions = {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: []
      };
    }
    this.initFilterColumns = this.filterColumns;
    this.isLoadingSegment = false;
  }

  private async initializeReport() {
    // Set up report parameters
    this.measure.fetch();
    this.report.measure = this.measure;
    this.report.dimension = new DimensionModel({ code: 'by_product' });
    this.report.isDetail = true;
    this.report.isMetric = false;
    if (this.productSegment.type === 'manual') {
      this.report.productSegment = this.productSegment;
    }
    
    // Add product_id to report columns
    const reportColumnsWithId = [
      { name: 'Product Id', code: 'product_id' },
      // { name: 'Product Description', code: 'Item[Item Description]' },
      // { name: 'Vendor', code: 'Vendor Name' },
      // { name: 'Product Type', code: 'product_type' },
      // { name: 'Product Status', code: 'product_status' },
      // { name: 'Reorder Quantity', code: '[inventory_replenishment]' },
      // { name: 'Daily Units Sold', code: '[average_daily_units_sold_param]' },
      // { name: 'Leadtime', code: '[vendor_lead_time]' },
      // { name: 'Inventory Quantity', code: '[Actual Inventory Quantity]' },
      // { name: 'Unit Cost', code: '[average_unit_cost]' },
      // { name: 'Selling Price', code: '[Selling Price]' },
      // { name: 'Net Sales', code: '[net_sales]' },
      // { name: 'Sales Contribution', code: '[sales_contribution]' },
    ];
    this.report.reportColumns = reportColumnsWithId;
    
    // Set default date range (last 3 months)
    const now = new Date();
    const threeMonthsAgo = moment().subtract(3, 'months').toDate();
    
    let from, to;
    if (this.dates && this.dates.length >= 2) {
      from = this.dates[0];
      to = this.dates[1];
    } else {
      from = moment(threeMonthsAgo).format('YYYY-MM-DD');
      to = moment(now).format('YYYY-MM-DD');
    }
    this.report.filterTimerange = [from, to];

    // Set pagination
    if (this.tableOptions && this.tableOptions.page && this.tableOptions.itemsPerPage) {
      this.report.page = this.tableOptions.page;
      this.report.perPage = this.tableOptions.itemsPerPage;
    } else {
      this.report.perPage = 100;
      this.report.page = 1;
    }
  }

  private async loadData() {
    this.isLoadingReport = true;
    try {
      // update the report condition with the current filter columns and daterange
      this.report.filterColumns = this.filterColumns;
      this.report.filterTimerange = this.dates;
      this.report.sortBy = this.tableOptions.sortBy;
      this.report.sortDesc = this.tableOptions.sortDesc;
      if (this.productSegment.type === 'manual') {
        this.report.productSegment = this.productSegment;
      }
      // Get report data
      await this.report.getReport(false);
      this.reportColumns = this.report.columns;
      this.isShowProductSelector = true;
      // Transform report data to rows
      this.products = [];
      for (const index in this.report.rows) {
        if (this.report.rows[index]) {
          const row: any = {};
          // Ensure product_id is set first
          const productIdColumn = this.report.columns.find(c => c.code === 'product_id');
          row.product_id = productIdColumn?.values[index] ? Number(productIdColumn.values[index]) : null;
          
          if (row.product_id === null) continue;
          
          for (const column of this.report.columns) {
            const header = this.headers.find(h => h.value === column.code);
            if (!header) continue;

            if (column.values[index]) {
              row[column.code] = column.values[index];
            } else {
              row[column.code] = column.dataType === 'number' ? '0' : '-';
            }
          }
          this.products.push(row);
        }
      }

    this.reportColumns = this.report.columns
      .filter(c => this.headers.some(h => h.value === c.code))
      .map(c => ({
        name: c.name,
        code: c.code,
        dataType: c.dataType,
        luisMapping: c.luisMapping,
      }));
      this.isLoadingReport = false;
      // Get total count
      this.loadingCount();
    } catch (error) {
      console.error('Error loading products:', error);
      EventBus.$emit('show-snackbar', {
        message: 'Error loading products',
        color: 'error',
      });
    }
  }

  public async loadingCount() {
    this.isLoadingProductCount = true;
    if (this.productSegment.type === 'manual') {
      await this.productSegment.fetch();
      this.totalProducts = Number(this.productSegment.count);
    } else {
      await this.report.getCount(false);
      this.totalProducts = Number(this.report.total);
    }
    this.isLoadingProductCount = false;
  }

  private async refreshData() {
    try {
      // Then refresh the data
      await this.loadData();
      
      // First load the segment to get latest status
      await this.productSegment.fetch();

      // If status is processing, start polling
      if (this.productSegment.status === 'PROCESSING') {
        this.isProcessing = true;
        this.startPollingSegmentStatus();
      } else {
        this.isProcessing = false;
      }
    } catch (error) {
      console.error('Error refreshing data:', error);
      EventBus.$emit('show-snackbar', {
        message: 'Error refreshing data',
        color: 'error',
      });
    }
  }

  @Watch('tableOptions', { deep: true })
  private async onTableOptionsChange() {
    if (this.isFullLoaded) {
      this.report.page = this.tableOptions.page;
      this.report.perPage = this.tableOptions.itemsPerPage;
      this.report.sortBy = this.tableOptions.sortBy;
      this.report.sortDesc = this.tableOptions.sortDesc;
      //await this.loadData();
    }
  }

  // Existing methods
  public showEditProductSegmentDialog() {
    this.editProductSegmentDialog = true;
  }

  public showExportDialog() {
    EventBus.$emit('show-download-dialog');
  }

  public getMenuItemStyle(hover: boolean = false) {
    return hover ? 'background-color: #d4d4d4;' : '';
  }

  public notResetColumnFilter() {
    // Handle filter reset if needed
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }
    reportColumns = reportColumns.concat(this.reportColumns);
    return reportColumns;
  }

  public async saveProductSegment() {
    if (!this.newProductSegmentName) return;
    
    try {
      this.saveLoading = true;
      this.productSegment.name = this.newProductSegmentName;
      this.productSegment.description = this.newProductSegmentDescription;
      
      await this.productSegment.update();
      
      EventBus.$emit('show-snackbar', {
        message: 'Product Segment updated successfully',
        color: 'success',
      });
      
      this.editProductSegmentDialog = false;
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Error updating Product Segment',
        color: 'error',
      });
    } finally {
      this.saveLoading = false;
    }
  }

  private async toggleEditCondition() {
    if (this.isEditingCondition) {
      // Save mode - update segment
      try {
        this.saveLoading = true;
        
        // Update segment with current conditions
        this.productSegment.timerange = this.dates;
        this.productSegment.filters = this.filterColumns;
        
        await this.productSegment.update();
        
        // Reset table options and reload data
        this.tableOptions = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
        };
        
        this.isEditingCondition = false;
        await this.loadData(); // Refresh data with new conditions
        
        EventBus.$emit('show-snackbar', {
          message: 'Segment conditions updated successfully',
          color: 'success',
        });
      } catch (error) {
        EventBus.$emit('show-snackbar', {
          message: 'Error updating segment conditions',
          color: 'error',
        });
      } finally {
        this.saveLoading = false;
      }
    } else {
      // Edit mode
      this.isEditingCondition = true;
    }
  }

  private getOperatorDisplay(operator: string): string {
    const operatorMap: { [key: string]: string } = {
      'eq': 'equals',
      'ne': 'does not equal',
      'gt': 'is greater than',
      'gte': 'is greater than or equal to',
      'lt': 'is less than',
      'lte': 'is less than or equal to',
      'contains': 'contains',
      'not_contains': 'does not contain',
      'starts_with': 'starts with',
      'ends_with': 'ends with',
      'in': 'is any of',
      'not_in': 'is none of',
      'between': 'is between',
      'null': 'is empty',
      'not_null': 'is not empty'
    };
    return operatorMap[operator] || operator;
  }

  private formatFilterValue(value: any, dataType: string): string {
    if (value === null || value === undefined) return '-';
    
    switch (dataType) {
      case 'number':
      case 'decimal':
        return Number(value).toLocaleString();
      case 'currency':
        return `$${Number(value).toLocaleString()}`;
      case 'percent':
        return `${Number(value).toLocaleString()}%`;
      case 'date':
        return moment(value).format('MMM D, YYYY');
      default:
        return String(value);
    }
  }

  private async afterProductsAdded() {
    try {
      await this.loadData();
      // Start polling for segment status
      this.startPollingSegmentStatus();
    } catch (error) {
      console.error('Error after adding products:', error);
    }
  }

  private startPollingSegmentStatus() {
    // Clear any existing timer
    if (this.pollTimer) {
      window.clearInterval(this.pollTimer);
    }

    // Poll every 5 seconds
    this.pollTimer = window.setInterval(async () => {
      try {
        await this.productSegment.fetch();
        
        // If status is no longer processing, stop polling
        if (this.productSegment.status !== 'PROCESSING') {
          this.isProcessing = false;
          this.stopPollingSegmentStatus();
          // Refresh the data one final time
          await this.loadData();
        } else {
          this.isProcessing = true;
        }
      } catch (error) {
        console.error('Error polling segment status:', error);
        this.stopPollingSegmentStatus();
      }
    }, 10000);
  }

  private stopPollingSegmentStatus() {
    if (this.pollTimer) {
      window.clearInterval(this.pollTimer);
      this.pollTimer = null;
    }
  }

  // Clean up on component destroy
  beforeDestroy() {
    this.stopPollingSegmentStatus();
  }

  // Add these properties to the class
  private selectedProducts: any[] = [];
  private deleteConfirmDialog: boolean = false;
  private isDeleting: boolean = false;
  private deleteSegmentDialog: boolean = false;
  private isDeletingSegment: boolean = false;

  // Add these methods to the class
  private confirmDeleteProducts() {
    if (this.selectedProducts.length > 0) {
      this.deleteConfirmDialog = true;
    }
  }

  private async deleteSelectedProducts() {
    if (this.selectedProducts.length === 0) return;
    
    try {
      this.isDeleting = true;
      
      // Store the IDs of products being deleted
      const productIdsToDelete = this.selectedProducts.map(p => p.product_id);
      
      // Optimistically remove products from the UI immediately
      this.products = this.products.filter(p => !productIdsToDelete.includes(p.product_id));
      
      // Update the total count optimistically
      this.totalProducts -= this.selectedProducts.length;
      
      // Send delete request to the server
      await this.productSegment.removeProducts(productIdsToDelete);
      
      EventBus.$emit('show-snackbar', {
        message: `Successfully removed ${this.selectedProducts.length} products from segment`,
        color: 'success',
      });
      
      this.deleteConfirmDialog = false;
      this.selectedProducts = [];
      
      // Refresh data in the background to ensure consistency
      // but the UI has already been updated for better user experience
      setTimeout(() => {
        this.refreshData();
      }, 1000);
      
      // Emit the product selection event to parent component
      this.$emit('product-selection', this.selectedProducts);
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Error removing products from segment',
        color: 'error',
      });
      
      // If an error occurs, refresh the data to restore accurate state
      await this.refreshData();
    } finally {
      this.isDeleting = false;
    }
  }

  // Add a watcher for selectedProducts
  @Watch('selectedProducts')
  private onSelectedProductsChange() {
    this.$emit('product-selection', this.selectedProducts);
  }

  private showDeleteSegmentDialog() {
    this.deleteSegmentDialog = true;
  }

  private async deleteSegment() {
    try {
      this.isDeletingSegment = true;
      await this.productSegment.remove();
      
      EventBus.$emit('show-snackbar', {
        message: 'Segment deleted successfully',
        color: 'success',
      });
      
      this.deleteSegmentDialog = false;
      
      // Navigate back to the segments list page
      this.$router.push('/product-segment');
    } catch (error) {
      console.error('Error deleting segment:', error);
      EventBus.$emit('show-snackbar', {
        message: 'Error deleting segment',
        color: 'error',
      });
    } finally {
      this.isDeletingSegment = false;
    }
  }
}

import { get, post, del } from '@/services/http';
import { ReplenishmentSettingRecordModel } from '@/models/replenishment_setting_record';
import List from './list';

export class ReplenishmentSettingRecordList extends List<ReplenishmentSettingRecordModel> {
  public async fetch(limit: number = 5, offset: number = 0) {
    const res: any = await get(`/replenishment_settings/records`, {
      limit,
      offset,
    });
    const data = res.data || [];
    this.mapData(data);
  }

  private mapData(data: any = []) {
    this.items = [];
    for (const s of data) {
      const job = new ReplenishmentSettingRecordModel();
      job.mapData(s);
      this.add(job);
    }
  }

  public async delete(id: number) {
    await del(`/replenishment_settings/records/${id}`);
    // remove the item from the list
    const index = this.items.findIndex(item => item.id === id);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }
}

import mixpanel from 'mixpanel-browser';

import { StoreModel } from '@/models/store';

export class EventHandler {
  public store: StoreModel = new StoreModel();

  constructor(data: any = {}) {
    this.store = data.store;
  }

  public track(event: string, extra: any = {}) {
    try {
      mixpanel.track(event, {
        passedTrial: this.store.currentPackage.passedTrial,
        ...extra,
      });
    } catch (err: any) {
      console.log("Track event error: ", err);
    }
  }
}

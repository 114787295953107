
import { Component } from 'vue-property-decorator';
import SettingBase from './SettingBase';

@Component
export default class SettingText extends SettingBase {
  created() {
    if (!this.setting.value) {
      this.setting.value = this.setting.defaultValue;
    }
  }
}

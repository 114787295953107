export default class List<T> {
  public items: T[];

  constructor() {
    this.items = [];
  }

  public size(): number {
    return this.items.length;
  }

  public add(value: T): void {
    this.items.push(value);
  }

  public get(index: number): T {
    return this.items[index];
  }

  public toArray(): T[] {
    return this.items;
  }

  public toJSON(): any {
    return {
      items: this.items.map(item => {
        // If the item has its own toJSON method, use it
        if (item && typeof (item as any).toJSON === 'function') {
          return (item as any).toJSON();
        }
        // Otherwise return the item as is
        return item;
      })
    };
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import PluginConfiguration from '@/components/PluginConfiguration.vue';
import IncomingInventorySpreadSheetSettings from '@/components/IncomingInventorySpreadSheetSettings.vue';
import QuickbooksIntegration from '@/components/QuickbooksIntegration.vue';
import {post, get} from "@/services/http";

@Component({
  components: {
    ActionButton,
    Alert,
    GoogleDriveSelector,
    PluginConfiguration,
    IncomingInventorySpreadSheetSettings,
    QuickbooksIntegration
  },
})
export default class IncomingInventorySettings extends Vue {
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public selectSourceLoading: boolean = false;
  public activeTab: string = '';

  public sources: object[] = [
    {
      value: 'assisty',
      text: 'Purchase Orders from Assisty (Default)',
      disabled: true
    },
    {
      value: 'shopify',
      text: 'Purchase Orders from Shopify'
    },
    {
      value: 'spreadsheets',
      text: 'Google speardsheets file'
    },
    {
      value: 'stocky',
      text: 'Purchase Orders from app Stocky'
    },
    {
      value: 'quickbooks',
      text: 'Purchase Orders from QuickBooks'
    }
  ];
  public selectedSources: string[] = [];

  public getSourceText(value: string): string {
    const source = this.sources.find((s: any) => s.value === value);
    return source ? (source as any).text : value;
  }

  public async created() {
    try {
      this.loading = true;

      const response: any = await get('/incoming_inventory/source');
      if (response.data.source) {
        // If source is a string, split it into array
        if (typeof response.data.source === 'string') {
          const sources = response.data.source.split(',').filter(Boolean);
          this.selectedSources = ['assisty', ...sources.filter((s: string) => s !== 'assisty')];
        } else {
          const sourceArray = Array.isArray(response.data.source) 
            ? response.data.source 
            : [response.data.source];
          this.selectedSources = ['assisty', ...sourceArray.filter((s: string) => s !== 'assisty')];
        }
        // Set initial active tab
        if (this.selectedSources.length > 0) {
          this.activeTab = this.selectedSources[0];
        }
      } else {
        // If no sources are set, at least set 'assisty'
        this.selectedSources = ['assisty'];
      }

      console.log(this.selectedSources);
      if (this.selectedSources.includes('not_set_up_yet')) {
        // remove not_set_up_yet from selectedSources
        this.selectedSources = this.selectedSources.filter((s: string) => s !== 'not_set_up_yet');
      }

      this.loading = false;
      this.success = true;
    } catch (e) {
      // console.log(e);
    }
  }

  public async updateSelectedSources(sources: string[]) {
    // Ensure 'assisty' is always included
    if (!sources.includes('assisty')) {
      sources = ['assisty', ...sources];
    }
    // If no other sources selected, just keep 'assisty'
    const sourceString = sources.filter(Boolean).join(',');
    await post('/incoming_inventory/source', {source: sourceString});
    // Update active tab if current tab is removed
    if (!sources.includes(this.activeTab)) {
      this.activeTab = sources[0];
    }
  }
}

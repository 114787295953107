
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ReportFilter from '@/components/ReportFilter.vue';
import ReorderSuggestionExplain from '@/components/ReorderSuggestionExplain.vue';
import DateFilter from '@/components/DateFilter.vue';
import { SupplierModel } from '@/models/supplier';
import { SupplierList } from '@/collections/suppliers';
import { StoreLocationModel } from '@/models/store_location';
import { FilterColumnModel } from '@/models/filter_column';
import { StoreLocationList } from '@/collections/store_locations';
import { FilterColumnList } from '@/collections/filter_columns';
import { ReportModel } from '@/models/report';
import { BuyingListModel } from '@/models/buying_list';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { ParamList } from '@/collections/params';
import { StoreModel } from '@/models/store';
import { MeasureList } from "@/collections/measures";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { ProductSegmentModel } from "@/models/product_segment";
import { EventBus } from "@/main";

interface Product {
  variant_id: string;
  productName: string;
  variantName: string;
  sku: string;
  currentInventory: number;
  reorderQuantity: number;
  reorderDate: string;
  supplier_id: string;
  supplier: string;
  unitsSoldPerDay: number;
  unitsSold: number;
  inventoryRemainingDays: number;
}

interface FilterOptions {
  category: string;
  inventoryStatus: string;
  fromDate: string;
  toDate: string;
}

interface FilterData {
  dimension: DimensionModel;
  analyticsType: AnalyticsTypeModel;
  dates: string[];
  filterColumns: FilterColumnList;
  reportParams: ParamList;
  productSegment: ProductSegmentModel;
}

interface PageOptions {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

@Component({
  components: {
    HeaderTitle,
    ReportFilter,
    ReorderSuggestionExplain,
    DateFilter
  }
})
export default class BuyingTable extends Vue {
// Add new properties for ReportFilter
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public readyToGetReport: boolean = false;
  public needRefreshData: boolean = false;
  public dates: string[] = [];
  public defaultRange: string = "30d";
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportParams: ParamList = new ParamList();
  public initReportParams: ParamList = new ParamList();
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public filterReportColumns: any[] = [];
  public availableParams: ParamList = new ParamList();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public initReportFilterColumns: any = [];
  // Data properties
  selectedLocationId: string = 'all-location';
  loadingLocations = false;
  selectedProducts: any[] = [];
  totalItems = 0;
  filterDialog = false;
  buyingListDialog = false;
  fromDateMenu = false;
  toDateMenu = false;
  selectedSupplier = '';
  supplierFilter = '';
  suppliers: SupplierList = new SupplierList();
  locations: StoreLocationModel[] = [];
  loadingSuppliers = false;
  loading = false;
  
  // Filter states
  filters: FilterOptions = {
    category: '',
    inventoryStatus: '',
    fromDate: '',
    toDate: ''
  };

  // Dropdown options
  supplierOptions = [
    { text: 'All Suppliers', value: 'all' }
  ];

  categoryOptions = [
    { text: 'All Categories', value: '' },
    { text: 'Electronics', value: 'electronics' },
    { text: 'Clothing', value: 'clothing' },
    { text: 'Home & Kitchen', value: 'home_kitchen' }
  ];

  inventoryStatusOptions = [
    { text: 'All Status', value: '' },
    { text: 'Out of Stock', value: 'out_of_stock' },
    { text: 'Low Stock', value: 'low_stock' },
    { text: 'In Stock', value: 'in_stock' }
  ];

  // Table headers
  tableHeaders: DataTableHeader[] = [
    { text: 'Product', value: 'product' },
    { text: 'Historical Sales', value: 'historicalSales' },
    { text: 'Reorder Date', value: 'reorderDate' },
    { 
      text: 'Suggested Qty', 
      value: '[inventory_replenishment]',
      align: 'center'
    },
    { text: 'Reorder Qty', value: 'reorderQuantity'},
    { text: 'Total', value: 'total'}
  ];

  // Mock products (will be replaced with API data)
  products: Product[] = [];
  public filterData: FilterData = {
    dimension: new DimensionModel(),
    analyticsType: new AnalyticsTypeModel(),
    dates: [],
    filterColumns: new FilterColumnList(),
    reportParams: new ParamList(),
    productSegment: new ProductSegmentModel(),
  };
  
  recentlyCreatedLists: BuyingListModel[] = [];

  // Add to component data
  creatingAllLists: boolean = false;

  private pageOptions: PageOptions = {
    page: '1',
    itemsPerPage: '50',
    sortBy: [],
    sortDesc: [],
    multiSort: false
  };

  // Add new property for initial loading state
  private initialLoading = true;

  // Add new properties for the explanation dialog
  private showFormulaExplanation: boolean = false;
  private selectedExplanationItem: any = null;

  // Add new properties for DateFilter
  private dateRangeMenu: boolean = false;

  // Created lifecycle hook
  async created() {
    // Initialize required objects
    await this.initReportMeasure();
    
    await Promise.all([
      this.fetchSuppliers(),
      this.fetchLocations()
    ]);
    
    this.initializeFromLocalStorage();
    await this.fetchFullData();
    this.initialLoading = false;
  }

  public async initReportMeasure() {
    this.readyToGetReport = false;
    const mesaureCode = "replenishment_settings";
    this.measure.code = mesaureCode;
    if (this.measures.items.length === 0) {
        await this.measure.fetch();
    } else {
      for (const item of this.measures.items) {
        if (item.code === mesaureCode) {
          this.measure = item;
          break;
        }
      }
    }
    
    for (const item of this.measure.dimensions.items) {
      if (item.code === "by_variant") {
        this.dimension = item;
        break;
      }
    }
    this.analyticsType = this.measure.analyticsTypes.items[0];
  }

  async fetchLocations() {
    this.loadingLocations = true;
    try {
      const storeLocationList = new StoreLocationList();
      await storeLocationList.fetch();
      this.locations = storeLocationList.items;
    } catch (error) {
      console.error('Failed to fetch locations', error);
      this.$emit('error', 'Failed to load store locations');
    } finally {
      this.loadingLocations = false;
    }
  }

  // Add this private method to handle common filter creation
  private getCommonFilters(): { filters: FilterColumnList; supplierId: string } {
    const filters = new FilterColumnList();
    
    // Add location filter if a specific location is selected
    if (this.selectedLocationId && this.selectedLocationId !== 'all-location') {
      const locationFilter = new FilterColumnModel();
      locationFilter.mapData({
        code: 'location_id',
        name: 'Location',
        value: [this.selectedLocationId],
        operator: 'eq',
        dataType: 'string'
      });
      filters.add(locationFilter);
    }
    
    // Add filters from filter component
    for (const filter of this.filterColumns.items) {
      filters.add(filter);
    }
    
    let supplierId = this.supplierFilter;
    if (supplierId === 'all') {
      supplierId = '0';
    }

    return { filters, supplierId };
  }

  // Update fetchProducts to use the common filter method
  async fetchProducts() {
    this.loading = true;    
    try {
      const { filters, supplierId } = this.getCommonFilters();
      
      

      const supplier = new SupplierModel({ id: parseInt(supplierId) });
      const supplierFilter = supplier.id ? 'eq' : 'all';
      const reportOptions: any = {
        initialFilterColumns: filters, 
        pageOptions: this.pageOptions 
      };
      // Add date range to filters if dates are selected
      if (this.dates.length >= 2) {
        reportOptions.dateRange = {
          start: this.dates[0],
          end: this.dates[1]
        };
      }
      const reportModel: ReportModel = await supplier.fetchProductsWithOptions(
        'inventory_replenishment', 
        supplierFilter, 
        reportOptions
      );

      this.initReportColumns = reportModel.getReportColumnList();
      this.reportColumns = reportModel.getReportColumnList();
      this.initReportFilterColumns = reportModel.getReportColumnList();

      this.products = reportModel.getReportRowsData();
      
      // Process products
      this.products = this.products.map((product: any) => {
        const quantity = product.reorderQuantity || product['[inventory_replenishment]'] || 0;
        const unitCost = product['[average_unit_cost]'] || 0;
        return {
          ...product,
          reorderQuantity: quantity,
          total: quantity * unitCost,
          supplier_id: product['supplier_id'] || (this.supplierFilter && this.supplierFilter !== 'all' ? this.supplierFilter : 'unknown'),
          supplier: product['supplier'] || (this.supplierFilter && this.supplierFilter !== 'all' ? this.supplierFilter : 'unknown'),
          unitsSoldPerDay: product['[average_daily_units_sold_param]'] || 0,
          unitsSold: product['[sold_quantity_param]'] || 0,
          inventoryRemainingDays: product['[stock_days_inventory_planning]'] || 0,
          incomingQuantity: product['[incoming_inventory_quantity]'] || 0,
          reorderDate: product['[reorder_date]'] || 0
        };
      });
    } catch (error) {
      console.error('Failed to fetch products', error);
      this.$emit('error', 'Failed to load products');
    } finally {
      this.loading = false;
    }
  }

  // Update fetchProductsCount to use the common filter method
  async fetchProductsCount() {
    try {
      const { filters, supplierId } = this.getCommonFilters();
      
      const supplier = new SupplierModel({ id: parseInt(supplierId) });
      const supplierFilter = supplier.id ? 'eq' : 'all';
      const count = await supplier.countProductsWithOptions(
        'inventory_replenishment', 
        supplierFilter, 
        {
          initialFilterColumns: filters, 
          pageOptions: this.pageOptions 
        }
      );
      this.totalItems = Number(count.totalItems);
    } catch (error) {
      this.$emit('error', 'Failed to load product count');
    }
  }

  async fetchFullData() {
    this.loading = true;
    try {
      this.fetchProductsCount();
      await this.fetchProducts();
    } finally {
      this.loading = false;
    }
  }

  // Custom filter method for the data table
  customFilter(value: any, search: string, item: any) {
    if (search.trim() === '') return true;
    
    // Search across product name, variant name, SKU
    const searchLower = search.toString().toLowerCase();
    return (
      item.productName?.toLowerCase().includes(searchLower) ||
      item.variantName?.toLowerCase().includes(searchLower) ||
      item.sku?.toLowerCase().includes(searchLower) ||
      item['Item[Item Description]']?.toLowerCase().includes(searchLower) ||
      item.product_sku?.toLowerCase().includes(searchLower)
    );
  }

  // Get color based on stock level
  getStockLevelColor(product: any): string {
    const stockLevel = product['[Actual Inventory Quantity]'] || product.currentInventory;
    const reorderQuantity = product['[inventory_replenishment]'] || product.reorderQuantity;
    
    // If we have less than half of the recommended reorder quantity, it's critical
    if (stockLevel <= (reorderQuantity * 0.5)) return 'error';
    
    // If we have less than the recommended reorder quantity, it's a warning
    if (stockLevel <= reorderQuantity) return 'warning';
    
    // Otherwise, we're good
    return 'success';
  }

  // Dialog methods
  openFilterDialog() {
    this.filterDialog = true;
  }

  openBuyingListDialog() {
    // Clear recently created lists when opening the dialog
    this.recentlyCreatedLists = [];
    this.buyingListDialog = true;
  }

  resetFilters() {
    this.filters = {
      category: '',
      inventoryStatus: '',
      fromDate: '',
      toDate: ''
    };
  }

  async applyFilters() {
    this.filterDialog = false;
    await this.fetchProducts();
  }

  addMoreProducts() {
    // Clear recently created lists when adding more products
    this.recentlyCreatedLists = [];
    this.buyingListDialog = false;
  }

  async createBuyingList() {
    if (!this.selectedSupplier || this.selectedProducts.length === 0) {
      return;
    }
    
    try {
      const supplier = this.suppliers.items.find(s => s.id === parseInt(this.selectedSupplier));
      if (!supplier) throw new Error('Supplier not found');
      
      // Create a new buying list
      const newList = new BuyingListModel({
        name: `${supplier.name} - ${new Date().toLocaleDateString()}`,
        supplierId: supplier.id,
        status: 'active',
        items: this.selectedProducts.map((product: any) => ({
          variantId: product.variant_id,
          productName: product.productName || product['Item[Item Description]'],
          sku: product.sku || product.product_sku,
          quantity: product.reorderQuantity || product['[inventory_replenishment]'],
          unitPrice: product['[average_unit_cost]'] || 0,
          totalPrice: (product.reorderQuantity || product['[inventory_replenishment]']) * 
                      (product['[average_unit_cost]'] || 0)
        }))
      });
      
      await newList.save();
      
      // Clear localStorage after successful creation
      localStorage.removeItem('selectedProducts');
      this.selectedProducts = [];
      
      // Close dialog and notify
      this.buyingListDialog = false;
      this.$emit('success', 'Buying list created successfully');
      
      // Navigate to the buying list detail page
      this.$router.push(`/buying-lists/${newList.id}`);
    } catch (error) {
      console.error('Failed to create buying list', error);
      this.$emit('error', 'Failed to create buying list');
    }
  }

  async fetchSuppliers() {
    try {
      // Get the suppliers list
      
      await this.suppliers.fetch();
      
      // Map suppliers to options for dropdown
      this.supplierOptions = [
        { text: 'All Suppliers', value: 'all' },
        ...this.suppliers.items.map((supplier: any) => ({
          text: supplier.name,
          value: supplier.id
        }))
      ];
    } catch (error) {
      console.error('Failed to fetch suppliers', error);
      this.$emit('error', 'Failed to load suppliers');
    }
  }

  initializeFromLocalStorage() {
    const savedProducts = localStorage.getItem('selectedProducts');
    if (savedProducts) {
      try {
        this.selectedProducts = JSON.parse(savedProducts);
      } catch (e) {
        console.error('Failed to parse saved products', e);
        localStorage.removeItem('selectedProducts');
      }
    }
  }

  updateTotal(item: any) {
    const quantity = item.reorderQuantity || 0;
    const unitCost = item['[average_unit_cost]'] || 0;
    item.total = quantity * unitCost;
  }

  private formatCurrency(value: any): string {
    const currency = localStorage.getItem('currency') || 'USD';
    if (!value || isNaN(value)) value = 0;
    const numValue = typeof value === 'string' ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : value;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numValue);
  }
  
  // Add a watcher for the supplierFilter property
  @Watch('supplierFilter')
  async onSupplierFilterChanged() {
    await this.fetchFullData();
  }

  get locationOptions() {
    return [
      { id: 'all-location', name: 'All Locations' },
      ...this.locations
    ];
  }

  private async handleLocationChange(): Promise<void> {
    await this.fetchProducts();
  }

  // Add a watcher for selectedLocationId
  @Watch('selectedLocationId')
  async onLocationChanged() {
    await this.fetchFullData();
  }

  @Watch("filterData", { deep: true })
  private async onFilterDataChange() {
    this.filterColumns = this.filterData.filterColumns || new FilterColumnList();
    this.reportParams = this.filterData.reportParams || {};
    this.productSegment = this.filterData.productSegment || null;
    await this.fetchFullData();
  }

  public async removeFilter(filter: FilterColumnModel) {
    const index = this.filterColumns.items.findIndex(f => f.code === filter.code);
    if (index > -1) {
      this.filterColumns.items.splice(index, 1);
      await this.fetchFullData();
    }
  }

  public async clearFilters() {
    this.filterColumns.items = [];
    await this.fetchFullData();
  }

  // Compute products grouped by supplier
  get productsBySupplier() {
    const grouped: { [key: string]: {
      products: any[], 
      supplier: any,
      loading?: boolean,
      listCreated?: boolean,
      listId?: number
    } } = {};
    
    this.selectedProducts.forEach((product: any) => {
      const supplierId = product.supplier_id || 'unknown';
      if (!grouped[supplierId]) {
        const supplier = this.suppliers.items.find(s => s.id + '' === supplierId + '');
        grouped[supplierId] = {
          products: [], 
          supplier: supplier || { 
            id: supplierId,
            name: product.supplier || 'Unknown Supplier'
          },
          loading: false,
          listCreated: false
        };
      }
      grouped[supplierId].products.push(product);
    });
    
    return grouped;
  }

  get hasProducts() {
    return this.selectedProducts.length > 0;
  }

  // Headers for the buying list table
  buyingListTableHeaders: DataTableHeader[] = [
    { text: 'Product', value: 'name', width: '35%' },
    { text: 'Quantity', value: 'quantity', width: '15%' },
    { text: 'Actions', value: 'actions', sortable: false, width: '10%', align: 'center' }
  ];

  // Get supplier name from ID
  getSupplierName(supplierId: string): string {
    if (supplierId === 'unknown') return 'Unknown Supplier';
    
    const supplier = this.suppliers.items.find(s => s.id + '' === supplierId + '');
    return supplier ? supplier.name : 'Unknown Supplier';
  }

  // Remove product from selection
  removeProductFromSelection(product: any) {
    const index = this.selectedProducts.findIndex(p => p.variant_id === product.variant_id);
    if (index !== -1) {
      this.selectedProducts.splice(index, 1);
    }
  }

  // Create buying list for a specific supplier
  async createBuyingListForSupplier(group: any) {
    group.loading = true;
    
    try {
      const products = group.products || [];
      if (!products.length) return;
      
      const supplier = group.supplier;
      if (!supplier) {
        throw new Error('Supplier not found');
      }
      
      // Create a new buying list
      const newList = new BuyingListModel({
        name: `${supplier.name} - ${new Date().toLocaleDateString()}`,
        supplierId: supplier.id,
        status: 'active',
        items: products.map((product: any) => ({
          variantId: product.variant_id,
          productName: product.productName || product['Item[Item Description]'],
          sku: product.sku || product.product_sku,
          quantity: product.reorderQuantity || product['[inventory_replenishment]'],
          unitPrice: product['[average_unit_cost]'] || 0,
          totalPrice: (product.reorderQuantity || product['[inventory_replenishment]']) * 
                      (product['[average_unit_cost]'] || 0)
        }))
      });
      
      await newList.save();
      
      // Add to recently created lists
      this.recentlyCreatedLists.unshift(newList);
      
      // Remove the created products from the selection
      products.forEach((product: any) => {
        this.removeProductFromSelection(product);
      });
      
      // Update success state
      group.listCreated = true;
      group.listId = newList.id;
      
      // Replace emit with EventBus
      EventBus.$emit("show-snackbar", {
        message: `Buying list for ${supplier.name} created successfully`,
        color: "success"
      });
      
      if (this.selectedProducts.length === 0) {
        localStorage.removeItem('selectedProducts');
      }
      
    } catch (error) {
      console.error(`Failed to create buying list for supplier ${group.supplier.name}`, error);
      EventBus.$emit("show-snackbar", {
        message: "Failed to create buying list",
        color: "error"
      });
    } finally {
      group.loading = false;
    }
  }

  // Create buying lists for all suppliers
  async createAllBuyingLists() {
    this.creatingAllLists = true;
    
    try {
      let skippedUnknown = false;
      const groups = Object.values(this.productsBySupplier);
      
      for (const group of groups) {
        // Skip empty groups and unknown suppliers
        if (!group.products.length) continue;
        if (group.supplier.id === 'unknown') {
          skippedUnknown = true;
          continue;
        }

        try {
          await this.createBuyingListForSupplier(group);
        } catch (error) {
          console.error(`Failed to create buying list for supplier ${group.supplier.name}`, error);
          // Continue with other suppliers even if one fails
          continue;
        }
      }
      
      // Clear selection and close dialog
      this.selectedProducts = [];
      localStorage.removeItem('selectedProducts');
      
      // Show success message
      const count = this.recentlyCreatedLists.length;
      let message = `${count} buying list${count !== 1 ? 's' : ''} created successfully`;
      if (skippedUnknown) {
        message += '. Some products were skipped because they have no supplier assigned';
      }
      
      EventBus.$emit("show-snackbar", {
        message,
        color: "success"
      });
    } catch (error) {
      console.error('Failed to create buying lists', error);
      EventBus.$emit("show-snackbar", {
        message: "Failed to create buying lists",
        color: "error"
      });
    } finally {
      this.creatingAllLists = false;
    }
  }

  formatNumber(value: any): string {
    if (value === null || value === undefined) return '0.00';
    if (typeof value === 'string') value = parseFloat(value);
    if (typeof value !== 'number' || isNaN(value)) return '0.00';
    return value.toFixed(2);
  }

  formatDate(value: any): string {
    if (!value) return '-';
    
    try {
      // If it's a timestamp (number)
      if (typeof value === 'number') {
        const date = new Date(value);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
      
      // If it's a string, try to parse it
      if (typeof value === 'string') {
        const date = new Date(value);
        if (isNaN(date.getTime())) return '-'; // Invalid date
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
      
      // If it's already a Date object
      if (value instanceof Date) {
        return value.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
      
      return '-';
    } catch (error) {
      console.error('Error formatting date:', error);
      return '-';
    }
  }

  // Update the handleOptionsUpdate method
  private async handleOptionsUpdate(options: any) {
      // Check if any pagination/sorting options actually changed
      const optionsChanged = 
        options.page !== this.pageOptions.page ||
        options.itemsPerPage !== this.pageOptions.itemsPerPage ||
        JSON.stringify(options.sortBy) !== JSON.stringify(this.pageOptions.sortBy) ||
        JSON.stringify(options.sortDesc) !== JSON.stringify(this.pageOptions.sortDesc);
      if (optionsChanged) {
        // Update pageOptions before fetching
        this.pageOptions = {
          page: options.page,
          itemsPerPage: options.itemsPerPage,
          sortBy: options.sortBy,
          sortDesc: options.sortDesc,
          multiSort: false
        };
        await this.fetchProducts(); // Use fetchFullData instead of just fetchProducts
      }
  }

  // Add method to show explanation
  showExplanation(item: any) {
    this.selectedExplanationItem = item;
    this.showFormulaExplanation = true;
  }

  // Add watcher for dates changes
  @Watch('dates', { immediate: true, deep: true })
  private async onDatesChanged(newVal: string[]) {
    if (this.dates.length >= 2) {
      await this.fetchProducts(); // Refetch products with new date range
    }
  }

  // Add method to navigate to buying list detail
  private viewBuyingList(listId: number | null) {
    if (listId) {
      this.$router.push(`/buying-lists/${listId}`);
    } else {
      console.error('List ID is null');
    }
  }
}


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { PurchaseOrderModel, IPurchaseOrder, IPurchaseOrderItem } from '@/models/purchase_order';
import { SupplierModel } from '@/models/supplier';
import { StoreLocationModel } from '@/models/store_location';
import { StoreLocationList } from '@/collections/store_locations';
import dateFormat from 'dateformat';
import { jsPDF } from 'jspdf';
import { PurchaseReceived, IPurchaseReceived, IPurchaseReceivedItem } from '@/models/purchase_received';

@Component
export default class PurchaseOrderDetail extends Vue {
  @Prop({ type: [String, Number], required: true }) id!: string | number;

  @Prop({ type: Object, required: true }) store!: StoreModel;
  private purchaseOrder: PurchaseOrderModel | null = null;
  private supplier: SupplierModel | null = null;
  private location: StoreLocationModel | null = null;
  private locations: StoreLocationModel[] = [];
  private items: IPurchaseOrderItem[] = [];
  private editedPurchaseOrder: Partial<IPurchaseOrder> = {};
  private isEditing = false;
  private loading = false;
  private saving = false;
  private error: string | null = null;
  private successMessage: string | null = null;
  private dateMenu = false;
  
  // Email dialog data
  private showEmailDialog = false;
  private emailSubject = '';
  private emailMessage = '';
  private emailRecipients: string[] = [];
  private attachmentFormat = 'pdf';
  private sending = false;
  private isEmailFormValid = false;

  // Receive dialog data
  private showReceiveDialog = false;
  private receiving = false;
  private receiveError: string | null = null;
  private receiveQuantities: { [key: number]: number | string } = {};
  private receiveNotes = '';
  private receiveds: PurchaseReceived[] = [];
  private loadingHistory = false;
  private deliveryNoteNumber = '';
  private showReceiveDetailDialog = false;
  private selectedReceive: IPurchaseReceived | null = null;

  private headers = [
    { text: 'Product Name', value: 'productName', sortable: false },
    { text: 'SKU', value: 'sku' },
    { text: 'Ordered Qty', value: 'orderedQuantity', align: 'center' },
    { text: 'Received Qty', value: 'receivedQuantity', align: 'center' },
    { text: 'Unit Price', value: 'unitPrice', align: 'right' },
    { text: 'Total', value: 'totalPrice', align: 'right' },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '80px' }
  ];

  @Watch('id', { immediate: true })
  onIdChange() {
    this.fetchData();
  }

  @Watch('showReceiveDialog')
  onReceiveDialogChange(val: boolean) {
    if (val) {
      // Reset quantities when dialog opens
      this.receiveQuantities = {};
      this.receiveNotes = '';
      this.receiveError = null;
    }
  }

  private async fetchData() {
    this.loading = true;
    try {
      this.purchaseOrder = await PurchaseOrderModel.getById(Number(this.id));
      this.items = this.purchaseOrder?.items || [];
      
      // Initialize edited purchase order with current data
      this.editedPurchaseOrder = { ...this.purchaseOrder };
      
      // Fetch locations
      await this.fetchLocations();
      
      // Fetch supplier details if there's a supplier
      if (this.purchaseOrder?.supplierId) {
        this.supplier = await SupplierModel.getById(this.purchaseOrder.supplierId);
        
    //     // Pre-populate email recipient with supplier email if available
    //     if (this.supplier.contactEmail) {
    //       this.emailRecipients = [this.supplier.contactEmail];
    //     }
        
    //     // Pre-populate email subject
    //     this.emailSubject = `Purchase Order #${this.purchaseOrder.poNumber} from ${this.store.shopName}`;
        
    //     // Pre-populate email message
    //     this.emailMessage = `Dear ${this.supplier.name},\n\nPlease find attached our purchase order #${this.purchaseOrder.poNumber}.\n\nKind regards,\n${this.store.shopName}`;
      }
      
      // Fetch location details if there's a location
      if (this.purchaseOrder.locationId) {
        this.location = this.locations.find(location => location.id === this.purchaseOrder?.locationId) || null;
      }
    } catch (error: any) {
      this.error = 'Failed to load purchase order details. ' + error.message;
      console.error('Error fetching purchase order:', error.message);
    } finally {
      this.loading = false;
    }
  }

  private async fetchLocations() {
    try {
      const locationsList = new StoreLocationList();
      await locationsList.fetch();
      this.locations = locationsList.items;
    } catch (error: any) {
      console.error('Error fetching locations:', error.message);
    }
  }

  private addItem() {
    this.items.push(
        {
            productName: '',
            sku: '',
            orderedQuantity: 1,
            receivedQuantity: 0,
            unitPrice: 0,
            totalPrice: 0,
            variantId: 0
        }
    );
  }

  private removeItem(item: IPurchaseOrderItem) {
    const index = this.items.indexOf(item);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  private updateItemTotal(item: IPurchaseOrderItem) {
    item.totalPrice = item.orderedQuantity * item.unitPrice;
  }

  private get getTotalAmount(): number {
    if (!this.items) return 0;
    return this.items.reduce((total, item) => {
      const itemTotal = Number(item.unitPrice || 0) * Number(item.orderedQuantity || 0);
      return total + (isNaN(itemTotal) ? 0 : itemTotal);
    }, 0);
  }

  private async savePurchaseOrder() {
    if (!this.purchaseOrder) return;
    
    this.saving = true;
    try {
      // Update purchase order with edited data
      Object.assign(this.purchaseOrder, this.editedPurchaseOrder);
      this.purchaseOrder.items = this.items;
      
      // Calculate totals
      this.purchaseOrder.calculateTotalAmount();
      
      // Save to API
      await this.purchaseOrder.save();
      
      this.isEditing = false;
      this.successMessage = 'Purchase order updated successfully';
      
      // Update location if needed
      if (this.purchaseOrder.locationId) {
        this.location = this.locations.find(loc => loc.id === this.purchaseOrder?.locationId) || null;
      } else {
        this.location = null;
      }
    } catch (error) {
      this.error = 'Failed to update purchase order';
      console.error('Error updating purchase order:', error);
    } finally {
      this.saving = false;
    }
  }

  private cancelEdit() {
    this.isEditing = false;
    this.fetchData(); // Reset to original data
  }

  private async printPurchaseOrder() {
    if (!this.purchaseOrder) return;
    
    try {
      this.loading = true;
      const doc = new jsPDF();
      
      // Colors
      const primaryColor: [number, number, number] = [91, 192, 222];  // #5BC0DE - Turquoise color
      const secondaryColor: [number, number, number] = [100, 100, 100];
      const borderColor: [number, number, number] = [200, 200, 200];
      
      // Header section
      doc.setFillColor(...primaryColor);
      doc.rect(0, 0, 210, 40, 'F');
      
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(24);
      const shopName = this.store?.shopName || 'Store Name';
      doc.text(shopName, 105, 20, { align: 'center' });
      
      doc.setFontSize(16);
      doc.text(`Purchase Order #${this.purchaseOrder.poNumber}`, 105, 33, { align: 'center' });
      
      // Info section
      doc.setTextColor(...secondaryColor);
      doc.setFontSize(11);
      doc.text('SUPPLIER', 20, 55);
      doc.text('ORDER DATE', 20, 75);
      doc.text('EXPECTED DELIVERY', 20, 95);
      doc.text('STORE LOCATION', 120, 55);
      
      doc.setTextColor(0, 0, 0);
      doc.setFontSize(12);
      doc.text(this.supplier?.name || '-', 20, 62);
      doc.text(this.formatDate(this.purchaseOrder.orderDate), 20, 82);
      doc.text(this.formatDate(this.purchaseOrder.expectedDeliveryDate), 20, 102);
      doc.text(this.location?.name || 'All Locations', 120, 62);
      
      // Notes section (if exists)
      let currentY = 115;
      if (this.purchaseOrder.notes) {
        doc.setTextColor(...secondaryColor);
        doc.setFontSize(11);
        doc.text('NOTES', 20, currentY);
        
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        const splitNotes = doc.splitTextToSize(this.purchaseOrder.notes, 170);
        doc.text(splitNotes, 20, currentY + 7);
        currentY += (splitNotes.length * 5) + 15;
      }
      
      // Table header
      const headers = ['Product Name', 'Qty', 'Unit Price', 'Total'];
      const startX = 20;  // Starting X position
      
      // Define column positions and widths
      const columns = {
        productName: { x: startX, width: 55 },
        qty: { x: 90, width: 25, align: 'right' },
        unitPrice: { x: 120, width: 35, align: 'right' },
        total: { x: 155, width: 35, align: 'right' }
      };
      
      const tableWidth = 185;  // Adjusted total width
      
      // Header background
      doc.setFillColor(245, 245, 245);
      doc.rect(15, currentY - 5, tableWidth, 10, 'F');
      
      // Header text
      doc.setTextColor(...secondaryColor);
      doc.setFontSize(10);
      
      // Draw headers
      doc.text('Product Name', columns.productName.x, currentY);
      doc.text('Qty', columns.qty.x + columns.qty.width, currentY, { align: 'right' });
      doc.text('Unit Price', columns.unitPrice.x + columns.unitPrice.width, currentY, { align: 'right' });
      doc.text('Total', columns.total.x + columns.total.width, currentY, { align: 'right' });
      
      // Table content
      currentY += 10;
      doc.setTextColor(0, 0, 0);
      
      this.items.forEach((item, index) => {
        if (currentY > 270) {
          doc.addPage();
          currentY = 20;
        }
        
        // Alternate row background
        if (index % 2 === 0) {
          doc.setFillColor(252, 252, 252);
          doc.rect(15, currentY - 5, tableWidth, 8, 'F');
        }
        
        // Product name with SKU
        const productText = item.sku ? `${item.productName} (SKU: ${item.sku})` : item.productName;
        const splitProduct = doc.splitTextToSize(productText, columns.productName.width - 5);
        doc.text(splitProduct, columns.productName.x, currentY);
        
        // Quantities and prices
        doc.text(
          item.orderedQuantity.toString(),
          columns.qty.x + columns.qty.width,
          currentY,
          { align: 'right' }
        );
        
        doc.text(
          this.formatCurrency(item.unitPrice),
          columns.unitPrice.x + columns.unitPrice.width,
          currentY,
          { align: 'right' }
        );
        
        doc.text(
          this.formatCurrency(item.totalPrice),
          columns.total.x + columns.total.width,
          currentY,
          { align: 'right' }
        );
        
        // Increase currentY based on number of lines in product name
        currentY += Math.max(8, splitProduct.length * 5);
      });
      
      // Total section
      currentY += 5;
      doc.setDrawColor(...borderColor);
      doc.line(15, currentY - 2, 15 + tableWidth, currentY - 2);
      
      doc.setTextColor(...primaryColor);
      doc.setFontSize(12);
      doc.text('Total:', columns.unitPrice.x + columns.unitPrice.width, currentY + 5, { align: 'right' });
      doc.setFontSize(14);
      doc.text(
        this.formatCurrency(this.getTotalAmount),
        columns.total.x + columns.total.width,
        currentY + 5,
        { align: 'right' }
      );
      
      // Footer
      const pageCount = doc.getNumberOfPages();
      doc.setFontSize(8);
      doc.setTextColor(...secondaryColor);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          `Generated on ${new Date().toLocaleDateString()} - Page ${i} of ${pageCount}`,
          105,
          290,
          { align: 'center' }
        );
      }
      
      // Save PDF
      doc.save(`PO-${this.purchaseOrder.poNumber}.pdf`);
      this.successMessage = 'PDF generated successfully';
    } catch (error) {
      this.error = 'Failed to generate PDF';
      console.error('Error generating PDF:', error);
    } finally {
      this.loading = false;
    }
  }

  private async sendEmail() {
    if (!(this.$refs.emailForm as any).validate() || !this.purchaseOrder) return;
    
    this.sending = true;
    try {
      await this.purchaseOrder.sendEmail({
        subject: this.emailSubject,
        recipients: this.emailRecipients,
        message: this.emailMessage,
        format: this.attachmentFormat
      });
      
      this.showEmailDialog = false;
      this.successMessage = 'Purchase order sent successfully to supplier';
      
      // Refresh data to get updated status
      await this.fetchData();
    } catch (error) {
      this.error = 'Failed to send purchase order';
      console.error('Error sending purchase order:', error);
    } finally {
      this.sending = false;
    }
  }

  private removeRecipient(index: number) {
    this.emailRecipients.splice(index, 1);
  }

  private validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  get locationOptions(): any[] {
    return [
      { id: null, name: 'All Locations' },
      ...this.locations
    ];
  }

  get canEditPurchaseOrder(): boolean {
    if (!this.purchaseOrder) return false;
    
    // Only allow editing if status is draft or sent
    return ['draft', 'sent'].includes(this.purchaseOrder.status) && this.canManagePurchaseOrders;
  }

  get canManagePurchaseOrders(): boolean {
    const validateValues = [
      {
        code: 'purchase_order',
        value: 'unlocked'
      }
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  getStatusColor(status: string | undefined): string {
    if (!status) return 'grey';
    
    switch (status) {
      case 'draft':
        return 'grey';
      case 'sent':
        return 'blue';
      case 'confirmed':
        return 'purple';
      case 'partial_received':
        return 'orange';
      case 'received':
        return 'green';
      case 'canceled':
        return 'red';
      default:
        return 'grey';
    }
  }

  formatStatus(status: string | undefined): string {
    if (!status) return '';
    
    switch (status) {
      case 'draft':
        return 'Draft';
      case 'sent':
        return 'Sent';
      case 'confirmed':
        return 'Confirmed';
      case 'partial_received':
        return 'Partially Received';
      case 'received':
        return 'Received';
      case 'canceled':
        return 'Canceled';
      default:
        return status;
    }
  }

  formatDate(dateString: string | undefined): string {
    if (!dateString) return '-';
    return dateFormat(new Date(dateString), 'mmm d, yyyy');
  }

  formatCurrency(value: number | undefined): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(value || 0);
  }

  get canReceiveItems(): boolean {
    return (
      this.purchaseOrder?.status === 'confirmed' || 
      this.purchaseOrder?.status === 'partial_received'
    ) && this.canManagePurchaseOrders;
  }

  get isAllItemsReceived(): boolean {
    if (!this.purchaseOrder?.items) return false;
    return this.purchaseOrder.items.every(item => 
      (item.receivedQuantity || 0) >= item.orderedQuantity
    );
  }

  private async handleReceiveItems() {
    if (!this.purchaseOrder) return;
    
    this.receiving = true;
    this.receiveError = null;
    
    try {
      const updates = Object.entries(this.receiveQuantities)
        .filter(([_, qty]) => Number(qty) > 0)
        .map(([id, qty]) => ({
          id: Number(id),
          receivedQuantity: Number(qty)
        }));
      
      await this.purchaseOrder.receiveItems(
        updates,
        this.receiveNotes,
        this.deliveryNoteNumber
      );
      
      this.showReceiveDialog = false;
      this.receiveQuantities = {};
      this.receiveNotes = '';
      this.deliveryNoteNumber = '';
      
      // Show success message
      this.$store.dispatch('showSnackbar', {
        text: 'Items received successfully',
        color: 'success'
      });
      
      // Reload the purchase order data
      await this.fetchData();
      
    } catch (error: any) {
      this.receiveError = error.message;
    } finally {
      this.receiving = false;
    }
  }

  // Add computed property for validation
  get hasValidQuantities(): boolean {
    if (!this.purchaseOrder?.items) return false;
    
    // Check if any quantities are entered and valid
    const validEntries = Object.entries(this.receiveQuantities).some(([id, qty]) => {
      if (qty !== undefined && qty !== null && qty !== '') {
        const item = this.purchaseOrder!.items.find(i => Number(i.id) === Number(id));
        console.log('item=',item);
        if (!item) return false;
        const remaining = item.orderedQuantity - (item.receivedQuantity || 0);
        const numQty = Number(qty);
        return !isNaN(numQty) && numQty > 0 && numQty <= remaining;
      }
      return true; // Skip empty or undefined quantities
    });

    // Return true if we have valid entries and no invalid quantities
    return validEntries && this.invalidQuantityItems.length === 0;
  }

  get invalidQuantityItems(): IPurchaseOrderItem[] {
    if (!this.purchaseOrder?.items) return [];
    
    return this.purchaseOrder.items.filter(item => {
      if (!item.id) return false;
      const qty = this.receiveQuantities[item.id];
      
      // Skip empty or zero quantities
      if (qty === undefined || qty === null || qty === '' || Number(qty) === 0) return false;
      
      const numQty = Number(qty);
      const remaining = item.orderedQuantity - (item.receivedQuantity || 0);
      
      // Check for invalid number or exceeding remaining quantity
      return isNaN(numQty) || numQty < 0 || numQty > remaining;
    });
  }

  getProductName(purchaseOrderItemId: number): string {
    const item = this.purchaseOrder?.items.find(i => i.id === purchaseOrderItemId);
    return item ? item.productName : '-';
  }

  private calculateTotalQuantity(items: IPurchaseReceivedItem[] = []): number {
    return items.reduce((total, item) => {
      // Convert to number before adding
      const quantity = Number(item.receivedQuantity || 0);
      return total + (isNaN(quantity) ? 0 : quantity);
    }, 0);
  }

  private showReceiveDetail(receive: IPurchaseReceived) {
    this.selectedReceive = receive;
    this.showReceiveDetailDialog = true;
  }

  private getShopifyInventoryHistoryUrl(inventoryItemId: number, locationId: number): string {
    if (!this.store || !this.store.shopName) return '#';
    
    const shopName = this.store.shopName.replace('.myshopify.com', '');
    return `https://admin.shopify.com/store/${shopName}/products/inventory/${inventoryItemId}/inventory_history?location_id=${locationId}`;
  }
}

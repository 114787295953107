import { get, put } from '@/services/http';
import { InventoryTransferRuleModel } from '@/models/inventory_transfer_rule';
import List from './list';

export class InventoryTransferRuleList extends List<InventoryTransferRuleModel> {

  public async fetch() {
    const res: any = await get(`/transferrules`, {});
    const rules = res.data || [];
    this.mapData(rules);
  }

  public mapData(rules: any = []) {
    this.items = rules.map((rule: any) => {
      const transferRule = new InventoryTransferRuleModel();
      transferRule.mapData(rule);
      return transferRule;
    });
  }

  public async updateLocationRule() {
    const data: any = [];
    this.items.forEach((rule: InventoryTransferRuleModel) => {
      data.push(
        {
          scope: rule.scope,
          location_id: rule.locationId,
          location_type: rule.locationType,
          location_name: rule.locationName,
          region: rule.region,
          transferable: rule.transferable,
          receivable: rule.receivable,
          priority: rule.priority,
          tags: rule.tags,
        }
      );
    });
    const res: any = await put(`/transferrules/bulk/location`, data);
  }

  public async updateGlobalRule() {
    if (this.items.length === 0) {
      return;
    }
    const rule = this.items[0];
    const data: any = 
    {
      id: rule.id,
      scope: rule.scope,
      multiple_location_transfer: rule.multipleLocationTransfer,
      transferable_quantity_calculation: rule.transferableQuantityCalculation,
      transfer_source_selection: rule.transferSourceSelection,
      receive_source_selection: rule.receiveSourceSelection,
      historical_period: rule.historicalPeriod,
      forecast_time: rule.forecastTime,
      sales_trends: rule.salesTrends,
    }
    const res: any = await put(`/transferrules/bulk/global`, data);
  }
}

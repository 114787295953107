
import { Component, Vue, Prop } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { PurchaseOrders } from '@/collections/purchase_orders';
import { SupplierList } from '@/collections/suppliers';
import { SupplierModel } from '@/models/supplier';
import { StoreLocationList } from '@/collections/store_locations';
import { StoreLocationModel } from '@/models/store_location';
import dateFormat from 'dateformat';

@Component
export default class PurchaseOrderList extends Vue {
  @Prop({ default: () => new StoreModel() }) store!: StoreModel;

  private purchaseOrders: any[] = [];
  private totalPurchaseOrders = 0;
  private loading = false;
  private search = '';
  private filters = {
    status: '',
    supplierId: null as number | null,
    locationId: null as number | null,
    fromDate: '',
    toDate: ''
  };
  private options = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['orderDate'],
    sortDesc: [true]
  };
  private headers = [
    { text: 'PO Number', value: 'poNumber' },
    { text: 'Supplier', value: 'supplierName' },
    { text: 'Status', value: 'status' },
    { text: 'Order Date', value: 'orderDate' },
    { text: 'Expected Delivery', value: 'expectedDeliveryDate' },
    { text: 'Total', value: 'totalAmount' },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '100px' }
  ];
  private purchaseOrdersCollection = new PurchaseOrders();
  private suppliers: SupplierModel[] = [];
  private locations: StoreLocationModel[] = [];
  private loadingSuppliers = false;
  private loadingLocations = false;

  async created() {
    await this.fetchOrdersData();
    await this.fetchSuppliers();
    await this.fetchLocations();
  }

  private async fetchOrdersData() {
    await this.fetchOrders();
  }

  private async fetchOrders() {
    this.loading = true;
    try {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      const orderBy = sortBy.length > 0 ? sortBy[0] : 'createdAt';
      const orderDir = sortDesc.length > 0 && sortDesc[0] ? 'desc' : 'asc';

      await this.purchaseOrdersCollection.fetch({
        page,
        limit: itemsPerPage,
        search: this.search,
        status: this.filters.status,
        supplierId: this.filters.supplierId || undefined,
        locationId: this.filters.locationId || undefined,
        fromDate: this.filters.fromDate,
        toDate: this.filters.toDate,
        sortBy: orderBy,
        sortDir: orderDir
      });

      this.purchaseOrders = this.purchaseOrdersCollection.items;
      this.totalPurchaseOrders = this.purchaseOrdersCollection.total;
    } catch (error) {
      this.$emit('error', 'Failed to load purchase orders');
      console.error('Error fetching purchase orders:', error);
    } finally {
      this.loading = false;
    }
  }

  private async fetchSuppliers() {
    this.loadingSuppliers = true;
    try {
      const suppliersCollection = new SupplierList();
      await suppliersCollection.fetch();
      this.suppliers = suppliersCollection.items;
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    } finally {
      this.loadingSuppliers = false;
    }
  }

  private async fetchLocations() {
    this.loadingLocations = true;
    try {
      const locationsList = new StoreLocationList();
      await locationsList.fetch();
      this.locations = locationsList.items;
    } catch (error) {
      console.error('Error fetching locations:', error);
    } finally {
      this.loadingLocations = false;
    }
  }

  get statusOptions() {
    return this.purchaseOrdersCollection.getStatusOptions();
  }

  get supplierOptions() {
    return this.suppliers;
  }

  get locationOptions() {
    return this.locations;
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'draft':
        return 'grey';
      case 'sent':
        return 'blue';
      case 'confirmed':
        return 'purple';
      case 'partial_received':
        return 'orange';
      case 'received':
        return 'green';
      case 'canceled':
        return 'red';
      default:
        return 'grey';
    }
  }

  formatStatus(status: string): string {
    switch (status) {
      case 'draft':
        return 'Draft';
      case 'sent':
        return 'Sent';
      case 'confirmed':
        return 'Confirmed';
      case 'partial_received':
        return 'Partially Received';
      case 'received':
        return 'Received';
      case 'canceled':
        return 'Canceled';
      default:
        return status;
    }
  }

  formatDate(dateString: string): string {
    if (!dateString) return '-';
    return dateFormat(new Date(dateString), 'mmm d, yyyy');
  }

  formatCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(value || 0);
  }

  get canManagePurchaseOrders(): boolean {
    const validateValues = [
      {
        code: 'purchase_order',
        value: 'unlocked'
      }
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }
}


import { Component, Vue } from 'vue-property-decorator';
import ChatWithUs from '@/components/ChatWithUs.vue';

@Component({
  components: {
    ChatWithUs
  },
})
export default class GoogleSheetIntroduction extends Vue {
  // You can add any methods or properties here if needed
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import { SupplierModel } from '@/models/supplier';
import { BuyingListModel } from '@/models/buying_list';
import { BuyingLists } from '@/collections/buying_lists';
import { ReportModel } from "@/models/report";
import { FilterColumnList } from "@/collections/filter_columns";
import { StoreLocationList } from '@/collections/store_locations';
import { StoreLocationModel } from '@/models/store_location';
import { FilterColumnModel } from '@/models/filter_column';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    FeatureLockedNotify
  }
})
export default class BuyingListCreateView extends Vue {
  private supplier: SupplierModel | null = null;
  private buyingList: BuyingListModel = new BuyingListModel({});
  private availableProducts: any[] = [];
  private selectedProducts: any[] = [];
  private search = '';
  private saving = false;
  private filterType = 'all';
  private loadingAvailableProduct = false;
  private loadingLocations = false;
  private locations: StoreLocationModel[] = [];
  private storeLocationList = new StoreLocationList();
  private showDetails = false;
  private headers = [
    { text: 'Variant ID', value: 'variant_id' },
    { text: 'Name', value: 'Item[Item Description]' },
    { text: 'SKU', value: 'product_sku' },
    { text: 'Barcode', value: 'product_barcode' },
    { text: 'Current Stock', value: '[Actual Inventory Quantity]', width: '120px' },
    { text: 'Suggested Order Quantity', value: '[inventory_replenishment]', width: '80px' },
    { text: 'Quantity', value: 'quantity', width: '120px' },
    { text: 'Unit Cost', value: '[average_unit_cost]', width: '100px' },
    { text: 'Total', value: 'total', width: '120px' }
  ];
  private requiredRule = (v: string) => !!v || 'Field is required';

  private rules = {
    quantity: [(v: number) => v >= 0 || 'Quantity must be positive']
  };

  @Prop({ default: () => new StoreModel() })
  private store!: StoreModel;

  private get canManageBuyingLists(): boolean {
    const validateValues = [
      {
        code: 'buying_list',
        value: 'unlocked'
      }
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  async created() {
    this.fetchLocations();
    await this.fetchData();
    
  }

  private async fetchLocations() {
    this.loadingLocations = true;
    try {
      await this.storeLocationList.fetch();
      this.locations = this.storeLocationList.items;
    } catch (error) {
      this.$emit('error', 'Failed to load store locations');
    } finally {
      this.loadingLocations = false;
    }
  }

  private async fetchData() {
    this.loadingAvailableProduct = true;
    try {
      const supplierId = Number(this.$route.params.supplierId);
      if (!this.supplier || this.supplier.id !== supplierId) {
        this.supplier = new SupplierModel({ id: supplierId });
        await this.supplier.fetch();
        this.buyingList = new BuyingListModel({
          name: `${this.supplier.name} - ${new Date().toLocaleDateString()}`,
          supplierId: supplierId,
        });
      }

      const filters = new FilterColumnList(); 
      if (this.buyingList.locationId) {
        const locationName = this.locations.find(l => l.id === this.buyingList.locationId)?.name; 
        const locationFilter = new FilterColumnModel();
        locationFilter.mapData({
          code: 'Location[Location]',
          name: 'Store Location',
          value: [locationName],
          operator: 'eq',
          dataType: 'string'
        });
        filters.add(locationFilter);
      }

      const reportModel: ReportModel = await this.supplier.fetchProducts('inventory_replenishment', 'eq', filters);
      this.availableProducts = reportModel.getReportRowsData();
    } catch (error) {
      this.$emit('error', 'Failed to load supplier data');
    } finally {
      this.loadingAvailableProduct = false;
    }
  }

  private async handleLocationChange() {
    this.selectedProducts = []; // Clear selected products when location changes
    await this.fetchData(); // Reload products with new location filter
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private getStockLevelColor(product: any): string {
    const ratio = product.currentStock / product.recommendedReorderQuantity;
    if (ratio <= 0.5) return 'error';
    if (ratio <= 1) return 'warning';
    return 'success';
  }

  private updateItemTotal(item: any) {
    item.total = item.quantity * item[`[average_unit_cost]`];
  }

  get totalValue(): number {
    return this.selectedProducts.reduce((sum, item) => {
      return sum + (item.quantity * item[`[average_unit_cost]`]);
    }, 0);
  }

  get isValid(): boolean {
    return !!(
      this.buyingList.name &&
      this.selectedProducts.length > 0
    );
  }

  get getOrderValueStatus(): string {
    if (!this.supplier) return 'grey';
    if (this.totalValue < this.supplier.minOrderValue) return 'error';
    if (this.totalValue > this.supplier.maxOrderValue) return 'error';
    return 'success';
  }

  get getOrderValueMessage(): string {
    if (!this.supplier) return 'Invalid';
    if (this.totalValue < this.supplier.minOrderValue) {
      return 'Below Minimum';
    }
    if (this.totalValue > this.supplier.maxOrderValue) {
      return 'Exceeds Maximum';
    }
    return 'Valid Order Value';
  }

  get filteredProducts(): any[] {
    switch (this.filterType) {
      case 'selected':
        return this.availableProducts.filter(p => this.selectedProducts.some(s => s.variant_id === p.variant_id));
      case 'not-selected':
        return this.availableProducts.filter(p => !this.selectedProducts.some(s => s.variant_id === p.variant_id));
      default:
        return this.availableProducts;
    }
  }

  get locationOptions() {
    return [
      { id: null, name: 'All Locations' },
      ...this.locations
    ];
  }

  private async saveBuyingList() {
    if (!this.supplier || !this.isValid) return;
    
    this.saving = true;
    try {
      const newList = new BuyingListModel({
        name: this.buyingList.name,
        description: this.buyingList.description,
        supplierId: this.supplier.id,
        status: 'active',
        totalValue: this.totalValue,
        locationId: this.buyingList.locationId,
        items: this.selectedProducts.map(product => ({
          variantId: product.variant_id,
          productName: product['Item[Item Description]'],
          sku: product.product_sku,
          quantity: product.quantity || product['[inventory_replenishment]'],
          unitPrice: product['[average_unit_cost]'],
          totalPrice: (product.quantity || product['[inventory_replenishment]']) * product['[average_unit_cost]']
        }))
      });

      await newList.save();
      this.$emit('success', 'Buying list created successfully');
      this.$router.push(`/buying-lists/${newList.id}`);
    } catch (error) {
      this.$emit('error', 'Failed to create buying list');
    } finally {
      this.saving = false;
    }
  }
}

import { get, post, put, del } from '@/services/http';

export interface ISupplierProduct {
  id: number;
  supplierId: number;
  variantId: number;
  supplierSku: string;
  cost: number;
  replenishmentExclusion: boolean;
  leadTime: number;
  orderCycle: number;
  fixOrderQuantity: number;
  minimumOrderQuantity: number;
  maxOrderQuantity: number;
  
  isPreferred: boolean;
  status: string;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  // productInfo
  productInfo: any;
  // productSetting
  productSetting: any;
}

export class SupplierProductModel {
  id: number;
  supplierId: number;
  variantId: number;
  supplierSku: string;
  cost: number;
  replenishmentExclusion: boolean;
  leadTime: number;
  orderCycle: number;
  fixOrderQuantity: number;
  minimumOrderQuantity: number;
  maxOrderQuantity: number;
  isPreferred: boolean;
  status: string;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  // Add productInfo and productSetting objects
  productInfo: {
    productName: string;
    variantTitle: string;
    sku: string;
    barcode: string;
    vendor: string;
    imageUrl?: string;
  };
  productSetting: {
    minInventoryQuantity: number;
    maxInventoryQuantity: number;
    minStockCoverDays: number;
    maxStockCoverDays: number;
  };
  

  constructor(data: Partial<ISupplierProduct> | null = null) {
    this.id = data?.id || 0;
    this.supplierId = data?.supplierId || 0;
    this.variantId = data?.variantId || 0;
    this.supplierSku = data?.supplierSku || '';
    this.cost = data?.cost || 0;
    this.replenishmentExclusion = data?.replenishmentExclusion || false;
    this.leadTime = data?.leadTime || 0;
    this.orderCycle = data?.orderCycle || 0;
    this.fixOrderQuantity = data?.fixOrderQuantity || 0;
    this.minimumOrderQuantity = data?.minimumOrderQuantity || 1;
    this.maxOrderQuantity = data?.maxOrderQuantity || 0;
    this.isPreferred = data?.isPreferred || false;
    this.status = data?.status || 'ACTIVE';
    this.isDeleted = data?.isDeleted || false;
    this.createdAt = data?.createdAt;
    this.updatedAt = data?.updatedAt;
    // Initialize productInfo
    this.productInfo = {
      productName: data?.productInfo?.productName || '',
      variantTitle: data?.productInfo?.variantTitle || '',
      sku: data?.productInfo?.sku || '',
      barcode: data?.productInfo?.barcode || '',
      vendor: data?.productInfo?.vendor || '',
      imageUrl: data?.productInfo?.imageUrl
    };
    // Initialize productSetting
    this.productSetting = {
      minInventoryQuantity: data?.productSetting?.minInventoryQuantity || 0,
      maxInventoryQuantity: data?.productSetting?.maxInventoryQuantity || 0,
      minStockCoverDays: data?.productSetting?.minStockCoverDays || 0,
      maxStockCoverDays: data?.productSetting?.maxStockCoverDays || 0,
    };
  }

  public async fetch(): Promise<void> {
    try {
      const response: any = await get(`/supplier-products/${this.id}`);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error fetching supplier product:', error);
      throw error;
    }
  }

  public async save(): Promise<void> {
    try {
      const payload = this.toJSON();
      const response: any = await post('/supplier-products', payload);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error saving supplier product:', error);
      throw error;
    }
  }

  public async update(): Promise<void> {
    try {
      const payload = this.toJSON();
      const response: any = await put(`/supplier-products/${this.id}`, payload);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error updating supplier product:', error);
      throw error;
    }
  }

  public async remove(): Promise<void> {
    try {
      await del(`/supplier-products/${this.id}`);
    } catch (error) {
      console.error('Error removing supplier product:', error);
      throw error;
    }
  }

  public async togglePreferred(): Promise<void> {
    try {
      const response: any = await post(`/supplier-products/${this.id}/toggle-preferred`);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error toggling preferred status:', error);
      throw error;
    }
  }

  private mapData(data: any): void {
    this.id = data.id;
    this.supplierId = data.supplierId;
    this.variantId = data.variantId;
    this.supplierSku = data.supplierSku;
    this.cost = data.cost;
    this.replenishmentExclusion = data.replenishmentExclusion;
    this.leadTime = data.leadTime;
    this.orderCycle = data.orderCycle;
    this.fixOrderQuantity = data.fixOrderQuantity;
    this.minimumOrderQuantity = data.minimumOrderQuantity;
    this.maxOrderQuantity = data.maxOrderQuantity;
    this.isPreferred = data.isPreferred;
    this.status = data.status;
    this.isDeleted = data.isDeleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    
    // Map productInfo
    this.productInfo = data.productInfo;
    // Map productSetting
    this.productSetting = data.productSetting;
  }

  public toJSON(): ISupplierProduct {
    return {
      id: this.id,
      supplierId: this.supplierId,
      variantId: this.variantId,
      supplierSku: this.supplierSku,
      cost: this.cost,
      replenishmentExclusion: this.replenishmentExclusion,
      leadTime: this.leadTime,
      orderCycle: this.orderCycle,
      fixOrderQuantity: this.fixOrderQuantity,
      minimumOrderQuantity: this.minimumOrderQuantity,
      maxOrderQuantity: this.maxOrderQuantity,
      isPreferred: this.isPreferred,
      status: this.status,
      isDeleted: this.isDeleted,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      productInfo: this.productInfo,
      productSetting: this.productSetting,
    };
  }


  // Static methods for bulk operations
  static async addVariantsToSupplier(supplierId: number, variants: Array<{ variantId: number } & Partial<ISupplierProduct>>): Promise<any> {
    try {
      const response: any = await post('/supplier-products/supplier/add-variants', {
        supplierId,
        variants
      });
      return response.data;
    } catch (error) {
      console.error('Error adding variants to supplier:', error);
      throw error;
    }
  }

  static async removeVariantsFromSupplier(supplierId: number, variantIds: number[]): Promise<any> {
    try {
      const response: any = await post('/supplier-products/supplier/remove-variants', {
        supplierId,
        variantIds
      });
      return response.data;
    } catch (error) {
      console.error('Error removing variants from supplier:', error);
      throw error;
    }
  }

  static async bulkUpdateSettings(updates: Array<Partial<ISupplierProduct> & { id: number }>): Promise<any> {
    try {
      const response: any = await post('/supplier-products/bulk-update-settings', {
        updates
      });
      return response.data;
    } catch (error) {
      console.error('Error updating settings:', error);
      throw error;
    }
  }

  static async getProductsBySupplier(supplierId: number, page: number = 1, limit: number = 20): Promise<any> {
    try {
      const response: any = await get(`/supplier-products/supplier/${supplierId}`, {
        params: { page, limit }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  static async getSuppliersByVariant(variantId: number): Promise<any> {
    try {
      const response: any = await get(`/supplier-products/variant/${variantId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching variant suppliers:', error);
      throw error;
    }
  }
} 
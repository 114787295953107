
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import TableReport from '@/components/TableReport.vue';
import Setting from '@/components/settings/Setting.vue';
import { EventBus } from "@/main";
import { GoogleSpreadsheetFileModel } from '@/models/google_spreadsheet_file';
import { decodeReportQuery } from '@/services/report_query_convertor';

@Component({
  components: {
    HeaderTitle,
    TableReport,
    Setting,
  },
})
export default class GoogleSpreadsheetFileDetail extends Vue {
  private isEditing = false;
  private editedName = '';
  private editedEmails: string[] = [];
  private isSaving = false;
  private isSyncing = false;
  private settingsBackup: any = null;
  private deleteDialog = false;

  private startEdit() {
    this.editedName = this.file.name;
    this.editedEmails = [...(this.file.emails || [])];
    this.settingsBackup = JSON.parse(JSON.stringify(this.file.settings));
    this.isEditing = true;
  }

  private validateEmail(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  private removeEmail(index: number) {
    this.editedEmails.splice(index, 1);
  }

  private async save() {
    this.isSaving = true;
    try {
      // Validate emails
      if (!this.editedEmails.every(email => this.validateEmail(email))) {
        throw new Error('Invalid email format');
      }

      // Update name if changed
      if (this.editedName.trim() && this.editedName !== this.file.name) {
        this.file.name = this.editedName;
      }

      // Update emails
      this.file.emails = this.editedEmails;

      await this.file.save();
      EventBus.$emit("show-snackbar", {
        message: "Changes saved successfully",
        color: "success"
      });
      this.isEditing = false;
      this.settingsBackup = null;
    } catch (error) {
      console.log(error);
      EventBus.$emit("show-snackbar", {
        message: "Failed to save changes",
        color: "error"
      });
      // Restore original name on error
      this.editedName = this.file.name;
    } finally {
      this.isSaving = false;
    }
  }

  private cancelEdit() {
    // Restore settings from backup
    if (this.settingsBackup) {
      this.file.settings = this.settingsBackup;
    }
    this.editedName = this.file.name;
    this.editedEmails = [...(this.file.emails || [])];
    this.isEditing = false;
    this.settingsBackup = null;
  }

  get reportSheets() {
    return this.file.content?.sheets.filter(sheet => sheet.type === 'report' && sheet.reportParams) || [];
  }
  public file: GoogleSpreadsheetFileModel = new GoogleSpreadsheetFileModel();
  public isLoading = false;
  public cloning = false;
  private activeTab = 0;
  private isLoadingSheet = false;

  private async created() {
    this.isLoading = true;
    const fileId = this.$route.params.id;
    await this.fetchFile(fileId);
    await this.initReportFromContent();
    this.isLoading = false;
    
    // Set up polling for status updates if status is GENERATING
    this.setupPollingIfNeeded();
  }
  
  private pollingInterval: number | null = null;
  
  private setupPollingIfNeeded() {
    // Clear any existing polling
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
    
    // Set up polling if file is in GENERATING state
    if (this.file.status === 'GENERATING') {
      this.pollingInterval = window.setInterval(async () => {
        await this.refreshFileStatus();
      }, 10000); // Check every 10 seconds
    }
  }
  
  private async refreshFileStatus() {
    try {
      // Fetch just the file status
      await this.file.fetchStatus();
      
      // If no longer generating, stop polling
      if (this.file.status !== 'GENERATING') {
        if (this.pollingInterval) {
          clearInterval(this.pollingInterval);
          this.pollingInterval = null;
        }
        
        // If status changed to ACTIVE, show a notification
        if (this.file.status === 'ACTIVE') {
          EventBus.$emit("show-snackbar", {
            message: "Your Google Spreadsheet is now ready!",
            color: "success"
          });
        }
      }
    } catch (error) {
      console.error("Failed to refresh file status", error);
    }
  }
  
  // Clean up on component destruction
  private beforeDestroy() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  private async initReportFromContent() {
    if (!this.file.content || !this.file.content.sheets) {
      return;
    }

    try {
      for (const sheet of this.file.content.sheets) {
        if (sheet.type === 'report') {
          sheet.reportParams = await decodeReportQuery(sheet.query);
        }
      }
    } catch (error: any) {
      console.log(error)
      EventBus.$emit("show-snackbar", {
        message: "Failed to decode report configuration", 
        color: "error"
      });
    }
  }

  public async fetchFile(id: string) {
    try {
      this.file.id = Number(this.$route.params.id);
      await this.file.fetch();
    } catch (error) {
      EventBus.$emit("show-snackbar", {
        message: "An error occurred while fetching the file details.", 
        color: "error"
      });
    }
  }

  public getStatusColor(status: string): string {
    const colors: {[key: string]: string} = {
      'NEW': 'light-blue',
      'INIT': 'indigo',
      'GENERATING': 'amber',
      'ACTIVE': 'teal',
      'INACTIVE': 'grey'
    };
    return colors[status] || 'grey';
  }

  public getStatusDescription(status: string): string {
    const descriptions: {[key: string]: string} = {
      'NEW': 'File has been created but not yet initialized',
      'INIT': 'File is initializing',
      'GENERATING': 'Your Google Spreadsheet is being generated. This usually takes 1-2 minutes.',
      'ACTIVE': 'File is active and ready to use',
      'INACTIVE': 'File is inactive and not syncing data'
    };
    return descriptions[status] || '';
  }

  public formatSettingName(name: string): string {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  private getSheetHeaders(sheet: any): any[] {
    if (!sheet || !sheet.data || !sheet.data.length) return [];
    
    // Get first row as headers
    const firstRow = sheet.data[0];
    return Object.keys(firstRow).map(key => ({
      text: this.formatColumnHeader(key),
      value: key,
      sortable: true,
      filterable: true
    }));
  }

  private getSheetData(sheet: any): any[] {
    if (!sheet || !sheet.data) return [];
    return sheet.data;
  }

  private formatColumnHeader(header: string): string {
    // Convert snake_case or camelCase to Title Case
    return header
      .split(/[_.]/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  public async cloneFile() {
    this.cloning = true;
    try {
      // TODO: Implement clone functionality
      await new Promise(resolve => setTimeout(resolve, 1000)); // Simulated delay
      EventBus.$emit("show-snackbar", {
        message: "File cloned successfully",
        color: "success"
      });
    } catch (error) {
      EventBus.$emit("show-snackbar", {
        message: "Failed to clone file",
        color: "error"
      });
    } finally {
      this.cloning = false;
    }
  }

  public confirmDelete() {
    this.deleteDialog = true;
  }
  
  public async deleteFile() {
    try {
      await this.file.delete();
      EventBus.$emit("show-snackbar", {
        message: "File deleted successfully",
        color: "success"
      });
      this.deleteDialog = false;
      // Navigate back to the files list
      this.$router.push('/google-spreadsheet-files');
    } catch (error) {
      console.error(error);
      EventBus.$emit("show-snackbar", {
        message: "Failed to delete file",
        color: "error"
      });
    }
  }
  
  public async syncData() {
    this.isSyncing = true;
    try {
      await this.file.syncData();
      EventBus.$emit("show-snackbar", {
        message: "File data synced successfully",
        color: "success"
      });
    } catch (error) {
      console.error(error);
      EventBus.$emit("show-snackbar", {
        message: "Failed to sync file data",
        color: "error"
      });
    } finally {
      this.isSyncing = false;
    }
  }
}

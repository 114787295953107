
import { Component, Vue, Watch } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { GoogleSpreadsheetTemplateModel } from '@/models/google_spreadsheet_template';
import { GoogleSpreadsheetTemplateList } from "@/collections/google_spreadsheet_templates";
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
  },
})
export default class GoogleSpreadsheetTemplates extends Vue {
  public isLoading = false;
  public isCloning = false;
  public templates = new GoogleSpreadsheetTemplateList();
  public showError = false;
  public errorMessage = '';
  public isCloneFormValid = false;
  
  public templateHeaders: any = [
    { text: 'Name', value: 'name', sortable: true },
    { text: 'Description', value: 'description', sortable: true },
    { text: 'Version', value: 'version', sortable: true },
    { text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '150px' },
  ];
  
  public dialogCloneTemplate = false;
  public selectedTemplate: GoogleSpreadsheetTemplateModel = new GoogleSpreadsheetTemplateModel();
  public cloneData = {
    name: '',
    emails: [] as string[]
  };

  public async created() {
    this.fetchTemplates();
  }

  public async fetchTemplates() {
    try {
      this.isLoading = true;
      await this.templates.fetch();
    } catch (error) {
      this.showErrorMessage("Failed to load templates");
      console.error("Error fetching templates:", error);
    } finally {
      this.isLoading = false;
    }
  }

  public showDialogCloneTemplate(item: GoogleSpreadsheetTemplateModel) {
    this.selectedTemplate = item;
    this.cloneData = {
      name: `${item.name} - Copy`,
      emails: []
    };
    this.dialogCloneTemplate = true;
  }

  public validateEmail(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  public async cloneTemplate() {
    if (!(this.$refs.cloneForm as any).validate()) {
      return;
    }

    this.isCloning = true;
    try {
      const file = await this.selectedTemplate.clone({
        name: this.cloneData.name,
        emails: this.cloneData.emails
      });
      
      this.dialogCloneTemplate = false;
      EventBus.$emit("show-snackbar", "The template has been cloned successfully.", "success");
      
      // Navigate to the new file detail page
      this.$router.push({ 
        name: 'GoogleSpreadsheetFileDetail',
        params: { id: file.id.toString() }
      });
    } catch (error) {
      this.showErrorMessage("Failed to clone template");
      console.error("Error cloning template:", error);
    } finally {
      this.isCloning = false;
    }
  }

  private showErrorMessage(message: string) {
    this.errorMessage = message;
    this.showError = true;
  }
}

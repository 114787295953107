import { BuyingListModel, IBuyingList } from '@/models/buying_list';
import { get, post } from '@/services/http';

export interface BuyingListsFilter {
  page?: number;
  limit?: number;
  status?: string;
  supplierId?: number;
  locationId?: number;
  search?: string;
}

export class BuyingLists {
  items: BuyingListModel[] = [];
  total: number = 0;

  async fetch(filters: BuyingListsFilter = {}): Promise<void> {
    const response: any = await get('/buying_lists', filters);
    this.items = response.data.buyingLists.map(
      (list: IBuyingList) => new BuyingListModel(list)
    );
    this.total = response.data.pagination.total;
  }

  async fetchAll(): Promise<void> {
    const response: any = await get('/buying_lists');
    this.items = response.data.data.buyingLists.map(
      (list: IBuyingList) => new BuyingListModel(list)
    );
  }

  async fetchBySupplier(supplierId: number): Promise<void> {
    const response: any = await get('/buying_lists', {
      supplierId
    });
    this.items = response.data.buyingLists.map(
      (list: IBuyingList) => new BuyingListModel(list)
    );
  }

  async createNewList(data: Partial<IBuyingList>): Promise<BuyingListModel> {
    const newList = new BuyingListModel(data);
    await newList.save();
    this.items.push(newList);
    return newList;
  }

  async getOne(id: string): Promise<BuyingListModel> {
    const response: any = await get(`/buying_lists/${id}`);
    const buyingList: BuyingListModel = new BuyingListModel(response.data);
    // we need to fetch buying list items
    await buyingList.fetchItems();
    return buyingList;
  }
}

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import Alert from '@/components/Alert.vue';
import { PluginList } from '@/collections/plugins';
import { EventBus } from '@/main';
import ActionButton from '@/components/ActionButton.vue';

@Component({
  components: {
    Alert,
    ActionButton,
  },
})
export default class FeatureLockedNotify extends Vue { @Prop({ default: '' })
  public lockedCode!: string;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: 'Click here' })
  public callToAction!: string;
  @Prop({ default: 5 })
  public customValue!: number;
  public messages: string = '';
  public actionType: string = 'PLUGIN';
  public alertType: string = 'update';
  public title: string = '';
  public canDimiss: boolean = true;
  public pluginId: number = 0;
  public plugins: PluginList = new PluginList();
  @Prop({ default: false})
  public isPageStyle!: boolean;
  public secondaryActionTitle: string = '';
  public startupMeeting: string = 'https://calendly.com/assisty/startup-program';

  public async created() {
    await this.store.fetch();
    await this.store.loadStoreType();
    this.generateMessage();
    if (!this.plugins || this.plugins.items.length === 0) {
      await this.plugins.fetch();
    }
  }

  public openChat() {
    const openChatMessage = 'I would like to unlock the feature: ' + this.title;
    // @ts-ignore: Unreachable code error
    this.$crisp.do("chat:open");
    // @ts-ignore: Unreachable code error
    // this.$crisp.do('message:send', ['text', this.$t(`chat_with_us.${this.defaultMessage}`)]);
    this.$crisp.do("message:send", ["text", openChatMessage]);
  }

  public generateMessage() {
    switch (this.lockedCode) {
      case 'schedule_reports_count': {
        this.actionType = 'UPGRADE';
        this.title = '📊 Unlock Scheduled Report Delivery';
        this.canDimiss = false;
        this.messages = 'Streamline your workflow with automated report delivery. Get crucial insights delivered right when you need them.';
        this.pluginId = 12;
        break;
      }

      case 'download_full_data': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Data Export Feature';
        this.canDimiss = false;
        this.messages = 'Looking to export your data in formats like CSV, Excel, and Google Spreadsheet?';
        this.pluginId = 11;
        break;
      }

      case 'favorite_reports_count': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Custom Report Creation';
        this.canDimiss = false;
        this.messages = 'To create, customize, and present reports that capture the exact data you require.';
        this.pluginId = 14;
        break;
      }

      case 'multi_user_login': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Multi-User Login';
        this.canDimiss = false;
        this.messages = 'To invite team members to join your app without Shopify accounts and promote cross-functional collaboration.';
        this.pluginId = 4;
        break;
      }

      case 'add_report_dashboard': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Custom Dashboard Creation';
        this.canDimiss = false;
        this.messages = 'To design and display data exactly the way you want it.';
        this.pluginId = 13;
        break;
      }

      case 'slack_push_notification': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Slack Push Notifications';
        this.canDimiss = false;
        this.messages = 'To start receiving data updates directly in your Slack workspace and enhance your responsiveness.';
        this.pluginId = 18;
        break;
      }

      case 'data_sync_schedule': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Hourly Data Sync';
        this.canDimiss = false;
        this.messages = 'Enable our Hourly Data Sync feature to ensure real-time data accuracy and elevate your decision-making precision.';
        this.pluginId = 10;
        break;
      }

      case 'export_google_spreadsheet': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Automated Data Export';
        this.canDimiss = false;
        this.messages = 'Streamline your data management with our Automated Data Export feature for seamless integration with Google Sheets.';
        this.pluginId = 19;
        break;
      }

      case 'paid_measures': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Deeper Insights';
        this.canDimiss = false;
        this.messages = 'Upgrade now to unlock this insightful report and harness the full potential of your data-driven decision-making.';
        this.pluginId = 0;
        break;
      }

      case 'Inventory Transfer': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Movement Insights';
        this.canDimiss = false;
        this.messages = 'Gain in-depth insights into your inventory dynamics with our Inventory Movement in a Period report.';
        this.pluginId = 16;
        break;
      }

      case 'inventory_movement': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Movement Insights';
        this.canDimiss = false;
        this.messages = 'Enhance your inventory management with our Inventory Movement by Product report, offering detailed analysis of stock changes for each product';
        this.pluginId = 16;
        break;
      }

      case 'month_end_sales_inventory': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Month-End Insights';
        this.canDimiss = false;
        this.messages = 'Access our Month-End Sales and Inventory Report for a comprehensive analysis of sales and inventory trends, driving strategic business decisions.';
        this.pluginId = 16;
        break;
      }

      case 'historical_inventory': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Month-End Insights';
        this.canDimiss = false;
        this.messages = 'Unlock detailed monthly insights into sales and inventory to guide your strategic business decisions.';
        this.pluginId = 16;
        break;
      }

      case 'stock_availability': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Stock Availability Insights';
        this.canDimiss = false;
        this.messages = 'Enhance your inventory management to ensure product availability and meet customer demand effectively.';
        this.pluginId = 9;
        break;
      }

      case 'inventory_replenishment_min_max_stock_cover_days': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Replenishment Strategies';
        this.canDimiss = false;
        this.messages = 'Streamline your operations, automating strategies to maintain optimal stock levels and reduce stockouts.';
        this.pluginId = 9;
        break;
      }

      case 'inventory_replenishment_min_max': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Replenishment Strategies';
        this.canDimiss = false;
        this.messages = 'Optimize your stock levels and minimize stockouts, enhancing product availability and operational efficiency.';
        this.pluginId = 9;
        break;
      }

      case 'inventory_replenishment_min_max_stock_cover_days': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Replenishment Strategies';
        this.canDimiss = false;
        this.messages = 'To optimize stock levels, prevent stockouts, and streamline operations.';
        this.pluginId = 9;
        break;
      }

      case 'inventory_replenishment': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Reorder Suggestions';
        this.canDimiss = false;
        this.messages = 'To automate replenishment strategies, enhance product availability. Elevate your inventory management and ensure products are always in stock.';
        this.pluginId = 9;
        break;
      }


      case 'inventory_planning': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Replenishment Strategies';
        this.canDimiss = false;
        this.messages = 'To automate replenishment strategies, enhance product availability. Elevate your inventory management and ensure products are always in stock.';
        this.pluginId = 9;
        break;
      }

      case 'inventory_planning_bundle': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Bundle Product Inventory Insights';
        this.canDimiss = false;
        this.messages = 'To automate replenishment strategies for bundle components, reduce stockouts, and ensure seamless supply.';
        this.pluginId = 6;
        break;
      }

      case 'by_hour': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Hourly Sales Insights';
        this.canDimiss = false;
        this.messages = 'To stay informed about your sales performance on an hourly basis and make timely decisions.';
        this.pluginId = 17;
        break;
      }

      case 'by_product_meta_field': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Sales Insights by Product Meta Field';
        this.canDimiss = false;
        this.messages = 'Deepen your understanding of sales by analyzing sales performance based on product metadata fields.';
        this.pluginId = 21;
        break;
      }

      case 'by_category': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Sales Insights by Product Collection';
        this.canDimiss = false;
        this.messages = 'To explore product-specific sales trends based on collections and optimize your product offerings.';
        this.pluginId = 20;
        break;
      }

      case 'by_variant_option_value': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Sales Insights by Variant Options';
        this.canDimiss = false;
        this.messages = 'To explore sales trends for different product variations and make data-driven decisions.';
        this.pluginId = 7;
        break;
      }

      case 'multiple_shopify_store_analytics': {
        this.actionType = 'PLUGIN';
        this.title = 'Unlock Multiple Store Data Combination';
        this.canDimiss = false;
        this.messages = 'Allow to tracking sales & inventory from multiple stores in one place.';
        this.secondaryActionTitle = '';
        this.pluginId = 1;
        break;
      }

      case 'incoming_inventory': {
        this.actionType = 'PLUGIN';
        this.title = 'Unlock Incoming Inventory Sync';
        this.canDimiss = false;
        this.messages = 'Allow to synchronize and calculate incoming inventory from multiple sources.';
        this.secondaryActionTitle = '';
        this.pluginId = 27;
        break;
      }

      case 'inventory_transfer_suggestion': {
        this.actionType = 'PLUGIN';
        this.title = 'Unlock Inventory Transfer Suggestion';
        this.canDimiss = false;
        this.messages = 'Streamline your inventory with smart transfer suggestions for optimal stock levels. Free to install with a paid plan.';
        this.secondaryActionTitle = '';
        this.pluginId = 28;
        break;
      }

      case 'stocky': {
        this.actionType = 'PLUGIN';
        this.title = 'Unlock Stocky Integration';
        this.canDimiss = false;
        this.messages = 'Synchronize the data from Stocky to make accurate reports with incoming inventory, stock transfer resources and optimize your inventory decisions.';
        this.secondaryActionTitle = '';
        this.pluginId = 24;
        break;
      }

      case 'quickbooks_integration': {
        this.actionType = 'PLUGIN';
        this.title = 'Unlock QuickBooks Integration';
        this.canDimiss = false;
        this.messages = 'Integrate QuickBooks with our platform today and take control of your inventory with ease and confidence. Maximize efficiency, accuracy, and insights with our advanced integration features.';
        this.secondaryActionTitle = '';
        this.pluginId = 26;
        break;
      }

      case 'dashboard_1394': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Replenishment Metrics';
        this.canDimiss = false;
        this.messages = 'Insights into Inventory Replenishment and Stock Management.';
        this.secondaryActionTitle = '';
        break;
      }

      case 'dashboard_1606': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Replenishment Metrics';
        this.canDimiss = false;
        this.messages = 'Insights into Inventory Replenishment and Stock Management.';
        this.secondaryActionTitle = '';
        break;
      }

      case 'dashboard_337': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Metrics';
        this.canDimiss = false;
        this.messages = 'Current Status and Analysis of Inventory Levels and Turnover.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_358': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Remaning Days';
        this.canDimiss = false;
        this.messages = 'Estimation of Days Remaining Before Inventory Depletion.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_351': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory On-hand';
        this.canDimiss = false;
        this.messages = 'Current Status and Evaluation of On-hand Inventory Quantities.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_355': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Low Stock';
        this.canDimiss = false;
        this.messages = 'Alerts and Analysis of Products Approaching Low Stock Levels.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_356': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Overstock';
        this.canDimiss = false;
        this.messages = 'The "Overstock" dashboard identifies items with over 30 days of inventory, signaling potential excess based on recent sales.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_357': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Out-of-Stock';
        this.canDimiss = false;
        this.messages = 'Identification and Analysis of Currently Out-of-Stock Items.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_359': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Slow Moving Product';
        this.canDimiss = false;
        this.messages = 'Identification and Analysis of Products with Low Sales Velocity.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_360': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Dead Stock';
        this.canDimiss = false;
        this.messages = 'Overview of Non-Moving Inventory Classified as Dead Stock.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_364': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Aging Stock';
        this.canDimiss = false;
        this.messages = 'Analysis of Inventory Aging and Long-Term Stock Accumulation.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_365': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Value';
        this.canDimiss = false;
        this.messages = 'Current Monetary Value of Total Inventory Holdings.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_366': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Sell-through Rate';
        this.canDimiss = false;
        this.messages = 'This dashboard displays sell-through rates, key for assessing sales performance and inventory.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_367': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Turnover';
        this.canDimiss = false;
        this.messages = 'The Inventory Turnover Rate dashboard simplifies tracking how often inventory is sold and replenished.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_368': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory To Sales';
        this.canDimiss = false;
        this.messages = 'The Inventory Sales Ratio dashboard offers a clear perspective on sales compared to average inventory value.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_371': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Stock Availability';
        this.canDimiss = false;
        this.messages = 'This dashboard displays the percentage of days items are in stock, offering a quick snapshot of inventory consistency and availability.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_372': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Stockout Revenue Analysis';
        this.canDimiss = false;
        this.messages = 'The "Stockout Revenue Analysis" dashboard quantifies potential revenue loss from stockouts, highlighting their impact on sales and guiding inventory optimization.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_361': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Just-in-Time Replenishment Model';
        this.canDimiss = false;
        this.messages = 'Analysis and Insights on Implementing a Just-in-Time Inventory Replenishment Strategy.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_363': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Historical Sales-Based Forecasting';
        this.canDimiss = false;
        this.messages = 'Analyzes past sales data to estimate future inventory needs by calculating product sale speeds from historical sales trends.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_373': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Inventory Movement-Based Forecasting';
        this.canDimiss = false;
        this.messages = 'Estimates sale speed and future stock requirements by tracking inventory changes, useful when complete sales data is unavailable.';
        this.secondaryActionTitle = '';
        break;
      }
      case 'dashboard_819': {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Replenishment Master Board';
        this.canDimiss = false;
        this.messages = 'Navigate your inventory replenishment journey with ease, using this dashboard to monitor, forecast, and manage your stock levels all in one place.';
        this.secondaryActionTitle = '';
        break;
      }

      case 'supplier_detail': {
        this.actionType = 'CHAT';
        this.title = 'Supplier Detail';
        this.canDimiss = false;
        this.messages = 'Access detailed information about your suppliers, including their products, purchase history, and performance metrics.';
        this.secondaryActionTitle = '';
        break;
      }

      case 'supplier_management': {
        this.actionType = 'CHAT';
        this.title = 'Supplier Management';
        this.canDimiss = false;
        this.messages = 'Access detailed information about your suppliers, including their products, purchase history, and performance metrics.';
        this.secondaryActionTitle = '';
        break;
      }

      case 'buying_list': {
        this.actionType = 'CHAT';
        this.title = 'Buying List Management';
        this.canDimiss = false;
        this.messages = 'Access our advanced buying list features to streamline your purchasing process and manage supplier orders efficiently.';
        this.secondaryActionTitle = '';
        break;
      }

      default: {
        this.actionType = 'UPGRADE';
        this.title = 'Unlock Advanced Feature';
        this.messages = 'This advanced feature is part of our premium offering.';
        this.pluginId = 0;
        break;
      }
    }
    if (this.store.storeType === 'SMALL_STORE' && this.store.currentPackage.name === 'Free') {
      this.actionType = 'MEETING';
      this.messages = `${this.messages}\n\nAs a startup, you might be eligible for our support program! Schedule a quick chat to learn how we can help activate this feature for your business.`;
    }
  }

  public upgradePlan() {
    this.$router.push('/subscription');
  }

  public explorePlugin() {
    let hasPlugin: boolean = false;
    for (const plugin of this.plugins.items) {
      if (plugin.id === this.pluginId) {
        hasPlugin = true;
        break;
      }
    }
    if (this.pluginId) {
      this.$router.push(`/plugin/${this.pluginId}`);
    } else {
      this.$router.push('/plugins');
    }
  }

  @Watch('lockedCode', { immediate: true, deep: true })
  private async onLockedCodeChanges() {
    this.generateMessage();
  }
}


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { SupplierModel } from '@/models/supplier';
import { BuyingLists } from '@/collections/buying_lists';
import { StoreModel } from "@/models/store";
import { ReportModel } from "@/models/report";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { ParamModel } from "@/models/param";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";
import { MeasureList } from "@/collections/measures";
import ReportFilter from "@/components/ReportFilter.vue";
import { InventoryParam } from "@/models/inventory_param";
import { ProductSegmentModel } from "@/models/product_segment";
import { FilterColumnModel } from "@/models/filter_column";
import { FilterDimensionList } from "@/collections/filter_dimensions";
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import ReplenishmentParamDialog from '@/components/ReplenishmentParamDialog.vue';
import { ReplenishmentSettingRecordList } from "@/collections/replenishment_setting_records";
import { EventBus } from "@/main";
import { SupplierProductList } from '@/collections/supplier_products';
import { SupplierProductModel } from '@/models/supplier_product';
@Component({
  components: {
    ReportFilter,
    FeatureLockedNotify,
    ReplenishmentParamDialog
  }
})
export default class SupplierDetailView extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  private supplier: SupplierModel | null = null;
  private buyingLists = new BuyingLists();
  private loading = false;
  private saving = false;
  private activeTab = 'products';
  private products: any[] = [];
  private editedSupplier: SupplierModel = new SupplierModel(null);
  private addProductDialog = false;
  private productSearch = '';
  private selectedProducts: any[] = [];
  private availableProducts: any[] = [];
  private confirmUnlinkDialog = false;
  private productToUnlink: any = null;
  private settingsValid = true;
  private statusOptions = ['ACTIVE', 'INACTIVE', 'SUSPENDED'];
  private loadingProduct: boolean = false;
  private loadingAvailableProduct: boolean = false;
  private addingProducts: boolean = false;
  private reportColumns: any[] = [];
  private filterData: any = {
    dimension: new DimensionModel(),
    analyticsType: new AnalyticsTypeModel(),
    dates: [],
    filterColumns: new FilterColumnList(),
    reportParams: new ParamList(),
    productSegment: new ProductSegmentModel(),
  };
  private measure: MeasureModel = new MeasureModel({code: 'replenishment_settings'});
  private dimension: DimensionModel = new DimensionModel({code: 'by_variant'});
  private filterColumns: FilterColumnList = new FilterColumnList();
  private paramDialogVisible = false;
  private paramDialogMode: 'bulk' | 'individual' = 'individual';
  private currentParams = new InventoryParam();
  private updatingProductId: string | null = null;
  private currentlyEditingVariantId: string | null = null;
  private replenishmentSettingRecords: ReplenishmentSettingRecordList = new ReplenishmentSettingRecordList();
  private isHistoryLoading: boolean = false;
  private originalParams: {[key: string]: InventoryParam} = {}; // For tracking changes
  private supplierProducts = new SupplierProductList();

  private get isActive(): boolean {
    return this.editedSupplier.status === 'ACTIVE';
  }

  private set isActive(value: boolean) {
    this.editedSupplier.status = value ? 'ACTIVE' : 'INACTIVE';
  }

  private handleStatusChange(value: boolean): void {
    this.editedSupplier.status = value ? 'ACTIVE' : 'INACTIVE';
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }

    for (const column of this.reportColumns) {
      reportColumns.push(column);
    }
    
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      "Item[Item Category]": "product_collections",
      product_tag: "product_tags",
      order_tag: "order_tags",
      customer_tag: "customer_tags",
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  // Add validation rules
  private emailRules = [
    (v: string) => !!v || 'Email is required',
    (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid'
  ];
  private phoneRule = (v: string) => !v || /^\+?[\d\s-]+$/.test(v) || 'Phone number must be valid';
  private requiredNumberRule = (v: number) => !!v || 'Field is required';
  private positiveNumberRule = (v: number) => v >= 0 || 'Value must be positive';
  private percentageRule = (v: number) => !v || (v >= 0 && v <= 100) || 'Percentage must be between 0 and 100';
  private maxValueRule = (v: number) => v >= this.editedSupplier.minOrderValue || 'Must be greater than minimum order value';
  private maxInventoryValueRule = (v: number) => v >= this.editedSupplier.minInventoryValue || 'Must be greater than minimum inventory value';
  private requiredRule = (v: string) => !!v || 'Field is required';
  private productHeaders = [
    { text: '', value: 'data-table-select', width: '50px' },
    { text: 'Image', value: 'variant_image', width: '80px' },
    { text: 'Product', value: 'Item[Item Description]' },
    { text: 'Variant', value: 'variant' },
    { text: 'SKU', value: 'product_sku' },
    { text: 'Barcode', value: 'product_barcode' },
    { text: 'Vendor', value: 'Vendor Name' },
    { text: 'Supplier', value: 'supplier' },
    { text: 'Unit Cost', value: '[variant_unit_cost]' },
    { text: 'Replenishment Parameters', value: 'replenishment_params', sortable: false, width: '300px' },
    { 
      text: 'Actions', 
      value: 'actions', 
      sortable: false, 
      width: '200px'
    }
  ];

  private buyingListHeaders = [
    { text: 'Name', value: 'name' },
    { text: 'Status', value: 'status', width: '120px' },
    { text: 'Total Value', value: 'total_value', width: '120px' },
    { text: 'Items', value: 'items.length', width: '80px' },
    { text: 'Actions', value: 'actions', sortable: false, width: '100px' }
  ];

  private availableProductHeaders = [
    { text: 'Id', value: 'variant_id', sortable: false, width: '100px' },
    { text: 'Product', value: 'Item[Item Description]' },
    { text: 'Variant', value: 'variant' },
    { text: 'SKU', value: 'product_sku' },
    { text: 'Barcode', value: 'product_barcode' },
    { text: 'Vendor', value: 'Vendor Name' },
    { text: 'Supplier', value: 'supplier' },
    { text: 'Lead time', value: '[vendor_lead_time]' },
    { text: 'Unit Cost', value: '[variant_unit_cost]' },
  ];

  async created() {
    try {
      console.log('Component created');
      await this.fetchSupplier();
      console.log('Supplier loaded, fetching related data...');
      if (this.supplier && this.supplier.id) {
        await Promise.all([
          this.fetchProducts(),
          this.fetchBuyingLists()
        ]);
      } else {
        console.warn('No supplier data available after loading');
      }
    } catch (error) {
      console.error('Error in created hook:', error);
      this.$emit('error', 'Failed to initialize supplier details');
    }
  }

  public async fetchProducts() {
    console.log('Fetching products...');
    this.loadingProduct = true;
    this.loadingAvailableProduct = true;
    
    try {
      if (this.supplier && this.supplier.id) {
        console.log('Supplier ID:', this.supplier.id);
        
        // 1. Get supplier-product relationships
        await this.supplierProducts.fetchBySupplier(this.supplier.id);
        const assignedProducts = this.supplierProducts.items;
        this.products = assignedProducts.map(supplierProduct => {
          // Find corresponding variant settings
          
          return {
            variant_id: supplierProduct.variantId,
            variant_image: supplierProduct.productInfo?.imageUrl || '',
            'Item[Item Description]': supplierProduct.productInfo?.productName || '',
            variant: supplierProduct.productInfo?.variantTitle || '',
            product_sku: supplierProduct.productInfo?.sku || '',
            product_barcode: supplierProduct.productInfo?.barcode || '',
            'Vendor Name': supplierProduct.productInfo?.vendor || '',
            supplier: this.supplier?.name,
            '[variant_unit_cost]': supplierProduct.cost,
            
            // Supplier-level settings from supplier_products
            '[vendor_lead_time]': supplierProduct.leadTime,
            '[reorder_cycle]': supplierProduct.orderCycle,
            '[fixed_order_quantity]': supplierProduct.fixOrderQuantity,
            '[min_order_quantity]': supplierProduct.minimumOrderQuantity,
            '[max_order_quantity]': supplierProduct.maxOrderQuantity,
            '[replenishment_exclusion]': supplierProduct.replenishmentExclusion ? 1 : 0,
          };
        });

        this.loadingProduct = false;
        console.log('Assigned products:', assignedProducts);
        // 2. Get variant-level settings from replenishment_settings report
        const [assignedSettingsResponse, availableProductsResponse] = await Promise.all([
          this.supplier.fetchProducts('replenishment_settings', 'eq'),
          this.supplier.fetchProducts('replenishment_settings', 'ne', this.filterData.filterColumns)
        ]);

        // Store report columns for filters
        this.reportColumns = assignedSettingsResponse.getReportColumnList();
        
        // Get variant-level settings
        const assignedSettingsProducts = assignedSettingsResponse.getReportRowsData();
        console.log('Assigned settings products:', assignedSettingsProducts);
        this.availableProducts = availableProductsResponse.getReportRowsData();
        console.log('Available products:', this.availableProducts);
        // 3. Merge supplier-product data with variant-level settings
        this.products = assignedProducts.map(supplierProduct => {
          // Find corresponding variant settings
          const variantSettings = assignedSettingsProducts.find(
            p => p.variant_id === supplierProduct.variantId
          );

          return {
            variant_id: supplierProduct.variantId,
            variant_image: supplierProduct.productInfo?.imageUrl || '',
            'Item[Item Description]': supplierProduct.productInfo?.productName || '',
            variant: supplierProduct.productInfo?.variantTitle || '',
            product_sku: supplierProduct.productInfo?.sku || '',
            product_barcode: supplierProduct.productInfo?.barcode || '',
            'Vendor Name': supplierProduct.productInfo?.vendor || '',
            supplier: this.supplier?.name,
            '[variant_unit_cost]': supplierProduct.cost,
            
            // Supplier-level settings from supplier_products
            '[vendor_lead_time]': supplierProduct.leadTime,
            '[reorder_cycle]': supplierProduct.orderCycle,
            '[fixed_order_quantity]': supplierProduct.fixOrderQuantity,
            '[min_order_quantity]': supplierProduct.minimumOrderQuantity,
            '[max_order_quantity]': supplierProduct.maxOrderQuantity,
            '[replenishment_exclusion]': supplierProduct.replenishmentExclusion ? 1 : 0,
            
            // Variant-level settings from replenishment_settings
            '[min_inventory_quantity]': variantSettings?.['[min_inventory_quantity]'],
            '[max_inventory_quantity]': variantSettings?.['[max_inventory_quantity]'],
            '[min_stock_cover_days]': variantSettings?.['[min_stock_cover_days]'],
            '[max_stock_cover_days]': variantSettings?.['[max_stock_cover_days]'],
          };
        });

        console.log('Loaded products:', this.products);
      }
    } catch (error) {
      console.error('Error loading products:', error);
      this.$emit('error', 'Failed to load products');
    } finally {
      this.loadingProduct = false;
      this.loadingAvailableProduct = false;
    }
  }

  private async fetchBuyingLists() {
    this.loading = true;
    try {
      await this.buyingLists.fetchBySupplier(this.supplier!.id);
    } catch (error) {
      this.$emit('error', 'Failed to load buying lists');
    } finally {
      this.loading = false;
    }
  }

  private async fetchSupplier() {
    console.log('Fetching supplier...');
    this.loading = true;
    try {
      const { id } = this.$route.params;
      console.log('Route params id:', id);
      if (!id) {
        throw new Error('Supplier ID is required');
      }

      // Create new supplier model and fetch details
      this.supplier = new SupplierModel({ id: parseInt(id, 10) });
      await this.supplier.getSupplierDetails();
      console.log('Loaded supplier:', this.supplier);
      // Create a copy for editing
      this.editedSupplier = new SupplierModel(this.supplier);
    } catch (error) {
      console.error('Error loading supplier:', error);
      this.$emit('error', 'Failed to load supplier details');
    } finally {
      this.loading = false;
    }
  }

  private formatNumber(value: number | undefined): string {
    if (!value) return '0';
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  private getStatusColor(metrics: any[] | undefined): string {
    if (!metrics) return 'grey';
    const fillRate = metrics.find(m => m.label === 'Order Fill Rate');
    if (!fillRate) return 'grey';
    const value = parseFloat(fillRate.value);
    if (value >= 95) return 'success';
    if (value >= 90) return 'warning';
    return 'error';
  }

  private getSupplierStatus(metrics: any[] | undefined): string {
    if (!metrics) return 'Unknown Performance';
    const fillRate = metrics.find(m => m.label === 'Order Fill Rate');
    if (!fillRate) return 'Unknown Performance';
    const value = parseFloat(fillRate.value);
    if (value >= 95) return 'Excellent';
    if (value >= 90) return 'Good';
    return 'Needs Improvement';
  }

  private getMetricTrendColor(metric: any): string {
    if (!metric.trend) return 'grey';
    return metric.trend.isPositive ? 'success' : 'error';
  }

  private getMetricTrendIcon(metric: any): string {
    if (!metric.trend) return 'mdi-minus';
    return metric.trend.isPositive ? 'mdi-arrow-up' : 'mdi-arrow-down';
  }

  private getStockLevelColor(item: any): string {
    const stock = item.currentStock;
    if (stock <= 0) return 'error';
    if (stock <= 10) return 'warning';
    return 'success';
  }

  private getProductMetricColor(metrics: any): string {
    if (metrics.demand_variability === 'High') return 'error';
    if (metrics.demand_variability === 'Medium') return 'warning';
    return 'success';
  }

  private getBuyingListStatusColor(status: string): string {
    switch (status) {
      case 'draft':
        return 'grey';
      case 'finalized':
        return 'primary';
      case 'completed':
        return 'success';
      case 'canceled':
        return 'error';
      default:
        return 'grey';
    }
  }

  private createBuyingList() {
    if (!this.supplier) return;
    this.$router.push({
      name: 'createBuyingList',
      params: { supplierId: this.supplier.id.toString() }
    });
  }

  private viewBuyingList(list: any) {
    this.$router.push('/buying-lists/' + list.id);
  }

  private async saveSettings() {
    if (!this.settingsValid || !this.supplier) return;
    this.saving = true;
    try {
      await this.editedSupplier.update();
      this.$emit('success', 'Settings updated successfully');
    } catch (error) {
      this.$emit('error', 'Failed to update settings');
    } finally {
      this.saving = false;
    }
  }

  private showAddProductDialog() {
    // Get all unassigned products
    this.selectedProducts = [];
    this.addProductDialog = true;
  }

  private async addSelectedProducts() {
    if (!this.supplier) return;
    
    // Create new product objects with supplier info
    const newProducts = this.selectedProducts.map(product => ({
      ...product,
      Supplier: this.supplier!.name
    }));
    
    // Store locally for persistence across refreshes
    const optimisticProducts = [...newProducts];
    
    try {
      // Update UI optimistically
      this.products = [...this.products, ...newProducts];
      this.addingProducts = true;
      
      // Call API
      await this.supplier.addProducts(this.selectedProducts, this.filterData);
      // Close dialog immediately after API call
      this.addProductDialog = false;
      this.selectedProducts = [];
      
      // Fetch updated list but preserve optimistic additions
      const response = await this.supplier.fetchProducts('replenishment_settings', 'eq');
      const backendProducts = response.getReportRowsData();
      
      // Merge backend products with optimistic ones (filtering duplicates)
      const mergedProducts = [...backendProducts];
      
      // Add optimistic products that aren't in backend response yet
      optimisticProducts.forEach(newProduct => {
        if (!backendProducts.some(p => p.variant_id === newProduct.variant_id)) {
          // Mark as pending with visual indicator
          mergedProducts.push({...newProduct, isPending: true});
        }
      });
      
      this.products = mergedProducts;
      this.$emit('success', 'Products added successfully. Changes may take a moment to appear.');
    } catch (error) {
      await this.fetchProducts();
      this.$emit('error', 'Failed to add products');
    } finally {
      this.addingProducts = false;
      this.addProductDialog = false;
      this.selectedProducts = [];
    }
  }

  private showUnlinkConfirmation(product: any) {
    this.productToUnlink = product;
    this.confirmUnlinkDialog = true;
  }

  private async confirmUnlinkProduct() {
    if (!this.productToUnlink) return;
    
    this.unlinkProduct(this.productToUnlink);
    this.confirmUnlinkDialog = false;
    this.productToUnlink = null;
  }

  private async unlinkProduct(product: any) {
    if (!this.supplier) return;
    
    try {
      // Remove the product from the local state
      this.products = this.products.filter(p => p.variant_id !== product.variant_id);
      
      //update the available products by add the product back to the unassigned products
      this.availableProducts.push(product);
      // call to remove products from supplier
      await this.supplier.removeProducts([product]);

      this.$emit('success', `Unlinked ${product.name} from supplier`);
    } catch (error) {
      this.$emit('error', 'Failed to unlink product from supplier');
    }
  }

  private getChipColor(value: number, trend: 'up' | 'down'): string {
    if (trend === 'up') {
      return value > 10 ? 'error' : 'success';
    }
    return value > 10 ? 'warning' : 'success';
  }

  // Add the validation check
  private get canViewSupplierDetail(): boolean {
    const validateValues = [
      {
        code: 'supplier_detail',
        value: 'unlocked'
      }
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }

  // Add watcher for filterData
  @Watch('filterData', { deep: true })
  async onFilterDataChange() {
    if (this.addProductDialog) {
      // Only reload when dialog is open
      await this.fetchAvailableProducts();
    }
  }

  // Add a separate method for fetching available products
  private async fetchAvailableProducts() {
    this.loadingAvailableProduct = true;
    try {
      if (this.supplier && this.supplier.id) {
        
        const reportModel = await this.supplier.fetchProducts('replenishment_settings', 'ne', this.filterData.filterColumns);
        this.availableProducts = reportModel.getReportRowsData();
      }
    } catch (error) {
      this.$emit('error', 'Failed to load available products');
    } finally {
      this.loadingAvailableProduct = false;
    }
  }

  private showBulkParamDialog() {
    this.paramDialogMode = 'bulk';
    this.currentParams = new InventoryParam();
    this.paramDialogVisible = true;
  }

  private async openParamDialog(product: any) {
    this.paramDialogMode = 'individual';
    this.currentlyEditingVariantId = product.variant_id;
    this.currentParams = await this.fetchProductParams(product.variant_id);
    this.paramDialogVisible = true;
  }

  private async fetchProductParams(variantId: string): Promise<InventoryParam> {
    try {
      this.updatingProductId = variantId;
      
      // Find the product in the list
      const product = this.products.find(p => p.variant_id === variantId);
      
      // Create a new InventoryParam instance
      const params = new InventoryParam();
      
      // If we have a supplier, set its ID
      if (this.supplier) {
        params.supplierId = this.supplier.id;
      }
      
      // If product exists, extract parameters from it
      if (product) {
        // Set parameters from product data
        params.minStockCoverDays = product['[min_stock_cover_days]'];
        params.maxStockCoverDays = product['[max_stock_cover_days]'];
        params.minInventoryQuantity = product['[min_inventory_quantity]'];
        params.maxInventoryQuantity = product['[max_inventory_quantity]'];
        params.leadtime = product['[vendor_lead_time]'];
        params.orderCycle = product['[reorder_cycle]'];
        params.fixOrderQuantity = product['[fixed_order_quantity]'];
        params.minOrderQuantity = product['[min_order_quantity]'];
        params.maxOrderQuantity = product['[max_order_quantity]'];
        params.replenishmentExclusion = product['[replenishment_exclusion]'] === 1;
        
        console.log('Loaded parameters from product:', variantId, params);
      }
      
      // Store original params for change tracking
      this.originalParams[variantId] = new InventoryParam();
      this.originalParams[variantId].mappData(params);
      
      return params;
    } catch (error) {
      console.error('Failed to fetch product parameters:', error);
      EventBus.$emit('show-snackbar', 'Failed to load product parameters', 'error');
      return new InventoryParam();
    } finally {
      this.updatingProductId = null;
    }
  }

  private validateParams(params: InventoryParam): { valid: boolean, message: string } {
    // Check minimum/maximum values
    if (
      params.minInventoryQuantity !== undefined && 
      params.maxInventoryQuantity !== undefined && 
      Number(params.minInventoryQuantity) > Number(params.maxInventoryQuantity)
    ) {
      return { 
        valid: false, 
        message: 'Min inventory quantity cannot be greater than max inventory quantity' 
      };
    }
    
    if (
      params.minStockCoverDays !== undefined && 
      params.maxStockCoverDays !== undefined && 
      Number(params.minStockCoverDays) > Number(params.maxStockCoverDays)
    ) {
      return { 
        valid: false, 
        message: 'Min stock cover days cannot be greater than max stock cover days' 
      };
    }
    
    // Validate numeric ranges
    if (params.leadtime !== undefined && Number(params.leadtime) < 0) {
      return { valid: false, message: 'Lead time cannot be negative' };
    }
    
    if (params.orderCycle !== undefined && Number(params.orderCycle) < 0) {
      return { valid: false, message: 'Order cycle cannot be negative' };
    }
    
    // Add more validation as needed
    
    return { valid: true, message: '' };
  }

  private async handleParamUpdate({ params, scope }: { params: InventoryParam, scope: string }) {
    try {
      // Validate parameters
      const validation = this.validateParams(params);
      if (!validation.valid) {
        EventBus.$emit('show-snackbar', validation.message, 'error');
        return;
      }
      
      let variantIds: string[] = [];
      
      // If we're in individual mode, ensure we use the currentlyEditingVariantId
      if (this.paramDialogMode === 'individual' && this.currentlyEditingVariantId) {
        variantIds = [this.currentlyEditingVariantId];
      } else {
        // Handle bulk update scopes
        switch (scope) {
          case 'all':
            variantIds = this.products.map(p => p.variant_id);
            break;
          case 'selected':
            variantIds = this.selectedProducts.map(p => p.variant_id);
            break;
          case 'filtered':
            variantIds = this.products.map(p => p.variant_id);
            break;
        }
      }
      
      if (variantIds.length === 0) {
        EventBus.$emit('show-snackbar', 'No products selected for update', 'warning');
        return;
      }
      
      // Only show confirmation for bulk updates
      if (variantIds.length > 1 && this.paramDialogMode !== 'individual') {
        if (!confirm(`Are you sure you want to update parameters for ${variantIds.length} products?`)) {
          return;
        }
      }
      
      // Apply optimistic updates immediately to UI
      variantIds.forEach(variantId => {
        this.updateProductOptimistically(variantId, params);
      });
      
      // Continue with the server update
      await this.updateProductParams(variantIds, params);
      
      this.paramDialogVisible = false;
      EventBus.$emit('show-snackbar', `Parameters updated successfully`, 'success');
      
    } catch (error) {
      console.error('Error in parameter update handler:', error);
      EventBus.$emit('show-snackbar', 'An error occurred while updating parameters', 'error');
      // Since the update failed, we should refresh to get the correct data
      this.fetchProducts();
    }
  }

  private handleParamDialogClose() {
    this.paramDialogVisible = false;
    this.currentParams = new InventoryParam();
    this.updatingProductId = null;
    
    // Reset any dirty state tracking
    // This is optional, depending on your UX design
    if (this.currentlyEditingVariantId) {
      delete this.originalParams[this.currentlyEditingVariantId];
      this.currentlyEditingVariantId = null;
    }
  }

  private async updateProductParams(variantIds: string[], params: InventoryParam) {
    try {
      console.log('Updating product params:', variantIds, params);
      if (!this.supplier) throw new Error('No supplier selected');

      // 1. Update ALL settings using report-based approach
      const report = new ReportModel();
      report.measure = new MeasureModel({code: 'replenishment_settings'});
      report.dimension = new DimensionModel({code: 'by_variant'});
      
      const filterColumns = new FilterColumnList();
      filterColumns.add(new FilterColumnModel({
        code: 'variant_id',
        name: 'Variant ID',
        operator: 'in',
        value: variantIds
      }));
      report.filterColumns = filterColumns;
      
      // Generate report params
      const reportRequestParams = report.generateParams();
      
      // Create history record with ALL parameters
      const currentDateTime = new Date().toISOString();
      const setting = {
        date: currentDateTime,
        productSegment: new ProductSegmentModel(),
        filterColumns: filterColumns,
        inventoryParam: {
          // Variant-level settings
          minInventoryQuantity: Number(params.minInventoryQuantity),
          maxInventoryQuantity: Number(params.maxInventoryQuantity),
          minStockCoverDays: Number(params.minStockCoverDays),
          maxStockCoverDays: Number(params.maxStockCoverDays),
          // Supplier-level settings
          leadTime: Number(params.leadtime),
          orderCycle: Number(params.orderCycle),
          fixOrderQuantity: Number(params.fixOrderQuantity),
          minimumOrderQuantity: Number(params.minOrderQuantity),
          maxOrderQuantity: Number(params.maxOrderQuantity),
          replenishmentExclusion: params.replenishmentExclusion
        }
      };

      // 2. Additionally save supplier-level settings to supplier_products table
      const supplierProducts = this.supplierProducts.items;
      const supplierLevelUpdates = variantIds.map(variantId => {
        const supplierProduct = supplierProducts.find(p => Number(p.variantId) === Number(variantId));
        if (supplierProduct) {
          return {
            id: supplierProduct.id,
            leadTime: Number(params.leadtime),
            orderCycle: Number(params.orderCycle),
            fixOrderQuantity: Number(params.fixOrderQuantity), 
            minimumOrderQuantity: Number(params.minOrderQuantity),
            maxOrderQuantity: Number(params.maxOrderQuantity),
            replenishmentExclusion: params.replenishmentExclusion,
            productSetting: {
              minInventoryQuantity: Number(params.minInventoryQuantity),
              maxInventoryQuantity: Number(params.maxInventoryQuantity),
              minStockCoverDays: Number(params.minStockCoverDays),
              maxStockCoverDays: Number(params.maxStockCoverDays),
            },
          };
        }
        return null;
      });

      // Check if all inventory level parameters are not set
      const hasInventoryParams = 
        params.minInventoryQuantity !== undefined ||
        params.maxInventoryQuantity !== undefined ||
        params.minStockCoverDays !== undefined ||
        params.maxStockCoverDays !== undefined;

      // Execute saves based on parameter presence
      await Promise.all([
        // Only save to report system if there are inventory parameters
        ...(hasInventoryParams ? [params.save(reportRequestParams, setting)] : []),
        // Always save supplier-level settings to supplier_products
        SupplierProductModel.bulkUpdateSettings(supplierLevelUpdates.filter(update => update !== null) as any)
      ]);

    } catch (error) {
      console.error('Failed to update product parameters:', error);
      throw error;
    }
  }

  private async getReplenishmentSettingRecords() {
    this.isHistoryLoading = true;
    try {
      await this.replenishmentSettingRecords.fetch();
    } catch (error) {
      console.error('Failed to fetch parameter history:', error);
      EventBus.$emit('show-snackbar', 'Failed to load parameter history', 'error');
    } finally {
      this.isHistoryLoading = false;
    }
  }

  private isParamDirty(variantId: string, currentParams: InventoryParam): boolean {
    if (!this.originalParams[variantId]) {
      return false;
    }
    
    const original = this.originalParams[variantId];
    
    // Compare important fields
    return (
      original.supplierId !== currentParams.supplierId ||
      original.replenishmentExclusion !== currentParams.replenishmentExclusion ||
      original.minInventoryQuantity !== currentParams.minInventoryQuantity ||
      original.maxInventoryQuantity !== currentParams.maxInventoryQuantity ||
      original.minStockCoverDays !== currentParams.minStockCoverDays ||
      original.maxStockCoverDays !== currentParams.maxStockCoverDays ||
      original.leadtime !== currentParams.leadtime ||
      original.orderCycle !== currentParams.orderCycle
    );
  }

  private updateProductOptimistically(variantId: string, params: InventoryParam) {
    // Find the product in the list
    const index = this.products.findIndex(p => p.variant_id === variantId);
    if (index === -1) {
      return;
    }
    
    // Apply parameter values to the product in the UI
    if (params.minInventoryQuantity !== undefined) {
      this.products[index]['[min_inventory_quantity]'] = params.minInventoryQuantity;
    }
    if (params.maxInventoryQuantity !== undefined) {
      this.products[index]['[max_inventory_quantity]'] = params.maxInventoryQuantity;
    }
    if (params.minStockCoverDays !== undefined) {
      this.products[index]['[min_stock_cover_days]'] = params.minStockCoverDays;
    }
    if (params.maxStockCoverDays !== undefined) { 
      this.products[index]['[max_stock_cover_days]'] = params.maxStockCoverDays;
    }
    if (params.leadtime !== undefined) {
      this.products[index]['[vendor_lead_time]'] = params.leadtime;
    }
    if (params.orderCycle !== undefined) {
      this.products[index]['[reorder_cycle]'] = params.orderCycle;
    }
    if (params.fixOrderQuantity !== undefined) {
      this.products[index]['[fixed_order_quantity]'] = params.fixOrderQuantity;
    }
    if (params.minOrderQuantity !== undefined) {
      this.products[index]['[min_order_quantity]'] = params.minOrderQuantity;
    }
    if (params.maxOrderQuantity !== undefined) {
      this.products[index]['[max_order_quantity]'] = params.maxOrderQuantity;
    }

    if (params.replenishmentExclusion !== undefined) {
      this.products[index]['[replenishment_exclusion]'] = params.replenishmentExclusion ? 1 : 0;
    }
    
    // Mark the product as recently updated for visual feedback
    this.products[index].recentlyUpdated = true;
    
    // Clear the recently updated flag after a delay
    setTimeout(() => {
      if (this.products[index]) {
        this.products[index].recentlyUpdated = false;
      }
    }, 5000);
  }

  private hasAnyParameters(item: any): boolean {
    return (
      item['[min_inventory_quantity]'] !== undefined ||
      item['[max_inventory_quantity]'] !== undefined ||
      item['[min_stock_cover_days]'] !== undefined ||
      item['[max_stock_cover_days]'] !== undefined ||
      item['[vendor_lead_time]'] !== undefined ||
      item['[reorder_cycle]'] !== undefined ||
      item['[replenishment_exclusion]'] !== undefined
    );
  }

  private formatValue(value: any): string {
    if (value === undefined || value === null) {
      return '-';
    }
    if (typeof value === 'number') {
      return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    return String(value);
  }
}

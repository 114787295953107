interface DropdownValue {
  value: string | number;
  label: string;
}

export interface DateRange {
  startDate: string;
  endDate: string;
  type: string;
}

export class SettingModel {
  public settingType: 'text' | 'number' | 'boolean' | 'dropdown' | 'datetime' | 'daterange' | 'multiple' | 'date';
  public name: string;
  public label: string;
  public description?: string;
  public defaultValue: string | number | boolean | DateRange | Array<string | number>;
  public value: string | number | boolean | DateRange | Array<string | number>;
  public dropdownValues: DropdownValue[];

  constructor(data: any = {}) {
    this.settingType = data.settingType;
    this.name = data.name;
    this.label = data.label;
    this.defaultValue = data.defaultValue;
    this.value = data.value;
    this.dropdownValues = data.dropdownValues;
  }

  public mapData(data: any): SettingModel {
    this.settingType = data.type;
    this.name = data.name;
    this.label = data.label;
    this.description = data.description;
    this.defaultValue = data.default;
    this.value = data.value ?? data.default;
    this.dropdownValues = data.values;
    return this;
  }

  public updateValue(newValue: any): void {
    this.value = newValue;
  }

  public toJSON() {
    return {
      settingType: this.settingType,
      name: this.name,
      label: this.label,
      description: this.description,
      defaultValue: this.defaultValue,
      value: this.value,
      dropdownValues: this.dropdownValues,
    };
  }
}

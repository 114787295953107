
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import GoogleSheetIntroduction from '@/components/GoogleSheetIntroduction.vue';

@Component({
  components: {
    HeaderTitle,
    GoogleSheetIntroduction
  },
})
export default class GoogleSpreadsheetIntroductionView extends Vue {
  public async created() {
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import moment from 'moment';
import { PurchaseOrders } from '@/collections/purchase_orders';
import { StoreLocationList } from '@/collections/store_locations';
import { StoreLocationModel } from '@/models/store_location';

@Component
export default class ConvertToPODialog extends Vue {
  @Prop({ type: Boolean, default: false }) value!: boolean;
  @Prop({ type: Number, default: 0 }) buyingListId!: number;
  @Prop({ type: String, default: '' }) buyingListName!: string;
  @Prop({ type: String, default: '' }) supplierName!: string;
  @Prop({ type: Number, default: 0 }) totalValue!: number;

  private show = false;
  private isFormValid = false;
  private loading = false;
  private errorMessage = '';
  private deliveryDate = '';
  private dateMenu = false;
  private notes = '';
  private locationId: number | null = null;
  private locations: StoreLocationModel[] = [];
  private loadingLocations = false;

  get minDate(): string {
    return moment().format('YYYY-MM-DD');
  }

  get formattedDeliveryDate(): string {
    if (!this.deliveryDate) return '';
    return dateFormat(new Date(this.deliveryDate), 'mmmm d, yyyy');
  }

  @Watch('value')
  onValueChange(val: boolean): void {
    this.show = val;
    if (val) {
      // Set default delivery date to 7 days from now
      const date = moment().add(7, 'days');
      this.deliveryDate = date.format('YYYY-MM-DD');
    }
  }

  @Watch('show')
  onShowChange(val: boolean): void {
    this.$emit('input', val);
    if (val) {
      this.fetchLocations();
    }
  }

  private async fetchLocations() {
    this.loadingLocations = true;
    try {
      const storeLocationList = new StoreLocationList();
      await storeLocationList.fetch();
      this.locations = storeLocationList.items;
    } catch (error) {
      this.$emit('error', 'Failed to load store locations');
    } finally {
      this.loadingLocations = false;
    }
  }

  get locationOptions() {
    return this.locations.map(location => ({
      id: location.id,
      name: location.name
    }));
  }

  public close(): void {
    this.show = false;
    this.errorMessage = '';
    this.resetForm();
  }

  private resetForm(): void {
    this.notes = '';
    this.locationId = null;
    if (this.$refs.form) {
      (this.$refs.form as any).reset();
    }
  }

  private async convert(): Promise<void> {
    if (!this.isFormValid || !this.buyingListId || !this.locationId) return;
    
    this.loading = true;
    this.errorMessage = '';
    
    try {
      // Call the API to convert buying list to PO
      const purchaseOrders = new PurchaseOrders();
      const newPurchaseOrder = await purchaseOrders.createFromBuyingList(
        this.buyingListId, 
        {
          expectedDeliveryDate: this.deliveryDate,
          notes: this.notes,
          locationId: this.locationId
        }
      );
      
      this.$emit('success', `Purchase order ${newPurchaseOrder.poNumber} created successfully`);
      this.$emit('converted', newPurchaseOrder);
      this.close();
      
      // Navigate to the new purchase order
      this.$router.push(`/purchase-orders/${newPurchaseOrder.id}`);
      
    } catch (error: any) {
      console.error('Error converting buying list to PO:', error);
      this.errorMessage = error.message || 'Failed to convert buying list to purchase order';
    } finally {
      this.loading = false;
    }
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ReportModel } from '@/models/report';
import { FilterColumnList } from '@/collections/filter_columns';
import { ProductSegmentModel } from '@/models/product_segment';
import { EventBus } from '@/main';
import FilterColumn from './FilterColumn.vue';
import { format, subMonths } from 'date-fns';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import moment from 'moment';

interface TableHeader {
  text: string;
  value: string;
  sortable?: boolean;
  filterable?: boolean;
}

@Component({
  components: {
    FilterColumn
  }
})
export default class ProductSelector extends Vue {
  @Prop({ required: true })
  private product_segment_id!: number;
  @Prop({ required: true })
  private measure!: MeasureModel;
  private report: ReportModel = new ReportModel();
  private segment: ProductSegmentModel = new ProductSegmentModel();
  private filters: FilterColumnList = new FilterColumnList();
  @Prop({ default: () => [] })
  private reportColumns!: any[];
  private products: any[] = [];
  private selectedProducts: any[] = [];
  private loading: boolean = false;
  private adding: boolean = false;
  private totalProducts: number = 0;
  private dialogProductSelector: boolean = false;
  private tableOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
  };

  private get headers(): TableHeader[] {
    return this.reportColumns.map((column: any) => ({
      text: column.name,
      value: column.code,
      sortable: true,
      filterable: true,
    }));
  }

  async created() {
    EventBus.$on('show-product-selector', () => {
      this.dialogProductSelector = true;
    });
    this.initializeReport();
    await this.loadData();
    
  }

  private initializeReport() {
    // Set up report parameters
    this.report.measure = this.measure;
    this.report.dimension = new DimensionModel({ code: 'by_product' });
    this.report.isDetail = true;
    this.report.isMetric = false;
    const reportColumnsWithId = [
        { name: 'Product Id', code: 'product_id' },
        // { name: 'Product Description', code: 'Item[Item Description]' },
        // { name: 'Vendor', code: 'Vendor Name' },
        // { name: 'Product Type', code: 'product_type' },
        // { name: 'Product Status', code: 'product_status' },
        // { name: 'Reorder Quantity', code: '[inventory_replenishment]' },
        // { name: 'Daily Units Sold', code: '[average_daily_units_sold_param]' },
        // { name: 'Leadtime', code: '[vendor_lead_time]' },
        // { name: 'Inventory Quantity', code: '[Actual Inventory Quantity]' },
        // { name: 'Unit Cost', code: '[average_unit_cost]' },
        // { name: 'Selling Price', code: '[Selling Price]' },
        // { name: 'Net Sales', code: '[net_sales]' },
        // { name: 'Sales Contribution', code: '[sales_contribution]' },
    ];
    this.report.reportColumns = reportColumnsWithId;
    // Calculate last 3 months date range
    const now = new Date();
    const threeMonthsAgo = subMonths(now, 3);
    
    const from = moment(threeMonthsAgo).format('YYYY-MM-DD');
    const to = moment(now).format('YYYY-MM-DD');
    this.report.filterTimerange = [from, to];

    // Set pagination
    this.report.page = this.tableOptions.page;
    this.report.perPage = this.tableOptions.itemsPerPage;
  }

  private async loadData() {
    try {
      this.loading = true;
      // Get report data
      await this.report.getReport(false);
      
      // Transform report data to rows
      this.products = [];
      for (const index in this.report.rows) {
        if (this.report.rows[index]) {
          const row: any = {};
          // Ensure product_id is set first and is a number
          const productIdColumn = this.report.columns.find(c => c.code === 'product_id');
          row.product_id = productIdColumn?.values[index] ? Number(productIdColumn.values[index]) : null;
          
          // Skip row if no valid product_id
          if (row.product_id === null) continue;
          
          for (const column of this.report.columns) {
            // Only include columns that are in our headers
            const header = this.headers.find(h => h.value === column.code);
            if (!header) continue;

            if (column.values[index]) {
              row[column.code] = column.values[index];
            } else {
              if (
                column.dataType === 'number' ||
                column.dataType === 'decimal' ||
                column.dataType === 'currency' ||
                column.dataType === 'percent'
              ) {
                row[column.code] = '0';
              } else {
                row[column.code] = '-';
              }
            }
            if (column.originalValues[index] === null && column.sortable === false) {
              row[column.code] = '';
            }
          }
          this.products.push(row);
        }
      }

      // Get total count
      await this.report.getCount();
      this.totalProducts = Number(this.report.total);

    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Error loading products',
        color: 'error',
      });
    } finally {
      this.loading = false;
    }
  }

  private async refreshData() {
    // Reset selection when refreshing
    this.selectedProducts = [];
    await this.loadData();
  }

  private async handleAddProducts() {
    await this.segment.addProducts(this.selectedProducts.map((p: any) => 
      p.id
    ));
    this.hideDialog();
    this.$emit('products-added', this.selectedProducts);
  }

  @Watch('tableOptions', { deep: true })
  private async onTableOptionsChange() {
    this.report.page = this.tableOptions.page;
    this.report.perPage = this.tableOptions.itemsPerPage;
    this.report.sortBy = this.tableOptions.sortBy;
    this.report.sortDesc = this.tableOptions.sortDesc;
    await this.loadData();
  }

  private async handleFilterChange(newFilters: FilterColumnList) {
    this.filters = newFilters;
    this.report.filterColumns = newFilters;
    await this.loadData();
  }

  private async addSelectedProducts() {
    if (this.selectedProducts.length === 0) return;

    try {
      this.adding = true;
      this.segment.id = this.product_segment_id;

      // Extract product IDs from selected products
      const productIds = this.selectedProducts
        .map(p => p.product_id)
        .filter(id => id != null && id !== undefined);

      if (productIds.length === 0) {
        throw new Error('No valid product IDs found in selection');
      }

      await this.segment.addProducts(productIds);
      
      EventBus.$emit('show-snackbar', {
        message: `Successfully added ${productIds.length} products to segment`,
        color: 'success',
      });

      this.$emit('products-added', this.selectedProducts);
      this.hideDialog();
      
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Error adding products to segment',
        color: 'error',
      });
    } finally {
      this.adding = false;
    }
  }

  @Watch('selectedProducts')
  private onSelectionChange(products: any[]) {
    this.$emit('selection-change', products);
  }

  // Add these methods to control dialog visibility
  public showDialog() {
    this.dialogProductSelector = true;
  }

  public hideDialog() {
    this.dialogProductSelector = false;
    this.selectedProducts = [];
  }
  @Watch('show')
  private onShowChange(newVal: boolean) {
    this.dialogProductSelector = newVal;
  }
}

import { get, post } from '@/services/http';
import { PurchaseOrderModel, IPurchaseOrder } from '@/models/purchase_order';

export interface PurchaseOrdersFilter {
  page?: number;
  limit?: number;
  search?: string;
  status?: string;
  supplierId?: number;
  locationId?: number;
  fromDate?: string;
  toDate?: string;
  sortBy?: string;
  sortDir?: string;
}

interface CreateFromBuyingListOptions {
  expectedDeliveryDate?: string;
  notes?: string;
  locationId?: number;
}

export class PurchaseOrders {
  items: PurchaseOrderModel[] = [];
  total: number = 0;

  async fetch(filters: PurchaseOrdersFilter = {}): Promise<void> {
    const response: any = await get('/purchase_orders', filters);
    this.items = response.data.purchaseOrders.map(
      (po: IPurchaseOrder) => new PurchaseOrderModel(po)
    );
    this.total = response.data.pagination.total;
  }

  async fetchAll(): Promise<void> {
    const response: any = await get('/purchase_orders', { limit: 1000 });
    this.items = response.data.purchaseOrders.map(
      (po: IPurchaseOrder) => new PurchaseOrderModel(po)
    );
    this.total = response.data.pagination.total;
  }

  async fetchBySupplier(supplierId: number): Promise<void> {
    const response: any = await get('/purchase_orders', {
      supplierId
    });
    this.items = response.data.purchaseOrders.map(
      (po: IPurchaseOrder) => new PurchaseOrderModel(po)
    );
    this.total = response.data.pagination.total;
  }

  async fetchByStatus(status: string): Promise<void> {
    const response: any = await get('/purchase_orders', {
      status
    });
    this.items = response.data.purchaseOrders.map(
      (po: IPurchaseOrder) => new PurchaseOrderModel(po)
    );
    this.total = response.data.pagination.total;
  }

  async fetchPendingReceiving(): Promise<void> {
    const response: any = await get('/purchase_orders', {
      status: ['confirmed', 'partial_received'].join(',')
    });
    this.items = response.data.purchaseOrders.map(
      (po: IPurchaseOrder) => new PurchaseOrderModel(po)
    );
    this.total = response.data.pagination.total;
  }

  async createNewPO(data: Partial<IPurchaseOrder>): Promise<PurchaseOrderModel> {
    const newPO = new PurchaseOrderModel(data);
    await newPO.save();
    this.items.push(newPO);
    return newPO;
  }

  async createFromBuyingList(
    buyingListId: number,
    options: CreateFromBuyingListOptions = {}
  ): Promise<PurchaseOrderModel> {
    const newPO = await PurchaseOrderModel.createFromBuyingList(buyingListId, options);
    this.items.push(newPO);
    return newPO;
  }

  async getOne(id: number | string): Promise<PurchaseOrderModel> {
    const response: any = await get(`/purchase_orders/${id}`);
    const purchaseOrder = new PurchaseOrderModel(response.data);
    // Fetch PO items
    await purchaseOrder.fetchItems();
    return purchaseOrder;
  }

  async receiveItems(
    poId: number,
    itemUpdates: { id: number; receivedQuantity: number }[]
  ): Promise<PurchaseOrderModel> {
    const po = await this.getOne(poId);
    await po.receiveItems(itemUpdates);
    
    // Update the item in the collection if it exists
    const index = this.items.findIndex(item => item.id === poId);
    if (index !== -1) {
      this.items[index] = po;
    }
    
    return po;
  }

  getStatusOptions(): { text: string; value: string }[] {
    return [
      { text: 'Draft', value: 'draft' },
      { text: 'Sent to Supplier', value: 'sent' },
      { text: 'Confirmed', value: 'confirmed' },
      { text: 'Partially Received', value: 'partial_received' },
      { text: 'Received', value: 'received' },
      { text: 'Canceled', value: 'canceled' }
    ];
  }

  // Helper method to generate dashboard statistics
  async getDashboardStats(): Promise<{
    total: number;
    pending: number;
    confirmed: number;
    received: number;
    value: {
      total: number;
      pending: number;
    }
  }> {
    const response: any = await get('/purchase_orders/stats');
    return response.data;
  }
} 
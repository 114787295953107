
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import ForecastSetting from '@/models/forecast_setting';
import { EventBus } from "@/main";

@Component({
  components: {
    ActionButton,
  }
})
export default class InventoryForecastSettings extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  @Prop({ default: () => [] })
  private timeRangeLimit!: string[];

  @Prop({ default: false })
  private isAthenaFinished!: boolean;

  private saving: boolean = false;
  private loading: boolean = false;
  private weightDialog: boolean = false;
  private editedWeight: number = 0;
  private editedWeightIndex: number = -1;
  private forecastSetting: ForecastSetting = new ForecastSetting();
  private recalculateLoading: boolean = false;
  private saveDialog: boolean = false;
  private initialMethodology: string | null = null;

  private defaultSettings = {
    options: {
      methodology: 'simple_average',
      recalculation_frequency: 'monthly',
      timeframe: {
        forecast_by: 'month',
        number_of_periods: 6
      },
      weighted_moving_average: {
        weights: [6, 5, 4, 3, 2, 1]
      },
      yearly_trend: {
        enabled: true,
        comparison_base: 'last_year'
      },
      seasonal_adjustment: {
        enabled: true,
        comparison_base: 'last_year'
      },
      combined_method: {
        weights: {
          weighted_moving_average: 0.5,
          yearly_trend: 0.25,
          seasonal_adjustment: 0.25
        }
      },
      service_level: 'high'
    }
  };

  private settings = { ...this.defaultSettings };

  private forecastByOptions = [
    { text: 'Month', value: 'month' },
    // { text: 'Week', value: 'week' },
    // { text: 'Day', value: 'day' }
  ];

  private comparisonBaseOptions = [
    { text: 'Last Year', value: 'last_year' },
  ];

  private allowedSarimaMerchants = [9618];
  private allowedAIMerchants = [8757, 11508, 9618];

  private serviceLevelOptions = [
    { text: 'High - 90% chance sales are within the forecast', value: 'high' },
    { text: 'Medium - 50% chance sales are within the forecast', value: 'medium' },
    { text: 'Low - 10% chance sales are within the forecast', value: 'low' }
  ];

  get methodologyOptions() {
    const baseOptions = [
      { 
        text: 'Simple average moving',
        value: 'simple_average' 
      },
      { 
        text: 'Computing model', 
        value: 'combined_weighted' 
      }
    ];

    if (this.allowedSarimaMerchants.includes(this.store.id)) {
      baseOptions.push({
        text: 'Smart model (using SARIMA)',
        value: 'sarima'
      });
    }

    if (this.allowedAIMerchants.includes(this.store.id)) {
      baseOptions.push({
        text: 'Smart model (using AI)',
        value: 'ai'
      });
    }

    return baseOptions;
  }

  private recalculationOptions = [
    { text: 'Daily', value: 'daily' },
    { text: 'Weekly', value: 'weekly' },
    { text: 'Monthly', value: 'monthly' }
  ];

  get methodology() {
    return this.settings.options.methodology;
  }

  get showRecalculationFrequency() {
    return ['combined_weighted', 'ai'].includes(this.settings.options.methodology);
  }

  get methodologyDescription(): string {
    const descriptions: { [key: string]: string } = {
      simple_average: 'Uses a straightforward average of historical data to predict future values. Best for stable, consistent data patterns.',
      combined_weighted: 'Advanced model that combines moving averages with yearly trends. Ideal for data with seasonal patterns and long-term trends.',
      sarima: 'Statistical model that captures complex seasonal patterns and trends. Best for detailed, long-term forecasting.',
      ai: 'Advanced AI-powered model that learns from historical patterns to provide highly accurate forecasts. Ideal for complex, dynamic data patterns.'
    };
    return descriptions[this.settings.options.methodology] || '';
  }

  get serviceLevelDescription(): string {
    const descriptions: { [key: string]: string } = {
      high: 'Help to maintain higher safety stock levels to minimize stockouts. Best for essential products or when customer satisfaction is the priority.',
      medium: 'Provides a balanced approach to inventory management.',
      low: 'Help to minimize inventory levels to optimize cash flow and reduce holding costs. Suitable for non-essential or slow-moving items.'
    };
    return this.settings.options?.service_level 
      ? descriptions[this.settings.options.service_level] 
      : '';
  }

  @Watch('settings.options.timeframe.number_of_periods')
  private onNumberOfPeriodsChange(newValue: number) {
    // Ensure weighted_moving_average and weights exist
    if (!this.settings.options.weighted_moving_average) {
      this.settings.options.weighted_moving_average = { weights: [] };
    }

    // Generate new weights array based on number of periods
    const currentWeights = this.settings.options.weighted_moving_average.weights || [];
    const newWeights = Array(newValue).fill(0).map((_, index) => {
      // If we have an existing weight, use it, otherwise generate new weight
      if (index < currentWeights.length) {
        return currentWeights[index];
      }
      // New weights decrease linearly from the highest period to the lowest
      return newValue - index;
    });
    
    // Update weights array
    this.settings.options.weighted_moving_average.weights = newWeights;
  }

  @Watch('settings.options.methodology')
  private onMethodologyChange(newValue: string) {
    if (newValue === 'simple_average') {
      this.settings.options.recalculation_frequency = 'daily';
    } else if (newValue === 'combined_weighted') {
      this.settings.options.recalculation_frequency = 'daily';
    } else if (newValue === 'sarima' || newValue === 'ai') {
      this.settings.options.recalculation_frequency = 'monthly';
    }
  }

  private editWeight(index: number) {
    this.editedWeightIndex = index;
    this.editedWeight = this.settings.options.weighted_moving_average.weights[index];
    this.weightDialog = true;
  }

  private saveWeight() {
    if (this.editedWeightIndex >= 0) {
      this.settings.options.weighted_moving_average.weights[this.editedWeightIndex] = this.editedWeight;
    }
    this.weightDialog = false;
  }

  private autoDistributeWeights() {
    const numPeriods = this.settings.options.timeframe.number_of_periods;
    this.settings.options.weighted_moving_average.weights = Array(numPeriods)
      .fill(0)
      .map((_, index) => numPeriods - index);
  }

  private recommendOptimalWeights() {
    let weights = {
      weighted_moving_average: 0,
      yearly_trend: 0,
      seasonal_adjustment: 0
    };

    const defaultWeights = this.defaultSettings.options.combined_method.weights;

    // Count enabled components
    let enabledCount = 1; // Always include weighted_moving_average
    if (this.settings.options.yearly_trend.enabled) enabledCount++;
    if (this.settings.options.seasonal_adjustment.enabled) enabledCount++;

    // Distribute weights evenly among enabled components
    const baseWeight = 1 / enabledCount;
    
    // Always include weighted_moving_average
    weights.weighted_moving_average = defaultWeights.weighted_moving_average;
    
    // Add weights for enabled components
    if (this.settings.options.yearly_trend.enabled) {
      weights.yearly_trend = defaultWeights.yearly_trend;
    }
    
    if (this.settings.options.seasonal_adjustment.enabled) {
      weights.seasonal_adjustment = defaultWeights.seasonal_adjustment;
    }
    // normalize weights and rounding to 2 decimal places
    const total = Object.values(weights).reduce((sum, weight) => sum + weight, 0);
    for (const key in weights) {
      weights[key as keyof typeof weights] = Math.round(weights[key as keyof typeof weights] / total * 100) / 100;
    }
    // ensure the sum of weights is 1, auto distribute to the weighted_moving_average 
    weights.weighted_moving_average = 1 - Object.entries(weights).reduce((sum, [k, weight]) => 
      k !== 'weighted_moving_average' ? sum + weight : sum, 0);
    // round weighted_moving_average
    weights.weighted_moving_average = Math.round(weights.weighted_moving_average * 100) / 100;
    // Update the settings
    this.settings.options.combined_method.weights = weights;
  }

  private async saveSettingsOnly() {
    this.saveDialog = false;
    this.saving = true;
    try {
      const forecastSetting = new ForecastSetting(this.settings.options);
      await forecastSetting.save();
      this.$emit('settings-saved', this.settings.options);
      EventBus.$emit('show-snackbar', 'The forecast settings have been updated successfully.');
      if ((!this.initialMethodology || this.initialMethodology === 'simple_average')
        && this.settings.options.methodology !== 'simple_average') {
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to save forecast settings:', error);
      EventBus.$emit('show-snackbar', 'Failed to save forecast settings');
    } finally {
      this.saving = false;
    }
  }

  private async saveAndRecalculate() {
    this.saveDialog = false;    
    this.saving = true;
    try {
      const forecastSetting = new ForecastSetting(this.settings.options);
      await forecastSetting.save();
      this.saving = false;
      this.$emit('settings-saved', this.settings.options);
      EventBus.$emit('show-snackbar', 'The forecast settings have been updated successfully.');
      await this.handleRecalculate();
      if ((!this.initialMethodology || this.initialMethodology === 'simple_average')
        && this.settings.options.methodology !== 'simple_average') {
        window.location.reload();
      }
    } catch (error) {
      console.error('Failed to save forecast settings:', error);
      EventBus.$emit('show-snackbar', 'Failed to save forecast settings');
    } finally {
      this.saving = false;
    }    
  }

  private async saveSettings() {
    // Show dialog instead of saving directly
    if (this.settings.options.methodology !== 'simple_average') {
      this.saveDialog = true;
    } else {
      await this.saveSettingsOnly();
    }
  }

  private async handleRecalculate() {
    try {
      this.recalculateLoading = true;
      this.forecastSetting.forceRecalculateSalesForecast();
      //sleep 2 seconds
      await new Promise(resolve => setTimeout(resolve, 2000));
      EventBus.$emit('show-snackbar', 'The forecast recalculation has been initiated. The data will be updated in a few minutes.');
    } catch (error) {
      console.error('Error recalculating forecast:', error);
      EventBus.$emit('show-snackbar', 'Failed to recalculate forecast');
    } finally {
      this.recalculateLoading = false;
    }
  }

  async created() {
    await this.loadForecastSetting();
  }

  private async loadForecastSetting() {
    this.loading = true;
    try {
      await this.forecastSetting.load();
      // Store initial methodology before merging settings
      this.initialMethodology = this.forecastSetting.options?.methodology || null;
      // Deep merge default settings with loaded settings   
      this.settings = {
        options: {
          ...this.defaultSettings.options,    
          ...this.forecastSetting.options,
          timeframe: {
            ...this.defaultSettings.options.timeframe,
            ...this.forecastSetting.options?.timeframe
          },
          weighted_moving_average: {
            weights: this.forecastSetting.options?.weighted_moving_average?.weights || 
                    this.defaultSettings.options.weighted_moving_average.weights
          },
          yearly_trend: {
            ...this.defaultSettings.options.yearly_trend,
            ...this.forecastSetting.options?.yearly_trend
          },
          seasonal_adjustment: {
            ...this.defaultSettings.options.seasonal_adjustment,
            ...this.forecastSetting.options?.seasonal_adjustment
          },
          combined_method: {
            weights: {
              ...this.defaultSettings.options.combined_method.weights,
              ...this.forecastSetting.options?.combined_method?.weights
            }
          },
        }
      };
    } catch (error) {
      console.error('Failed to load forecast settings:', error);
      this.settings = { ...this.defaultSettings };
      this.initialMethodology = null;
    } finally {
      this.loading = false;
    }
  }
}

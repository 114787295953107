
import { Component, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import { GoogleSpreadsheetFileList } from '@/collections/google_spreadsheet_files';
import { GoogleSpreadsheetFileModel } from '@/models/google_spreadsheet_file';
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
  },
})
export default class GoogleSpreadsheetFiles extends Vue {
  public isLoading = false;
  public files: GoogleSpreadsheetFileModel[] = [];
  public headers = [
    { text: 'Name', value: 'template' },
    { text: 'Version', value: 'version' },
    { text: 'File Url', value: 'fileUrl' },
    // { text: 'Created At', value: 'createdAt' },
    // { text: 'Updated At', value: 'updatedAt' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  public async created() {
    this.isLoading = true;
    await this.fetchFiles();
    this.isLoading = false;
  }

  public async fetchFiles() {
    try {
      const fileList = new GoogleSpreadsheetFileList();
      await fileList.fetch({});
      this.files = fileList.items;
    } catch (error) {
      EventBus.$emit("show-snackbar", "An error occurred while fetching the files.", "error");
    }
  }

  public viewFile(file: GoogleSpreadsheetFileModel) {
    this.$router.push({ name: 'GoogleSpreadsheetFileDetail', params: { id: file.id.toString() } });
  }

  public convertSnakeCaseToNormalText(text: string): string {
    return text.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  public formatDate(dateString: string): string {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleString();
  }
}

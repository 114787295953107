
export class ReplenishmentSettingRecordModel {
  public id: number;
  public value: any;
  public status: string;
  public createdAt: string;
  public updatedAt: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.value = data.value;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.value = data.value;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

}


import { Component, Vue, Prop } from 'vue-property-decorator';
import Installing from '@/components/Installing.vue';
import { UserPackageModel } from '@/models/user_package';
import { BillingPackageList } from '@/collections/billing_packages';
import { StoreModel } from '@/models/store';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History } from '@shopify/app-bridge/actions';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import { BillingPackageModel } from '@/models/billing_package';

@Component({
  components: {
    Installing,
  },
})
export default class SubscriptionConfirm extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: null })
  public app!: any;
  public billingPackages: BillingPackageList = new BillingPackageList();
  public selectedBillingPackage: BillingPackageModel = new BillingPackageModel();
  public userPackage: UserPackageModel = new UserPackageModel();

  public async created() {
    let interval = this.$route.query.interval || 'EVERY_30_DAYS';
    let isYearly: boolean = false;
    if (interval === 'ANNUAL') {
      isYearly = true;
    }
    let trialDays: number = 14;
    if (this.$route.query.trial_days) {
      trialDays = Number(this.$route.query.trial_days);
    }

    await this.billingPackages.fetch();
    await this.selectBillingPackage();

    if (this.$route.query.discount) {
      this.userPackage.discountCode = this.$route.query.discount + '';
    }
    this.userPackage.billingPackageId = this.selectedBillingPackage.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    }
    const discount = 0;

    const confirmationUrl = await this.userPackage.create(
      trialDays,
      discount,
      returnUrl,
      [],
      isYearly,
    );

    if (this.userPackage.status === 'CONFIRMING' && confirmationUrl) {
      let eventName = 'CONFIRMING_PAID_PACKAGE';
      if (discount > 0) {
        eventName = `CONFIRMING_PAID_PACKAGE_AFTER_OFFER_${discount * 100}`;
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
        this.store.sendEvent(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }
      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    }
  }

  public selectBillingPackage() {
    const assistyPlan = this.store.assistyPlan;
    for (const item of this.billingPackages.items) {
      if (assistyPlan === item.shopifyPlanName) {
        this.selectedBillingPackage = item;
      }
    }
    for (const item of this.billingPackages.items) {
      if (item.id === this.userPackage.billingPackageId) {
        if (item.price > this.selectedBillingPackage.price) {
          this.selectedBillingPackage = item;
        }
      }
    }
  }
}

import { get, put } from '@/services/http';
import { SupplierModel } from '@/models/supplier';
import { supplierData, supplierProducts, buyingListData,supplierMetricsHistory } from '@/mock/supplier-data';
import List from './list';

export class SupplierList extends List<SupplierModel> {

  public async fetch(itemsPerPage=10) {
    // fetch data from api
    const response: any = await get('/suppliers', {limit: itemsPerPage});
    this.mapData(response.data);
  }

  public mapData(data: any) {
    
    // Clear existing items
    this.items = [];
    if (data.suppliers) {
      for (const supplier of data.suppliers) {
        const supplierModel = new SupplierModel(supplier);
        this.add(supplierModel);
      }
    }
  }

  public async save() {
    try {
      // In mock mode, just log the save operation
      console.log('Saving suppliers:', this.items.map(item => item.toJSON()));
      return true;
    } catch (e) {
      return false;
    }
  }

  public countActiveSuppliers(): number {
    return this.items.filter(supplier => 
      supplier.status === 'ACTIVE'
    ).length;
  }

  public getSuppliersByMetricThreshold(metricLabel: string, threshold: number): SupplierModel[] {
    return this.items.filter(supplier => {
      const metric = supplier.summaryMetrics.find(m => m.label === metricLabel);
      if (!metric) return false;
      const value = parseFloat(String(metric.value));
      return !isNaN(value) && value >= threshold;
    });
  }
}

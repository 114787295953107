import zlib from 'zlib';
import { Buffer } from 'buffer';
import { MeasureModel } from '@/models/measure';
import { FilterColumnList } from '@/collections/filter_columns';
import { MetricList } from '@/collections/metrics';
import { ColorRuleList } from '@/collections/color_rules';
import { convertTimeRange } from '@/services/datetime_convertor';

export async function decodeReportQuery(reportQuery: string) {
  try {
    // Restore base64 padding
    const m = reportQuery.length % 4;
    let reportQueryStr = reportQuery
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .padEnd(reportQuery.length + (m === 0 ? 0 : 4 - m), '=');

    // Convert from base64 to buffer  
    const buffer = Buffer.from(reportQueryStr, 'base64');

    // Decompress using zlib
    const decompressed = zlib.inflateSync(buffer);

    // Convert buffer to string using UTF-8 encoding
    const jsonString = decompressed.toString('utf8');

    // Parse the JSON
    const decodedQuery = JSON.parse(jsonString);

    // Initialize report properties
    return await initReportProperties(decodedQuery);
  } catch (error) {
    console.error('Failed to decode report query:', error);
    throw error;
  }
}

async function initReportProperties(reportQuery: any) {
  if (!reportQuery) return;

  const reportParams: any = {};

  try {
    // Initialize measure
    reportParams.measure = new MeasureModel();
    reportParams.measure.code = reportQuery.measure;
    await reportParams.measure.fetch();

    // Initialize dimension
    if (reportQuery.dimension) {
      for (const dimension of reportParams.measure.dimensions.items) {
        if (dimension.code === reportQuery.dimension) {
          reportParams.dimension = dimension;
          break;
        }
      }
    }

    // Initialize analytics type
    if (reportQuery.analyticsType) {
      for (const analyticsType of reportParams.measure.analyticsTypes.items) {
        if (analyticsType.code === reportQuery.analyticsType) {
          reportParams.analyticsType = analyticsType;
          break;
        }
      }
    }

    // Set dates
    if (reportQuery.start_time && reportQuery.end_time) {
      const defaultRange: string = reportQuery.default_range || 'custom';
      let startTime = reportQuery.start_time;
      let endTime = reportQuery.end_time;

      if (defaultRange !== 'custom') {
        const range = convertTimeRange(defaultRange);
        startTime = range.start;
        endTime = range.end;
      }
      reportParams.dates = [
        startTime,
        endTime,
        defaultRange,
      ];
    }

    // Initialize filter columns
    reportParams.filterColumns = new FilterColumnList();
    if (reportQuery.filter && reportQuery.filter.length > 0) {
      reportParams.filterColumns.mappData(reportQuery.filter);
    }

    // Set pivot option
    reportParams.pivotOption = reportQuery.pivot_option || '';

    // Set selected report columns  
    if (reportQuery.selected_report_columns) {
      reportParams.selectedReportColumns = reportQuery.selected_report_columns;
    }

    // Initialize calculated metrics
    reportParams.calculatedMetrics = new MetricList();
    if (reportQuery.calculated_metrics) {
      reportParams.calculatedMetrics.items = reportQuery.calculated_metrics;
    }

    // Initialize color rules
    reportParams.colorRules = new ColorRuleList();
    if (reportQuery.colorRules) {
      reportParams.colorRules.mapData(reportQuery.colorRules);
    }

    return reportParams;
  } catch (error) {
    console.error('Failed to initialize report properties:', error);
    throw error;
  }
}

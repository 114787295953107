
import { Component, Vue, Watch } from 'vue-property-decorator';
import router from '@/router';
import { SupplierModel } from '@/models/supplier';

@Component({
  name: 'SupplierCreate'
})
export default class SupplierCreate extends Vue {
  private form: any = null;
  private isValid = false;
  private loading = false;
  private showError = false;
  private errorMessage = '';
  private isActive = false;

  private supplier = new SupplierModel({
    name: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    minOrderValue: 0,
    maxOrderValue: 0,
    minInventoryValue: 0,
    maxInventoryValue: 0,
    leadTime: 0,
    reorderCycle: 0,
    status: 'INACTIVE',
    summaryMetrics: []
  });

  // Validation Rules
  private nameRule = (v: string): boolean | string => 
    !!v || 'Name is required';

  private emailRules = [
    (v: string): boolean | string => !!v || 'Email is required',
    (v: string): boolean | string => /.+@.+\..+/.test(v) || 'Email must be valid'
  ];

  private phoneRule = (v: string): boolean | string => 
    !v || /^\+?[\d\s-]+$/.test(v) || 'Phone number must be valid';

  private requiredNumberRule = (v: number): boolean | string => 
    v !== null && v !== undefined || 'Field is required';

  private positiveNumberRule = (v: number): boolean | string => 
    v >= 0 || 'Value must be positive';

  private maxValueRule = (v: number): boolean | string => 
    v >= this.supplier.minOrderValue || 'Must be greater than minimum order value';

  private maxInventoryValueRule = (v: number): boolean | string => 
    v >= this.supplier.minInventoryValue || 'Must be greater than minimum inventory value';

  // Watchers
  @Watch('isActive')
  private onIsActiveChange(value: boolean): void {
    this.supplier.status = value ? 'ACTIVE' : 'INACTIVE';
  }

  // Methods
  private async handleSubmit(): Promise<void> {
    if (!this.isValid) return;

    this.loading = true;
    try {
      await this.supplier.save();
      router.push({
        name: 'supplierDetail',
        params: { id: this.supplier.id.toString() }
      });
    } catch (error: unknown) {
      this.showError = true;
      this.errorMessage = error instanceof Error ? error.message : 'Failed to create supplier';
    } finally {
      this.loading = false;
    }
  }

  private handleCancel(): void {
    router.push({ name: 'suppliers' });
  }
}

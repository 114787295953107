
import { Component } from 'vue-property-decorator';
import SettingBase from './SettingBase';

@Component
export default class SettingMultiple extends SettingBase {
  created() {
    if (!Array.isArray(this.setting.value)) {
      this.setting.value = this.setting.defaultValue || [];
    }
  }

  get dropdownItems() {
    return this.setting.dropdownValues.map((item) => ({
      text: item.label,
      value: item.value,
    }));
  }
}

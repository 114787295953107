
import { Component, Prop, Vue } from 'vue-property-decorator';
import { SupplierModel } from '@/models/supplier';
import { SupplierList } from '@/collections/suppliers';
import FeatureLockedNotify from '@/components/FeatureLockedNotify.vue';
import { StoreModel } from '@/models/store';

@Component({
  components: {
    FeatureLockedNotify
  }
})
export default class SupplierListView extends Vue {
  private suppliers = new SupplierList();
  private loading = false;
  private filters = {
    status: '',
    priority: '',
    searchQuery: ''
  };

  private metrics = [
    {
      label: 'Total Suppliers',
      value: 0,
      icon: 'mdi-domain',
      color: 'primary'
    },
    {
      label: 'Active Suppliers',
      value: 0,
      icon: 'mdi-check-circle',
      color: 'success'
    },
    {
      label: 'Average Lead Time',
      value: '10 days',
      icon: 'mdi-clock-outline',
      color: 'warning'
    }
  ];

  private headers = [
    { text: 'Name', value: 'name' },
    { text: 'Status', value: 'status', width: '120px' },
    { text: 'Contact', value: 'contactEmail', width: '150px' },
    { text: 'Products', value: 'productCount', width: '150px' },
    { text: 'Buying Lists', value: 'buyingListCount', width: '150px' },
    { text: 'Lead Time', value: 'leadTime', width: '150px' },
    { text: 'Actions', value: 'actions', sortable: false, width: '150px' }
  ];

  private statusOptions = [
    { text: 'All Status', value: '' },
    { text: 'Active', value: 'active' },
    { text: 'Inactive', value: 'inactive' }
  ];

  private priorityOptions = [
    { text: 'All Priority', value: '' },
    { text: 'High', value: 'high' },
    { text: 'Medium', value: 'medium' },
    { text: 'Low', value: 'low' }
  ];

  public itemsPerPage = 50;

  @Prop({ default: () => new StoreModel() })
  private store!: StoreModel;

  async created() {
    await this.fetchData();
  }

  private async fetchData() {
    this.loading = true;
    try {
      await this.suppliers.fetch(this.itemsPerPage);
      this.updateMetrics();
    } finally {
      this.loading = false;
    }
  }

  private updateMetrics() {
    const activeSupplierCount = this.suppliers.countActiveSuppliers();
    const avgLeadTime = this.suppliers.items.reduce(
      (sum, supplier) => sum + supplier.leadTime,
      0
    ) / (this.suppliers.items.length || 1);

    this.metrics[0].value = this.suppliers.items.length;
    this.metrics[1].value = activeSupplierCount;
    this.metrics[2].value = `${Math.round(avgLeadTime)} days`;
  }

  private formatNumber(value: number): string {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  }

  get filteredSuppliers() {
    return this.suppliers.items.filter(supplier => {
      if (this.filters.status && supplier.summaryMetrics?.some(metric => metric.label === 'Status' && String(metric.value) !== this.filters.status)) {
        return false;
      }
      if (this.filters.priority && supplier.summaryMetrics?.some(metric => metric.label === 'Priority' && String(metric.value) !== this.filters.priority)) {
        return false;
      }
      if (this.filters.searchQuery) {
        const query = this.filters.searchQuery.toLowerCase();
        return (
          supplier.name.toLowerCase().includes(query) ||
          supplier.contactEmail.toLowerCase().includes(query)
        );
      }
      return true;
    });
  }

  private getStatusColor(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'success';
      case 'INACTIVE':
        return 'grey';
      default:
        return 'error';
    }
  }

  private getMetricTrendColor(metric: any): string {
    if (!metric.trend) return 'grey';
    return metric.trend.isPositive ? 'success' : 'error';
  }

  private getMetricTrendIcon(metric: any): string {
    if (!metric.trend) return 'mdi-minus';
    return metric.trend.isPositive ? 'mdi-arrow-up' : 'mdi-arrow-down';
  }

  private viewSupplier(supplier: SupplierModel) {
    this.$router.push({
      name: 'supplierDetail',
      params: { id: supplier.id.toString() }
    });
  }

  private createBuyingList(supplier: SupplierModel) {
    this.$router.push({
      name: 'createBuyingList',
      params: { supplierId: supplier.id.toString() }
    });
  }

  private createSupplier() {
    this.$router.push({ name: 'supplierCreate' });
  }

  private get canManageSuppliers(): boolean {
    const validateValues = [
      {
        code: 'supplier_management',
        value: 'unlocked'
      }
    ];
    const validateResult = this.store.validateRule(validateValues);
    return validateResult.isValidate;
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoreModel } from '@/models/store';
import { avatarColor } from '@/services/configs';
import { setIdentityToken } from '@/services/http';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';

@Component
export default class StoresSwitcher extends Vue {
  @Prop({ required: true })
  public store!: StoreModel;
  @Prop({ required: true })
  public mini!: boolean;
  @Prop({default: null})
  public app!: any; 
  public selectedStore: string = '';
  public availableStores: any[] = [];
  public loadingStores: boolean = false;
  public switchingStore: boolean = false;
  public isMultipleStores: boolean = false;

  public get avatarColor() {
    const s = this.store.information.name[0].toUpperCase();
    const value = s.charCodeAt(0);
    return avatarColor[value % avatarColor.length];
  }

  async mounted() {
    this.selectedStore = this.store.id.toString();
    await this.fetchAvailableStores();
    if (this.availableStores.length > 1) {
      this.isMultipleStores = true;
    }
  }

  async fetchAvailableStores() {
    try {
      this.loadingStores = true;
      const stores = await this.store.getGroupStores();
      this.availableStores = stores.map((store: any) => ({
        id: store.id.toString(),
        name: store.name
      }));
    } catch (error) {
      console.error('Failed to fetch stores:', error);
    } finally {
      this.loadingStores = false;
    }
  }

  public async handleSwitchStore(storeId: string) {
    if (storeId === this.store.id.toString()) return;
    try {
      this.switchingStore = true; // Start loading
      const response = await this.store.switchStore(storeId);
      if (response.token && response.store_name) {
        setIdentityToken(response.token, response.store_name);
        if (isShopifyEmbedded()) {
          const redirectUrl = `https://admin.shopify.com/store/${response.store_name.split('.')[0]}/apps/${process.env.VUE_APP_NAME}`
          console.log('redirectUrl', redirectUrl);
          Redirect.create(this.app).dispatch(Redirect.Action.REMOTE, redirectUrl);
        } else {
          window.location.reload();
        }
      }
    } catch (error) {
      console.error('Failed to switch store:', error);
      this.selectedStore = this.store.id.toString();
    } finally {
      this.switchingStore = false; // End loading
    }
  } 

}


import { Component, Watch, Prop, Vue } from "vue-property-decorator";

import ActionButton from "@/components/ActionButton.vue";
import ReplenishmentSimulator from "@/components/ReplenishmentSimulator.vue";
import { InventoryParam } from "@/models/inventory_param";
import { SupplierModel } from "@/models/supplier";
import { SupplierList } from "@/collections/suppliers";
import { EventBus } from "@/main";

@Component({
  components: {
    ActionButton,
    ReplenishmentSimulator,
  },
})
export default class ReplenishmentParam extends Vue {
  public settingDialog: boolean = false;
  @Prop({ default: false })
  public isPrimaryButton!: boolean;
  @Prop({ default: "Inventory Parameters" })
  public buttonTitle!: string;
  @Prop({ default: "mdi-cog" })
  public buttonIcon!: string;
  @Prop({ default: "small" })
  public buttonSize!: string;
  @Prop({ default: () => new InventoryParam() })
  public initInventoryParam!: InventoryParam;
  public inventoryParam: InventoryParam = new InventoryParam();
  public suppliers: SupplierList = new SupplierList();
  @Prop({ default: false })
  public saveLoading!: boolean;
  public supplierLoading: boolean = false;
  public supplier: SupplierModel = new SupplierModel({ id: 0 });
  public supplierRule: any = [this.isSupplierValid];
  public numberRule: any = [
    (v: string) => !v || !isNaN(Number(v)) || "Value must be a number",
  ];
  public mustSelectVariantRule: any = [
    (v: any) => !v || !v.id || "Must select a variant",
  ];
  public isFormValid: boolean = false;
  public limitStockByDays: boolean = false;
  @Prop({ default: false })
  public isSupplierContext!: boolean;
  @Prop({ default: null })
  public supplierId!: number | null;
  public enableInventoryParams: boolean = true;

  public async created() {
    this.inventoryParam = new InventoryParam();
    if (this.initInventoryParam) {
      this.inventoryParam = this.initInventoryParam;
    }
    this.supplier = new SupplierModel({ id: 0 });
    this.supplier.name = "";
    try {
      this.supplierLoading = true;
      await this.suppliers.fetch();
      this.supplierLoading = false;
    } catch (e) {
      this.supplierLoading = false;
    }
    if (this.isSupplierContext && this.supplierId) {
      this.inventoryParam.supplierId = this.supplierId;
    }
  }

  public isSupplierValid(v: any) {
    if (!v) {
      return "Supplier is required";
    }
    return true;
  }

  public async saveNewSupplier() {
    if (!this.supplier.name) {
      return;
    }
    let exist = false;
    for (const item of this.suppliers.items) {
      if (item.name === this.supplier.name) {
        exist = true;
        return;
      }
    }
    if (!exist) {
      const newSupplier: SupplierModel = new SupplierModel({ id: 0 });
      newSupplier.name = this.supplier.name;
      await newSupplier.save();
      EventBus.$emit('show-snackbar', 'Supplier added successfully!');
      this.suppliers.items.push(newSupplier);
    }
  }

  public resetForm() {
    this.inventoryParam = new InventoryParam();
    if (this.isSupplierContext && this.supplierId) {
      this.inventoryParam.supplierId = this.supplierId;
    }
  }

  public handleBack() {
    this.$emit("inventory-params-back");
  }

  public async applySetting() {
    if (this.$refs.form) {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }

    if (this.isFormValid) {
      // Create a copy of inventory params
      const paramsToUpdate = new InventoryParam();
      
      // Always copy supplier-level parameters
      paramsToUpdate.supplierId = this.inventoryParam.supplierId;
      paramsToUpdate.leadtime = this.inventoryParam.leadtime;
      paramsToUpdate.orderCycle = this.inventoryParam.orderCycle;
      paramsToUpdate.fixOrderQuantity = this.inventoryParam.fixOrderQuantity;
      paramsToUpdate.minOrderQuantity = this.inventoryParam.minOrderQuantity;
      paramsToUpdate.maxOrderQuantity = this.inventoryParam.maxOrderQuantity;
      paramsToUpdate.replenishmentExclusion = this.inventoryParam.replenishmentExclusion;

      // Only copy inventory parameters if enabled in supplier context
      if (!this.isSupplierContext || this.enableInventoryParams) {
        paramsToUpdate.minInventoryQuantity = this.inventoryParam.minInventoryQuantity;
        paramsToUpdate.maxInventoryQuantity = this.inventoryParam.maxInventoryQuantity;
        paramsToUpdate.minStockCoverDays = this.inventoryParam.minStockCoverDays;
        paramsToUpdate.maxStockCoverDays = this.inventoryParam.maxStockCoverDays;
      }

      this.$emit("apply-setting", paramsToUpdate);
      this.settingDialog = false;
    }
  }
  // Watch initInventoryParam if it changed then update inventoryParam
  @Watch("initInventoryParam")
  private onInitInventoryParamChanged() {
    if (this.initInventoryParam) {
      if (this.initInventoryParam.maxStockCoverDays || this.initInventoryParam.minStockCoverDays) {
        this.limitStockByDays = true;
      } else {
        this.limitStockByDays = false;
      }
      this.inventoryParam = this.initInventoryParam;
    }
  }
}

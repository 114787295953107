import List from './list';
import { get } from '@/services/http';
import { GoogleSpreadsheetTemplateModel } from '@/models/google_spreadsheet_template';

export class GoogleSpreadsheetTemplateList extends List<GoogleSpreadsheetTemplateModel> {
  public async fetch() {
    console.log('fetching google spreadsheet templates');
    const res: any = await get(`/google_spreadsheet/template`, {});
    console.log('google spreadsheet templates:', res.data); 
    const googleSpreadsheetTemplates = res.data || [];
    this.mapData(googleSpreadsheetTemplates);
  }

  public mapData(data: any): GoogleSpreadsheetTemplateList {
    for (const s of data) {
      const googleSpreadsheetTemplate = new GoogleSpreadsheetTemplateModel();
      googleSpreadsheetTemplate.mapData(s);
      this.add(googleSpreadsheetTemplate);
    }
    return this;
  }
}

import { GoogleSpreadsheetTemplateModel } from './google_spreadsheet_template';
import { SettingList } from '@/collections/settings';
import { get, put, del, post } from '@/services/http';

export class GoogleSpreadsheetFileModel {
  public id: number;
  public name: string;
  public description: string;
  public version: string;
  public source: string;
  public template: GoogleSpreadsheetTemplateModel;
  public settings: SettingList;
  public emails: string[];
  public status: 'NEW' | 'GENERATING' | 'ACTIVE' | 'ERROR' | 'DELETED';
  public fileUrl: string;
  public options: any;
  public content: {
    sheets: Array<{
      name: string;
      type: string;
      description: string;
      query: string;
      reportParams?: any;
    }>;
  };
  public merchantId?: number;
  public createdAt: string;
  public updatedAt: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.version = data.version;
    this.source = data.source;
    this.template = new GoogleSpreadsheetTemplateModel(data.template);
    this.settings = new SettingList();
    this.settings.mapData(data.settings);
    this.emails = data.emails || [];
    this.content = data.content || { sheets: [] };
    this.status = data.status;
    this.fileUrl = data.fileUrl;
    this.options = data.options;
    this.merchantId = data.merchantId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public async fetch() {
    const res: any = await this.getData();
    this.mapData(res.data);
  }

  public async fetchStatus() {
    const res: any = await this.getData();
    this.status = res.data.status;
  }

  private async getData()  {
    return await get(`/google_spreadsheet/file/${this.id}`, {});
  }

  public mapData(data: any): GoogleSpreadsheetFileModel {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.version = data.version;
    this.source = data.source;
    this.template = new GoogleSpreadsheetTemplateModel({
      id: data.template,
    });
    this.settings = new SettingList();
    this.settings.mapData(data.settings);
    this.content = data.content;
    this.emails = data.emails;
    this.status = data.status;
    this.fileUrl = data.fileUrl;
    this.options = data.options;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    return this;
  }

  public async save(): Promise<GoogleSpreadsheetFileModel> {
    if (!this.id) {
      throw new Error('Cannot save file without ID');
    }

    const payload = {
      name: this.name,
      settings: this.settings.items,
      emails: this.emails,
      content: this.content,
    };

    const res: any = await put(`/google_spreadsheet/file/${this.id}`, payload);
    
    if (!res.status) {
      throw new Error(res.message || 'Failed to save file');
    }

    return this;
  }
  
  public async delete(): Promise<boolean> {
    if (!this.id) {
      throw new Error('Cannot delete file without ID');
    }
    
    const res: any = await del(`/google_spreadsheet/file/${this.id}`, {});
    
    if (!res.status) {
      throw new Error(res.message || 'Failed to delete file');
    }
    
    return true;
  }
  
  public async syncData(): Promise<boolean> {
    if (!this.id) {
      throw new Error('Cannot sync file without ID');
    }
    
    if (this.status === 'DELETED') {
      throw new Error('Cannot sync deleted file');
    }
    
    const res: any = await post(`/google_spreadsheet/file/${this.id}/sync`, {});
    
    if (!res.status) {
      throw new Error(res.message || 'Failed to sync file data');
    }
    
    return true;
  }
}

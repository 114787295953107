import { SettingList } from '@/collections/settings';
import { GoogleSpreadsheetFileModel } from '@/models/google_spreadsheet_file';
import { get, post } from '@/services/http';

export interface SheetConfig {
  name: string;
  type: string;
  description: string;
  schedule?: {
    type: string;
    hour: number;
  };
}

export class GoogleSpreadsheetTemplateModel {
  public id: string;
  public name: string;
  public version: string;
  public description: string;
  public source: string;
  public settings: SettingList;
  public content: {
    sheets: SheetConfig[];
  };

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.version = data.version;
    this.description = data.description;
    this.source = data.source;
    this.settings = new SettingList();
    if (data.settings && data.settings.length > 0) {
      this.settings.mapData(data.settings);
    }
    this.content = data.content || { sheets: [] };
  }

  public async fetch() {
    const res: any = await get(`/google_spreadsheet/template/${this.id}`, {});
    this.mapData(res.data);
  }

  public async clone(data: { name: string, emails: string[] }) {
    const res: any = await post('/google_spreadsheet/file', {
      templateName: this.id,
      name: data.name,
      emails: data.emails
    });
    const googleSpreadsheetFile: GoogleSpreadsheetFileModel = new GoogleSpreadsheetFileModel();
    return googleSpreadsheetFile.mapData(res.data);
  }

  public mapData(data: any): GoogleSpreadsheetTemplateModel {
    this.id = data.fileName;
    this.name = data.name;
    this.description = data.description;
    this.version = data.version;
    this.source = data.source;
    if (data.settings && data.settings.length > 0) {
      this.settings = new SettingList();
      this.settings.mapData(data.settings);
    }
    this.content = data.content;
    return this;
  }
}


import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CountdownOffer from '../components/CountdownOffer.vue';
import { StoreModel } from '@/models/store';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect } from '@shopify/app-bridge/actions';
import { BillingPackageList } from '@/collections/billing_packages';
import { BillingPackageModel } from '@/models/billing_package';
import { UserPackageModel } from '@/models/user_package';
import dateFormat from 'dateformat';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';
import StatusPageComponent from '@/components/StatusPage.vue';
import { UserPluginList } from '@/collections/user_plugins';
import { PluginList } from '@/collections/plugins';
import ActionButton from '@/components/ActionButton.vue';
import { DiscountList } from '@/collections/discounts';
import FeatureComparisonTable from '@/components/FeatureComparisonTable.vue';

// Add new interface for feedback form
interface FeedbackForm {
  mainReason: string;
  additionalFeedback: string;
  contactInfo: string;
}

// Add interface for feature description
interface FeatureDescription {
  text: string;
  index: number;
}

@Component({
  components: {
    CountdownOffer,
    StatusPageComponent,
    ActionButton,
    FeatureComparisonTable,
  },
})
export default class Subscription extends Vue {

  // Computed properties to fix v-for/v-if linter issues
  get freeBillingPackageButton(): BillingPackageModel | null {
    return this.filteredBillingPackages.items.find((item) => item.price === 0) || null;
  }

  get paidBillingPackageButtons(): BillingPackageModel[] {
    return this.filteredBillingPackages.items.filter((item) => item.price > 0);
  }

  get isCurrentPlanPremium(): boolean {
    return this.userPackage.name !== 'Free';
  }

  get isCurrentPlanYearly(): boolean {
    return this.userPackage.interval === 'ANNUAL';
  }

  // Add validation rules as a computed property
  get contactRules() {
    return [
      (v: string) => !!v || 'Please provide contact information for the gift card',
    ];
  }

  // Computed property to get all packages as an array
  get allPackages(): BillingPackageModel[] {
    return this.filteredBillingPackages.items;
  }
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public downgradeDialog: boolean = false;
  public offerDiscountDialog: boolean = false;
  public downgrade: boolean = false;
  public isFree: boolean = false;
  public hasPermission: boolean = true;
  public discountMessage: string = '';
  public headers: any = [
    {
      text: 'Attribute',
      align: 'start',
      value: 'attribute',
    },
    {
      text: 'Value',
      align: 'end',
      value: 'value',
      cellClass: 'font-weight-bold',
    },
  ];
  public pluginHeaders: any = [
    {
      text: 'Plugin Name',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Price',
      align: 'end',
      value: 'price',
      cellClass: 'font-weight-bold',
    },
    {
      text: 'Action',
      align: 'end',
      value: 'action',
      cellClass: 'font-weight-bold',
      width: '100px',
    },
  ];

  public packageDetailed: any = [];
  public discount: number = 0.9;

  public billingPackages: BillingPackageList = new BillingPackageList();
  public userPackage: UserPackageModel = new UserPackageModel();
  public filteredBillingPackages: BillingPackageList = new BillingPackageList();
  public freeBillingPackage: BillingPackageModel = new BillingPackageModel();
  public paidBillingPackage: BillingPackageModel = new BillingPackageModel();
  public submitting: boolean = false;
  public userPlugins: UserPluginList = new UserPluginList();
  public plugins: PluginList = new PluginList();
  public installedPlugins: any = [];
  public totalPrice: number = 0;
  public totalPluginPrice: number = 0;
  public isYearly: boolean = false;
  public canSubscribePaidPlan: boolean = true;
  public maxFeatureLines: number = 7;
  public viewAllFeatures: boolean = false;

  // Add new properties
  private showFeedbackForm: boolean = false;
  private feedbackForm: FeedbackForm = {
    mainReason: '',
    additionalFeedback: '',
    contactInfo: '',
  };
  
  private feedbackReasons = [
    { value: 'usability', text: 'Difficult navigation or usability issues' },
    { value: 'features', text: 'Lack of key features' },
    { value: 'support', text: 'Poor customer support' },
    { value: 'alternative', text: 'Found a better alternative' },
    { value: 'other', text: 'Other' },
  ];

  // Helper method to get filtered features for a package
  public getFilteredFeatures(packageItem: BillingPackageModel): FeatureDescription[] {
    if (!packageItem || !packageItem.description) { return []; }

    const features = packageItem.description.split('\n')
      .map((text, index) => ({ text, index }))
      .filter((item) => item.text !== '' && item.index > 0);

    if (!this.viewAllFeatures) {
      return features.filter((item) => item.index < this.maxFeatureLines);
    }

    return features;
  }

  // Helper method to check if a plan is premium (highest priced)
  public isPremiumPlan(item: BillingPackageModel): boolean {
    if (!item || item.price === 0) { return false; }

    // Find highest priced package from the paid packages
    const highestPrice = Math.max(...this.filteredBillingPackages.items
      .filter((pkg) => pkg.price > 0)
      .map((pkg) => pkg.price));

    // A package is premium if it has the highest price
    return item.price === highestPrice;
  }

  // Helper to determine if a plan is a downgrade from the current plan
  public isPlanDowngrade(item: BillingPackageModel): boolean {
    // If user is on free plan, nothing is a downgrade
    if (this.userPackage.name === 'Free') {
      return false;
    }

    // Find the current package price
    const currentPackagePrice = this.filteredBillingPackages.items.find(
      (pkg) => pkg.id === this.userPackage.billingPackageId,
    )?.price || 0;

    // A plan is a downgrade if its price is lower than the current plan's price
    return item.price < currentPackagePrice;
  }

  // Helper to determine if a plan is the current one
  public isCurrentPlan(item: BillingPackageModel): boolean {
    return item.id === this.userPackage.billingPackageId;
  }

  // Method to check if features should be hidden based on index
  public isMaxFeature(index: number): boolean {
    if (this.viewAllFeatures) {
      return false;
    }
    return index >= this.maxFeatureLines;
  }

  public async created() {
    this.loading = true;
    if (this.$route.query.discount) {
      this.userPackage.discountCode = this.$route.query.discount + '';
    }
    try {
      this.canSubscribePaidPlan = await this.store.canSubscribePaidPlan();
      await this.loadCurrentPackage();
      await this.billingPackages.fetch();
      await this.getBillingPackage();
      await this.plugins.fetch();
      await this.userPlugins.fetch();
      this.generateInstalledPluginData();
      this.generatePackageDetailed();
      this.error = false;
      this.success = true;
    } catch (e: any) {
      this.error = true;
      this.success = false;
      if (e.response && e.response.status === 403) {
        this.hasPermission = false;
      }
    }
    this.loading = false;
  }

  public async processDowngrade() {
    this.offerDiscountDialog = false;
    this.showFeedbackForm = true;
  }

  public async processFinalDowngrade(item: BillingPackageModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      
      await eventHandler.track('DOWNGRADE_COMPLETED', {
        from_package: this.userPackage.name,
        to_package: 'Free',
        feedback_reason: this.feedbackForm.mainReason,
        feedback_details: this.feedbackForm.additionalFeedback,
        contact_info: this.feedbackForm.contactInfo,
        subscription_duration: this.getSubscriptionDuration(),
      });

      this.showFeedbackForm = false;
      this.openChatAfterDowngrade();
      await this.submitPackage(item, 0);

    } catch (error) {
      // Error handling for downgrade process
      EventBus.$emit('show-snackbar', {
        message: 'Failed to process downgrade',
        color: 'error',
      });
    }
  }

  public openChatAfterDowngrade() {
    let message = `The reason for my downgrade: ${this.feedbackForm.mainReason}`;
    
    if (this.feedbackForm.additionalFeedback) {
      message += `\nAdditional feedback: ${this.feedbackForm.additionalFeedback}`;
    }

    message += `\nContact for gift card: ${this.feedbackForm.contactInfo}`;

    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
    // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }

  public calcualtePackagePrice(packagePrice: number) {
    // Apply yearly discount if yearly is selected
    if (this.isYearly) {
      // Yearly pricing: 10 months for price of 12 (20% discount)
      return ((10 * packagePrice) / 12).toFixed(1);
    }
    // Monthly pricing: regular price
    return packagePrice.toFixed(1);
  }

  public calcualteYearlyPrice(packagePrice: number) {
    let price: number = packagePrice;
    if (this.isYearly) {
      price = (10 * price) / 12;
      return price.toFixed(1);
    }
    return price;
  }

  public removePlugin(pluginId: string) {
    this.$router.push(`/order_confirm?uninstallingId=${pluginId}`);
  }

  public generateInstalledPluginData() {
    this.totalPrice = this.store.currentPackage.price;
    this.totalPluginPrice = 0;
    for (const userPlugin of this.userPlugins.items) {
      for (const plugin of this.plugins.items) {
        if (userPlugin.pluginId === plugin.id) {
          this.totalPrice = this.totalPrice + userPlugin.price;
          this.totalPluginPrice = this.totalPluginPrice + userPlugin.price;
          this.installedPlugins.push(
            {
              name: plugin.name,
              price: '$' + userPlugin.price + '/Month',
              pluginId: plugin.id,
            },
          );
        }
      }
    }
  }

  public generatePackageDetailed() {
    this.packageDetailed = [
      {
        attribute: 'Your current plan',
        value: this.userPackage.name,
      },
      {
        attribute: 'Member since',
        value: dateFormat(this.userPackage.activatedAt, 'mmm d, yyyy', true),
      },
      {
        attribute: 'Billing cycle',
        value: this.userPackage.interval === 'EVERY_30_DAYS' ? 'Monthly' : 'Yearly',
      },
      {
        attribute: 'Package Price',
        value: '$' + this.userPackage.price,
      },
      {
        attribute: 'Total Plugins Price',
        value: '$' + this.totalPluginPrice,
      },
      {
        attribute: 'Recurring charge',
        value: '$' + this.totalPrice,
      },
      {
        attribute: 'Discount',
        value: `$${Math.round(this.userPackage.discount * this.userPackage.price * 100) / 100} (${this.userPackage.discount * 100}%)`,
      },
    ];
    if (this.userPackage.discount) {
      this.packageDetailed.push(
        {
          attribute: 'Discount limit intervals (month)',
          value: this.userPackage.durationLimitInIntervals,
        },
      );
    }
  }

  public async getBillingPackage() {
    let discount: any;
    try {
      const discounts = new DiscountList();
      await discounts.fetch();
      if (discounts.size() > 0) {
        discount = discounts.items[0];
      }
    } catch (e) {
      // ignore this
    }

    // Use assistyPlan to map with shopifyPlanName
    const assistyPlan = this.store.assistyPlan;
    this.freeBillingPackage = new BillingPackageModel();

    // Create a list for paid billing packages
    const paidBillingPackageList = new BillingPackageList();

    // Find and categorize packages
    for (const item of this.billingPackages.items) {
      if (item.price === 0) {
        this.freeBillingPackage = item;
      } else if (assistyPlan === item.shopifyPlanName) {
        // If assistyPlan matches
        paidBillingPackageList.add(item);
      }
    }

    // If no packages were found with matching shopifyPlanName, include all paid packages
    if (paidBillingPackageList.items.length === 0) {
      for (const item of this.billingPackages.items) {
        if (item.price > 0) {
          paidBillingPackageList.add(item);
        }
      }
    }

    // Sort the paid billing packages by price in ascending order
    paidBillingPackageList.items.sort((a, b) => b.price - a.price);

    // Create the filtered list that will be displayed
    this.filteredBillingPackages = new BillingPackageList();

    // Apply discount if available
    if (discount) {
      // Get discount information
      const discountType = discount.type;
      const discountRate = discount.rate;
      const discountAmount = discount.discountAmount;
      const fixedPrice = discount.fixedPrice;

      // Set discount message based on type
      switch (discountType) {
        case 'PERCENTAGE_DISCOUNT':
          this.discountMessage = `included ${discountRate}% off`;
          break;
        case 'FIXED_DISCOUNT_AMOUNT':
          this.discountMessage = `included $${discountAmount} off`;
          break;
        default:
          this.discountMessage = 'This is a special discount';
      }

      // Apply discount to each paid package
      for (const item of paidBillingPackageList.items) {
        switch (discountType) {
          case 'PERCENTAGE_DISCOUNT':
            item.price = item.price * (1 - discountRate / 100);
            break;
          case 'FIXED_DISCOUNT_AMOUNT':
            item.price = item.price - discountAmount;
            break;
          default:
            item.price = fixedPrice || item.price;
        }
      }
    }

    // Add all paid packages to the filtered list
    for (const item of paidBillingPackageList.items) {
      this.filteredBillingPackages.add(item);
    }

    // Find the current paid package based on userPackage.billingPackageId
    this.paidBillingPackage = paidBillingPackageList.items.find(
      (item) => item.id === this.userPackage.billingPackageId,
    ) || new BillingPackageModel();
  }

  public async submitPackage(item: BillingPackageModel, trialDays: number = 14,
                             discount: number = 0, swithPlan: boolean = false, billingYearly: boolean | null = null) {
    let isYearly: boolean = this.isYearly;
    if (swithPlan) {
      isYearly = !isYearly;
    } else if (billingYearly !== null) {
      // Use provided billing period if specified
      isYearly = billingYearly;
    }
    this.submitting = true;
    this.userPackage.billingPackageId = item.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    }
    const confirmationUrl = await this.userPackage.create(
      trialDays,
      discount,
      returnUrl,
      [],
      isYearly,
    );
    if (this.userPackage.status === 'CONFIRMING' && confirmationUrl) {
      let eventName = 'CONFIRMING_PAID_PACKAGE';
      if (discount > 0) {
        eventName = `CONFIRMING_PAID_PACKAGE_AFTER_OFFER_${discount * 100}`;
      }
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
        this.store.sendEvent(eventName, {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }
      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    } else if (this.$route.name === 'onboardPricing') {
      this.$router.push('/subscription');
    } else {
      try {
        EventBus.$emit('current-package-changed');
        const eventHandler = new EventHandler({
          store: this.store,
        });
        eventHandler.track('START_FREE_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
        this.store.sendEvent('START_FREE_PACKAGE', {
          billing_package_id: this.userPackage.billingPackageId,
          version: this.userPackage.billingPackageVersion,
        });
      } catch (e) {
        // ignore this
      }
      this.offerDiscountDialog = false;
      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          '/subscription',
        );
      } else {
        window.location.assign('/subscription');
      }
    }
    this.submitting = false;
  }

  public async startOffer(offerAt: number) {
    this.store.startOffer(offerAt);
  }

  public toTimestamp(strDate: string = '') {
    let datum: number = 0;
    if (!strDate) {
      datum = Date.now();
    } else {
      datum = Date.parse(strDate);
    }
    return Math.floor(datum / 1000);
  }
@Watch('$route', { immediate: true })
  public onRouteChange(newRoute: any) {
    if (newRoute.query.action === 'downgrade') {
      // Remove the query parameter to prevent dialog from reopening on refresh
      this.$router.replace({ query: {} });

      // Open downgrade dialog
      this.downgradeDialog = true;
    }
  }

  public closeOfferDialog() {
    this.offerDiscountDialog = false;
  }

  // Add manual refresh method for paid packages
  public async manuallyLoadPackages() {
    this.loading = true;
    try {
      // Clear existing filtered billing packages
      this.filteredBillingPackages = new BillingPackageList();

      // Reload billing packages
      await this.billingPackages.fetch();

      // Add all packages that have a non-zero price
      for (const item of this.billingPackages.items) {
        if (item.price > 0) {
          // Create a new instance of BillingPackageModel
          const discountedItem = new BillingPackageModel();
          // Copy properties from the original item
          discountedItem.id = item.id;
          discountedItem.name = item.name;
          discountedItem.price = item.price * 0.7; // Apply 30% discount
          discountedItem.description = item.description;
          discountedItem.shopifyPlanName = item.shopifyPlanName;
          discountedItem.status = item.status;

          // Add to the filtered list
          this.filteredBillingPackages.add(discountedItem);
        }
      }

      // Show success message
      EventBus.$emit('show-snackbar', {
        message: 'Packages refreshed successfully.',
        color: 'success',
      });
    } catch (error) {
      // Log error without using console.error
      EventBus.$emit('show-snackbar', {
        message: 'Failed to load packages. Please try again.',
        color: 'error',
      });
    }
    this.loading = false;
  }

  public toggleBillingPeriod() {
    // Update the price calculations without loading packages again
    // This will just update the displayed prices based on the billing period
    this.generatePackageDetailed();
  }

  private getSubscriptionDuration(): number {
    const activatedDate = new Date(this.store.currentPackage.activatedAt || '');
    const now = new Date();
    const diffTime = Math.abs(now.getTime() - activatedDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  private async loadCurrentPackage() {
    await this.userPackage.getCurrent();
    this.store.currentPackage = this.userPackage;
    if (this.userPackage.name !== 'Free') {
      if (this.userPackage.interval === 'ANNUAL') {
        this.isYearly = true;
      } else {
        this.isYearly = false;
      }
    }
  }

  public getProfessionalPriceDisplay(): string {
    const paidPlans = this.filteredBillingPackages.items.filter(item => item.price > 0);
    
    if (paidPlans.length > 0) {
      // Find plan with lowest price
      const lowestPricePlan = paidPlans.reduce((min, plan) => 
        plan.price < min.price ? plan : min, paidPlans[0]);
      
      const price = this.calcualtePackagePrice(lowestPricePlan.price);
      return `$${price}`;
    }
    
    return 'Varies by Shopify plan';
  }

  public getEnterprisePriceDisplay(): string {
    const paidPlans = this.filteredBillingPackages.items.filter(item => item.price > 0);
    
    if (paidPlans.length > 0) {
      // Find plan with highest price
      const highestPricePlan = paidPlans.reduce((max, plan) => 
        plan.price > max.price ? plan : max, paidPlans[0]);
      
      const price = this.calcualtePackagePrice(highestPricePlan.price);
      return `$${price}`;
    }
    
    return 'Varies by Shopify plan';
  }

  public openFeatureComparison() {
    if (this.$refs.featureComparisonTable) {
      (this.$refs.featureComparisonTable as any).showFullComparisonDialog = true;
    }
  }

  public handleFeatureToggleClick(event: MouseEvent, item: BillingPackageModel) {
    // If shift key is pressed, show full comparison dialog
    if (event.shiftKey) {
      this.openFeatureComparison();
    } else {
      // Otherwise toggle view all features as usual
      this.viewAllFeatures = !this.viewAllFeatures;
    }
  }
}

import { get, post } from '@/services/http';

export default class ForecastSetting {
  public options: any = {};

  constructor(data: any = null) {
    if (data) {
      this.options = data;
    }
  }

  public mapData(data: any = {}) {
    this.options = data.options;
  }

  public async load(): Promise<void> {
    try {
      const response: any = await get('/inventory_forecasting_settings');
      this.mapData(response.data);
    } catch (error) {
      console.error('Error loading forecast settings:', error);
      throw error;
    }
  }

  public async save(): Promise<void> {
    try {
      await post('/inventory_forecasting_settings', {
        options: this.options
      });
    } catch (error) {
      console.error('Error saving forecast settings:', error);
      throw error;
    }
  }

  public async forceRecalculateSalesForecast() {
    const res: any = await post(`/inventory_forecasting_recalculate`);
    return res.data;
  }
}

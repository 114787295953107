import { get, post, put, del } from '@/services/http';

export class LegacySupplierModel {
  public id: number;
  public name: string;
  public companyName: string;
  public contactName: string;
  public contactEmail: string;
  public accountNumber: string;
  public address1: string;
  public address2: string;
  public city: string;
  public provinceCode: string;
  public countryName: string;
  public zip: string;
  public phone: string;
  public fax: string;
  public phoneTollFree: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.companyName = data.companyName;
    this.contactName = data.contactName;
    this.contactEmail = data.contactEmail;
    this.accountNumber = data.accountNumber;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.provinceCode = data.provinceCode;
    this.countryName = data.countryName;
    this.zip = data.zip;
    this.phone = data.phone;
    this.fax = data.fax;
    this.phoneTollFree = data.phoneTollFree;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.companyName = data.company_name;
    this.contactName = data.contact_name;
    this.contactEmail = data.contact_email;
    this.accountNumber = data.account_number;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.provinceCode = data.province_code;
    this.countryName = data.country_name;
    this.zip = data.zip;
    this.phone = data.phone;
    this.fax = data.fax;
    this.phoneTollFree = data.phone_toll_free;
  }

  public async fetch() {
    const res: any = await get(`/legacy/suppliers/${this.id}`);
    this.mapData(res.data);
  }

  public async save() {
    const data: any = {
      name: this.name,
      company_name: this.companyName,
      contact_name: this.contactName,
      contact_email: this.contactEmail,
      account_number: this.accountNumber,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      province_code: this.provinceCode,
      country_name: this.countryName,
      zip: this.zip,
      phone: this.phone,
      fax: this.fax,
      phone_toll_free: this.phoneTollFree,
    };

    const res: any = await post('/legacy/suppliers', data);
    this.mapData(res.data);
  }

  public async update() {
    const data: any = {
      name: this.name,
      company_name: this.companyName,
      contact_name: this.contactName,
      contact_email: this.contactEmail,
      account_number: this.accountNumber,
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      province_code: this.provinceCode,
      country_name: this.countryName,
      zip: this.zip,
      phone: this.phone,
      fax: this.fax,
      phone_toll_free: this.phoneTollFree,
    };
    const res: any = await put(`/legacy/suppliers/${this.id}`, data);
  }

  public async remove() {
    const res: any = await del(`/legacy/suppliers/${this.id}`);
  }
}

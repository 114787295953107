
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import dateFormat from 'dateformat';
import {post, get} from "@/services/http";
import { StoreModel } from "@/models/store";
import { ReportModel } from "@/models/report";
import { MeasureModel } from "@/models/measure";
import { DimensionModel } from "@/models/dimension";
import { AnalyticsTypeModel } from "@/models/analytics_type";
import { ParamModel } from "@/models/param";
import { FilterColumnList } from "@/collections/filter_columns";
import { ParamList } from "@/collections/params";
import HeaderTitle from "@/components/HeaderTitle.vue";
import DataTable from "@/components/DataTable.vue";
import { MeasureList } from "@/collections/measures";
import ActionButton from "@/components/ActionButton.vue";
import ReportFilter from "@/components/ReportFilter.vue";
import ReplenishmentParam from "@/components/ReplenishmentParam.vue";
import ReportSearch from "@/components/ReportSearch.vue";
import { InventoryParam } from "@/models/inventory_param";
import { ReplenishmentSettingHistory } from "@/models/replenishment_setting_history"; 
import { ProductSegmentModel } from "@/models/product_segment";
import { FilterColumnModel } from "@/models/filter_column";
import { EventBus } from "@/main";
import SpreadsheetSettings from '@/components/SpreadsheetSettings.vue';
import { SupplierList } from "@/collections/suppliers";
import { ReplenishmentSettingRecordList } from "@/collections/replenishment_setting_records";
import { ReplenishmentSettingRecordModel } from "@/models/replenishment_setting_record";

interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

interface FilterData {
  dimension: DimensionModel;
  analyticsType: AnalyticsTypeModel;
  dates: string[];
  filterColumns: FilterColumnList;
  reportParams: ParamList;
  productSegment: ProductSegmentModel;
}

interface SettingHistory {
  date: string;
  productSegment: ProductSegmentModel;
  filterColumns: FilterColumnList;
  inventoryParam: InventoryParam;
}

@Component({
  components: {
    SpreadsheetSettings,
    HeaderTitle,
    DataTable,
    ActionButton,
    ReportFilter,
    ReplenishmentParam,
    ReportSearch,
  },
})
export default class ReplenishmentSetting extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;
  public measure: MeasureModel = new MeasureModel();
  public dimension: DimensionModel = new DimensionModel();
  public analyticsType: AnalyticsTypeModel = new AnalyticsTypeModel();
  public readyToGetReport: boolean = false;
  public needRefreshData: boolean = false;
  public dates: string[] = [];
  public defaultRange: string = "30d";
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public hasSchemas!: boolean;
  public filterColumns: FilterColumnList = new FilterColumnList();
  public initFilterColumns: FilterColumnList = new FilterColumnList();
  public reportParams: ParamList = new ParamList();
  public initReportParams: ParamList = new ParamList();
  public productSegment: ProductSegmentModel = new ProductSegmentModel();
  public reportColumns: any = [];
  public initReportColumns: any = [];
  public initReportFilterColumns: any = [];
  public selectedReportColumns: any = [];
  public savedReportColumns: any = [];
  public availableParams: ParamList = new ParamList();
  public filterDialog: boolean = false;
  public filterData: FilterData = {
    dimension: new DimensionModel(),
    analyticsType: new AnalyticsTypeModel(),
    dates: [],
    filterColumns: new FilterColumnList(),
    reportParams: new ParamList(),
    productSegment: new ProductSegmentModel(),
  };

  public initPageOptions: Option = {};
  public pageOptions: Option = {};
  public inventoryParam: InventoryParam = new InventoryParam();
  public report: ReportModel = new ReportModel();
  public searchData: FilterColumnModel = new FilterColumnModel();
  public tab: number = 0;
  public saveLoading: boolean = false;
  public settingHistory: SettingHistory[] = [];
  public settingHistoryModel: ReplenishmentSettingHistory = new ReplenishmentSettingHistory();
  public isCreated: boolean = false;
  public showFilterDialog: boolean = false;
  public currentStep: number = 1;
  public showSuccessSnackbar: boolean = false;
  public showParameterDialog: boolean = false;
  public showReviewDialog: boolean = false;
  public useSpreadsheets: boolean = false;
  public configKey: string = 'replenishment_setting_source';
  public selectedSource: string = 'in_app';
  public suppliers: SupplierList = new SupplierList();
  public replenishmentSettingRecords: ReplenishmentSettingRecordList = new ReplenishmentSettingRecordList();
  public isHistoryLoading: boolean = false;
  
  public async created() {
    try {
      const existingConfig = await this.getConfig();
      if (existingConfig) {
        this.selectedSource = existingConfig;
        if (this.selectedSource === 'google_spreadsheets') {
          this.useSpreadsheets = true;
        } else {
          this.useSpreadsheets = false;
        }
      }
    } catch (e) {
      console.log(e);
    }
    EventBus.$on("reload-report-data", async () => {
      await this.retryLoadingData();
    });
    this.getReplenishmentSettingRecords();
    await this.fullLoadData();
    await this.suppliers.fetch();
  }

  public async getConfig() {
    const res: any = await get('/users/config', {key: this.configKey});
    if (res.data && res.data.length > 0) {
      return res.data[0].value;
    }
    return null;
  }

  public async updateConfig() {
    const payload = {
      key: this.configKey,
      value: this.selectedSource || 'in_app'
    };
    await post('/users/config', payload);
  }

  public getSupplierName(supplierId: number) {
    for (const item of this.suppliers.items) {
      if (item.id === supplierId) {
        return item.name;
      }
    }
    return '';
  }

  public async getReplenishmentSettingRecords() {
    this.isHistoryLoading = true;
    try {
      await this.replenishmentSettingRecords.fetch();
    } finally {
      this.isHistoryLoading = false;
    }
  }

  public generateSettingHistoryTitle(setting: SettingHistory) {
    if (!setting) {
      return '';
    }
    let title = "";
    if (setting.productSegment && setting.productSegment.id) {
      title += `Product Segment: ${setting.productSegment.name}`;
    }
    if (setting.filterColumns && setting.filterColumns.items.length > 0) {
      if (title) {
        title += " | ";
      }
      title += "Filters: ";
      let first: boolean = true;
      for (const column of setting.filterColumns.items) {
        if (!first) {
          title += " | ";
        }
        title += `${column.name} ${column.operator} {`;

        for (const v of column.value) {
          title += `${v},`;
        }

        // remove the last comma
        title = title.slice(0, -1) + '}';
        first = false;
      }
    }
    if (title) {
      title = '(' + title + ')';
    } else {
      title = '(No filters)';
    }
    return title;
  }

  public loadSettingHistory(setting: SettingHistory) {
    this.initFilterColumns = setting.filterColumns;
    this.filterColumns = setting.filterColumns;
    this.productSegment = setting.productSegment;
    const newInventoryParam = new InventoryParam();
    newInventoryParam.mappData(setting.inventoryParam);
    this.inventoryParam = newInventoryParam;
  }
  
  public async deleleSettingHistory(settingRecordId: number) {
    await this.replenishmentSettingRecords.delete(settingRecordId);
  }

  public async retryLoadingData() {
    this.needRefreshData = true;
  }

  public refreshTableDataCompleted() {
    this.needRefreshData = false;
  }

  public assignInitReportFilterColumns(reportFilterColumns: any) {
    this.initReportFilterColumns = reportFilterColumns;
  }

  public get filterReportColumns() {
    let reportColumns: any = [];
    if (this.measure.filterDimensions.size() > 0) {
      for (const dim of this.measure.filterDimensions.toArray()) {
        reportColumns.push({
          name: dim.label ? dim.label : dim.name,
          code: dim.fieldCode,
          dataType: dim.dataType,
          luisMapping: dim.code,
        });
      }
    }
    for (const column of this.initReportFilterColumns) {
      reportColumns.push(column);
    }
    reportColumns = this.refineFilterColumns(reportColumns);
    return reportColumns;
  }

  public refineFilterColumns(reportColumns: any) {
    const duplicateColumns: any = {
      "Item[Item Category]": "product_collections",
      product_tag: "product_tags",
      order_tag: "order_tags",
      customer_tag: "customer_tags",
    };

    for (const column of reportColumns) {
      if (Object.keys(duplicateColumns).includes(column.code)) {
        let index: number = 0;
        for (const column2 of reportColumns) {
          if (column2.code === duplicateColumns[column.code]) {
            reportColumns.splice(index, 1);
            break;
          }
          index++;
        }
      }
    }
    return reportColumns;
  }

  public async fullLoadData() {
    this.readyToGetReport = false;
    const mesaureCode = "replenishment_settings";
    this.measure.code = mesaureCode;
    if (this.measures.items.length === 0) {
        await this.measure.fetch();
    } else {
      for (const item of this.measures.items) {
        if (item.code === mesaureCode) {
          this.measure = item;
          break;
        }
      }
    }
    
    for (const item of this.measure.dimensions.items) {
      if (item.code === "by_variant") {
        this.dimension = item;
        break;
      }
    }
    this.analyticsType = this.measure.analyticsTypes.items[0];
    this.initAvailableParams();

    this.needRefreshData = true;
    this.readyToGetReport = true;
    this.isCreated = true;
  }

  public async initAvailableParams() {
    this.availableParams = new ParamList();
    if (this.measure.params && this.measure.params.size() > 0) {
      for (const item of this.measure.params.items) {
        this.availableParams.add(item);
      }
    }
    for (const dimension of this.measure.dimensions.items) {
      if (dimension.params && dimension.params.size() > 0) {
        for (const item of dimension.params.items) {
          if (this.canAddAvailableParam(item)) {
            this.availableParams.add(item);
          }
        }
      }
    }
  }

  public canAddAvailableParam(param: ParamModel) {
    for (const item of this.availableParams.items) {
      if (item.code === param.code) {
        return false;
      }
    }
    return true;
  }

  public updateReportOption(pageOptions: any) {
    this.pageOptions = pageOptions;
  }

  public updateReportColumn(reportColumns: any) {
    this.initReportColumns = reportColumns;
    this.reportColumns = reportColumns;
  }

  public handleInventoryParamsBack() {
    this.showParameterDialog = false;
    EventBus.$emit("show-product-filter-dialog");
  }

  public async handleParameterSubmit() {
    this.showParameterDialog = false;
    this.showReviewDialog = true;
    this.currentStep = 3;
  }

  public dateToStr(date: string) {
    return dateFormat(date, 'yyyy-mm-dd, HH:MM');
  }

  public async saveSetting() {
    try {
      this.saveLoading = true;
      this.report = new ReportModel();
      this.report.measure = this.measure;
      this.report.dimension = this.dimension;
      this.report.analyticsType = this.analyticsType;
      this.report.filterTimerange = this.dates;
      this.report.isDetail = true;
      this.report.filterColumns = this.filterColumns;
      this.report.reportParams = this.reportParams;
      this.report.reportColumns = this.reportColumns;
      this.report.typeReport = "TABLE";
      this.report.productSegment = this.productSegment;
      this.report.page = 1;
      this.report.perPage = 50;
      const reportRequestParams = this.report.generateParams();

      const cloneInventoryParam = JSON.parse(JSON.stringify(this.inventoryParam));
      const cloneFilterColumns = JSON.parse(JSON.stringify(this.filterColumns));
      const currentDatetime = dateFormat(new Date().toISOString(), 'yyyy-mm-dd, HH:MM');
      const setting: SettingHistory = {
        date: currentDatetime,
        productSegment: this.productSegment,
        filterColumns: cloneFilterColumns,
        inventoryParam: cloneInventoryParam,
      };

      await this.inventoryParam.save(reportRequestParams, setting);

      this.tab = 0;
      EventBus.$emit("show-snackbar", "Inventory replenishment parameters saved successfully!");
      EventBus.$emit("clear-cache");
      this.needRefreshData = true;
      this.saveLoading = false;
      
      this.showReviewDialog = false;
      this.showSuccessSnackbar = true;
      this.currentStep = 1;
      
      this.getReplenishmentSettingRecords();
      
    } catch (error) {
      this.saveLoading = false;
    }
  }

  @Watch("filterData", { deep: true })
  private onFilterDataChange() {
    this.readyToGetReport = false;
    this.filterColumns = this.filterData.filterColumns;
    this.initFilterColumns = this.filterColumns;
    this.reportParams = this.filterData.reportParams;
    this.productSegment = this.filterData.productSegment;
    this.showParameterDialog = true;
    this.readyToGetReport = true;
  }

  @Watch("searchData", { deep: true })
  private onSearchDataChange() {
    this.readyToGetReport = false;
    if (this.filterData && this.filterData.filterColumns) {
      this.filterColumns = new FilterColumnList();
      for (const item of this.filterData.filterColumns.items) {
        this.filterColumns.add(item);
      }
      this.filterColumns.add(this.searchData);
    } else {
      this.filterColumns = new FilterColumnList();
      this.filterColumns.add(this.searchData);
    }
    this.readyToGetReport = true;
  }

  public removeFilter(filter: FilterColumnModel) {
    const index = this.filterColumns.items.findIndex(f => f.code === filter.code);
    if (index > -1) {
      this.filterColumns.items.splice(index, 1);
    }
  }

  public get getSelectedProductsCount(): string {
    // Add logic to get the count of filtered products
    return "X"; // Replace with actual count
  }

  public clearFilters() {
    this.filterColumns.items = [];
    this.needRefreshData = true;
  }

  @Watch('filterColumns', { deep: true })
  onFilterChange() {
    if (this.filterColumns.items.length > 0) {
      this.currentStep = 2;
    } else {
      this.currentStep = 1;
    }
  }

  @Watch('saveLoading')
  onSaveLoadingChange(newVal: boolean) {
    if (newVal) {
      this.currentStep = 3;
    }
  }

  @Watch('useSpreadsheets', { immediate: true, deep: true })
  private async onUseSpreadsheetsChange(newVal: any, oldVal: any) {
    if (newVal === true) {
      this.selectedSource = 'google_spreadsheets';
    } else {
      this.selectedSource = 'in_app';
    }
    this.updateConfig()
  }

  public handleStepClick(step: number) {
    switch(step) {
      case 1:
        // Show filter dialog
        EventBus.$emit("show-product-filter-dialog");
        break;
      case 2:
        // Show parameter dialog
        this.showParameterDialog = true;
        break;
      case 3:
        // Show review dialog or error message
        if (this.isFormValid) {
          this.showReviewDialog = true;
        } else {
          EventBus.$emit("show-snackbar", "Please configure parameters in Step 2 first");
        }
        break;
    }
  }

  public saveAndCloseDialog() {
    this.saveSetting();
    this.showParameterDialog = false;
  }

  public get isFormValid(): boolean {
    if (!this.inventoryParam) {
      return false;
    }

    return Boolean(
      this.inventoryParam?.supplierId !== undefined ||
      this.inventoryParam?.replenishmentExclusion !== undefined ||
      this.inventoryParam?.minInventoryQuantity !== undefined ||
      this.inventoryParam?.maxInventoryQuantity !== undefined ||
      this.inventoryParam?.minStockCoverDays !== undefined ||
      this.inventoryParam?.maxStockCoverDays !== undefined ||
      this.inventoryParam?.leadtime !== undefined ||
      this.inventoryParam?.orderCycle !== undefined
    );
  }

  public getFilterIcon(code: string): string {
    const icons: { [key: string]: string } = {
      product_collections: 'mdi-shape',
      product_tags: 'mdi-tag',
      order_tags: 'mdi-shopping',
      customer_tags: 'mdi-account',
      default: 'mdi-filter'
    };
    return icons[code] || icons.default;
  }

  public getStatusColor(status: string): string {
    const colors: { [key: string]: string } = {
      PENDING: 'grey',
      PROCESSING: 'orange',
      COMPLETED: 'green',
      FAILED: 'red',
      default: 'grey',
    };  
    return colors[status] || colors.default;
  }
}

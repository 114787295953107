import { get, post, put, del } from '@/services/http';

export interface IBuyingListItem {
  id?: number;
  buyingListId?: number;
  variantId: number;
  productName: string;
  sku: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  _action?: 'add' | 'update' | 'remove';
}

export interface IBuyingList {
  id?: number;
  name: string;
  description?: string;
  status: 'draft' | 'finalized' | 'completed' | 'canceled';
  supplierId?: number;
  locationId?: number;
  totalValue: number;
  items: IBuyingListItem[];
  updatedAt?: string;
  createdAt?: string;
}

interface ISendEmailOptions {
  emailSubject: string;
  customMessage: string;
  attachmentFormat: 'pdf' | 'csv';
  recipients: string[];
}

export interface IProduct {
  id: number;
  name: string;
  currentStock: number;
  reorderPoint: number;
  reorderQuantity: number;
  leadTime: number;
  buyingCycle: number;
  nextReorderDate: string;
  unitPrice: number;
  category: string;
}

export interface ISupplier {
  id: number;
  name: string;
  buyingCycle: number;
  products: IProduct[];
}

export interface ISupplierGroup {
  supplierId: number;
  supplierName: string;
  supplierBuyingCycle: number;
  products: IProduct[];
}

export type IViewMode = 'day' | 'week' | 'month';

export interface DateRange {
  from: Date;
  to: Date;
}

export interface Product {
  variant_image: string;
  'Item[Item Description]': string;
  variant: string;
  variant_id: string;
  product_type: string;
  '[Actual Inventory Quantity]': number;
  '[reorder_point]': number;
  '[vendor_lead_time]': number;
  '[average_daily_units_sold_param]': number;
  '[inventory_replenishment]': number;
  unit_cost: number;
  '[reorder_date]': string;
  // Add other fields as needed
}

export interface Supplier {
  id: string;
  name: string;
  buyingCycle: number;
  products: Product[];
}

export interface Period {
  period: string;
  suppliers: Supplier[];
}

export interface BuyingCalendarResponse {
  success: boolean;
  data: {
    periods: Period[];
  };
  meta: {
    total: number;
    page: number;
    per_page: number;
  };
}

export class BuyingCalendarModel {
  async fetchBuyingCalendar(viewMode: string, dateRange: DateRange, locationName: string): Promise<any> {
    try {
      const response: any = await get(`/buying_calendar?from=${dateRange.from.toISOString().split('T')[0]}&to=${dateRange.to.toISOString().split('T')[0]}&period=${viewMode}&location_name=${locationName}`);
      return response.data.periods;
    } catch (error) {
      console.error('Error fetching buying calendar:', error);
      return [];
    }
  }
} 

import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductSegment from '@/components/ProductSegment.vue';
import ProductSelector from '@/components/ProductSelector.vue';
import { TemplateReportList } from '@/collections/template_reports';
import { MeasureList } from '@/collections/measures';
import { StoreModel } from '@/models/store';
import { ProductSegmentModel } from '@/models/product_segment';
import { EventBus } from '@/main';
import InteractiveGuides from '@/components/InteractiveGuides.vue';

@Component({
  components: {
    ProductSegment,
    ProductSelector,
    InteractiveGuides,
  },
})
export default class ProductSegmentDetail extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: 'available' })
  public isLockedReports!: string;
  @Prop({ default: false })
  public hasSchemas!: boolean;
  @Prop({ default: () => new TemplateReportList() })
  public templateReports!: TemplateReportList;
  @Prop({ default: () => new MeasureList() })
  public measures!: MeasureList;

  private segment: ProductSegmentModel = new ProductSegmentModel();
  private showProductSelector: boolean = false;
  private selectedProducts: any = [];
  private addingProducts: boolean = false;

  async created() {
    await this.loadSegment();
  }

  private async loadSegment() {
    try {
      const segmentId = Number(this.$route.params.segment_id);
      this.segment.id = segmentId;
      await this.segment.fetch();
    } catch (error) {
      console.error('Error loading segment:', error);
      EventBus.$emit('show-snackbar', {
        message: 'Error loading segment details',
        color: 'error',
      });
    }
  }

  private handleProductSelection(products: any[]) {
    this.selectedProducts = products;
  }

  private async addSelectedProducts() {
    if (this.selectedProducts.length === 0) return;

    try {
      this.addingProducts = true;
      await this.segment.addProducts(this.selectedProducts.map((p: any) => p.id));
      
      EventBus.$emit('show-snackbar', {
        message: `Successfully added ${this.selectedProducts.length} products to segment`,
        color: 'success',
      });

      this.showProductSelector = false;
      this.selectedProducts = [];
      
      // Refresh segment data
      await this.loadSegment();
    } catch (error) {
      EventBus.$emit('show-snackbar', {
        message: 'Error adding products to segment',
        color: 'error',
      });
    } finally {
      this.addingProducts = false;
    }
  }
}

import { get, post, put, del } from '@/services/http';
import { ReportModel } from '@/models/report';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';
import { InventoryParam } from '@/models/inventory_param';
import { ProductSegmentModel } from '@/models/product_segment';
import dateFormat from 'dateformat';

export interface ISupplierMetric {
  value: number;
  label: string;
  trend: 'up' | 'down' | 'stable';
}

export interface ISupplierRecommendation {
  reason: string;
  priority: 'high' | 'medium' | 'low';
  metrics: {
    lowStockProducts: number;
    outOfStockProducts: number;
    revenueAtRisk: number;
    lastOrderDate: string;
  };
}

export interface ISupplier {
  id: number;
  name: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  minOrderValue: number;
  maxOrderValue: number;
  minInventoryValue: number;
  maxInventoryValue: number;
  leadTime: number;
  reorderCycle: number;
  status: string;
  summaryMetrics: ISupplierMetric[];
  recommendation?: ISupplierRecommendation;
  productCount: number;
  buyingListCount: number;
}

export interface IMockSupplierMetric {
  value: string;
  label: string;
  trend: {
    value: number;
    isPositive: boolean;
  };
}

export interface IMockSupplierRecommendation {
  reason: string;
  priority: 'high' | 'medium' | 'low';
  metrics: {
    lowStockProducts: number;
    outOfStockProducts: number;
    revenueAtRisk: number;
    lastOrderDate: string;
  };
}

export interface IMockSupplierResponse {
  supplier: {
    id: number;
    name: string;
    contact_email: string;
    contact_phone: string;
    address: string;
    min_order_value: number;
    max_order_value: number;
    max_inventory_value: number;
    payment_terms: string;
    lead_time: number;
    safety_stock_percentage: number;
    performance_metrics: IMockSupplierMetric[];
    recommendation: IMockSupplierRecommendation;
    product_count: number;
    buying_list_count: number;
  };
}

interface SettingHistory {
  date: string;
  productSegment: ProductSegmentModel;
  filterColumns: FilterColumnList;
  inventoryParam: InventoryParam;
}

interface PageOptions {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

export class SupplierModel {
  id: number;
  name: string;
  status: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  minOrderValue: number;
  maxOrderValue: number;
  minInventoryValue: number;
  maxInventoryValue: number;
  leadTime: number;
  reorderCycle: number;
  summaryMetrics: ISupplierMetric[];
  recommendation?: ISupplierRecommendation;
  productCount: number;
  buyingListCount: number;
  constructor(data: Partial<ISupplier> | null) {
    this.id = data?.id || 0;
    this.status = data?.status || '';
    this.name = data?.name || '';
    this.contactEmail = data?.contactEmail || '';
    this.contactPhone = data?.contactPhone || '';
    this.address = data?.address || '';
    this.minOrderValue = data?.minOrderValue || 0;
    this.maxOrderValue = data?.maxOrderValue || 0;
    this.minInventoryValue = data?.minInventoryValue || 0;
    this.maxInventoryValue = data?.maxInventoryValue || 0;
    this.leadTime = data?.leadTime || 0;
    this.reorderCycle = data?.reorderCycle || 0;
    this.summaryMetrics = data?.summaryMetrics || [];
    this.recommendation = data?.recommendation;
    this.productCount = data?.productCount || 0;
    this.buyingListCount = data?.buyingListCount || 0;
  }

  public async fetch(): Promise<void> {
    try {
      const response: any = await get(`/suppliers/${this.id}`);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error fetching supplier:', error);
      throw error;
    }
  }

  public async save(): Promise<void> {
    try {
      const payload = {
        name: this.name,
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        address: this.address,
        minOrderValue: this.minOrderValue,
        maxOrderValue: this.maxOrderValue,
        minInventoryValue: this.minInventoryValue,
        maxInventoryValue: this.maxInventoryValue,
        leadTime: this.leadTime,
        reorderCycle: this.reorderCycle,
        status: this.status,
      };

      const response: any = await post('/suppliers', payload);
      if (response.data) {
        this.id = response.data.id;
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error saving supplier:', error);
      throw error;
    }
  }

  public async update(): Promise<void> {
    try {
      const payload = {
        name: this.name,
        contactEmail: this.contactEmail,
        contactPhone: this.contactPhone,
        address: this.address,
        minOrderValue: this.minOrderValue,
        maxOrderValue: this.maxOrderValue,
        minInventoryValue: this.minInventoryValue,
        maxInventoryValue: this.maxInventoryValue,
        leadTime: this.leadTime,
        reorderCycle: this.reorderCycle,
        status: this.status
      };

      const response: any = await put(`/suppliers/${this.id}`, payload);
      if (response.data) {
        this.mapData(response.data);
      }
    } catch (error) {
      console.error('Error updating supplier:', error);
      throw error;
    }
  }

  public async remove(): Promise<void> {
    try {
      await del(`/api/suppliers/${this.id}`);
    } catch (error) {
      console.error('Error removing supplier:', error);
      throw error;
    }
  }

  async getSupplierDetails(): Promise<void> {
    try {
      await this.fetch();
      // Get supplier metrics
      // const metricsResponse: any = await get(`/api/suppliers/${this.id}/metrics`);
      // if (metricsResponse.data) {
      //   this.summaryMetrics = this.transformMetrics(metricsResponse.data);
      // }
    } catch (error) {
      console.error('Error fetching supplier details:', error);
      throw error;
    }
  }

  private transformMetrics(apiMetrics: any[]): ISupplierMetric[] {
    return apiMetrics.map(metric => ({
      value: metric.value,
      label: metric.metricType,
      trend: this.calculateTrend(metric.value, metric.previousValue)
    }));
  }

  private calculateTrend(currentValue: number, previousValue: number): 'up' | 'down' | 'stable' {
    if (!previousValue) return 'stable';
    if (currentValue > previousValue) return 'up';
    if (currentValue < previousValue) return 'down';
    return 'stable';
  }

  private mapData(data: any): void {
    this.name = data.name;
    this.contactEmail = data.contactEmail;
    this.contactPhone = data.contactPhone;
    this.address = data.address;
    this.minOrderValue = data.minOrderValue;
    this.maxOrderValue = data.maxOrderValue;
    this.minInventoryValue = data.minInventoryValue;
    this.maxInventoryValue = data.maxInventoryValue;
    this.leadTime = data.leadTime;
    this.reorderCycle = data.reorderCycle;
    if (data.metrics) {
      this.summaryMetrics = this.transformMetrics(data.metrics);
    }
    this.status = data.status;
    this.productCount = data.productCount;
    this.buyingListCount = data.buyingListCount;
  }

  getMetricTrend(metric: ISupplierMetric): string {
    if (!metric.trend) return 'mdi-minus';
    return metric.trend === 'up' ? 'mdi-arrow-up' : 'mdi-arrow-down';
  }

  getMetricColor(metric: ISupplierMetric): string {
    if (!metric.trend) return 'grey';
    return metric.trend === 'up' ? 'success' : 'error';
  }

  toJSON(): ISupplier {
    return {
      id: this.id,
      name: this.name,
      contactEmail: this.contactEmail,
      contactPhone: this.contactPhone,
      address: this.address,
      minOrderValue: this.minOrderValue,
      maxOrderValue: this.maxOrderValue,
      minInventoryValue: this.minInventoryValue,
      maxInventoryValue: this.maxInventoryValue,
      leadTime: this.leadTime,
      reorderCycle: this.reorderCycle,
      status: this.status,
      summaryMetrics: this.summaryMetrics,
      recommendation: this.recommendation,
      productCount: this.productCount,
      buyingListCount: this.buyingListCount
    };
  }

  public async fetchProducts(measureCode: string = 'replenishment_settings', operator?: 'eq' | 'ne' | 'all', initialFilterColumns: FilterColumnList = new FilterColumnList()): Promise<ReportModel> {
    try {
      const report = new ReportModel();
      
      // Set up measure
      report.measure = new MeasureModel({ code: measureCode });
      
      // Set up dimension
      report.dimension = new DimensionModel({ code: 'by_variant' });
      
      // Set report type
      report.isDetail = true;
      report.isMetric = false;
      report.page = 1;
      report.perPage = 100;

      // Set report columns
      const reportColumns: any = [];
      // Basic product details
      reportColumns.push({ code: 'variant_id', dataType: 'text', name: 'Variant Id' });
      reportColumns.push({ code: 'product_id', dataType: 'text', name: 'Product Id' });
      reportColumns.push({ code: 'Variant Name', dataType: 'text', name: 'Product Variant' });
      reportColumns.push({ code: 'image', dataType: 'image', name: 'Product Image' });
      reportColumns.push({ code: 'Item[Item Description]', dataType: 'text', name: 'Product' });
      reportColumns.push({ code: 'variant', dataType: 'text', name: 'Variant' });
      reportColumns.push({ code: 'product_sku', dataType: 'text', name: 'SKU' });
      reportColumns.push({ code: 'product_barcode', dataType: 'text', name: 'Barcode' });
      reportColumns.push({ code: 'supplier', dataType: 'text', name: 'Supplier' });
      reportColumns.push({ code: 'supplier_id', dataType: 'text', name: 'Supplier Id' });
      reportColumns.push({ code: 'Vendor Name', dataType: 'text', name: 'Vendor' });
      
     
      
      // Stock parameters
      reportColumns.push({ code: '[vendor_lead_time]', dataType: 'decimal', name: 'Vendor Lead Time' });
      reportColumns.push({ code: '[min_stock_cover_days]', dataType: 'decimal', name: 'Min Stock Cover Days' });
      reportColumns.push({ code: '[max_stock_cover_days]', dataType: 'decimal', name: 'Max Stock Cover Days' });
      reportColumns.push({ code: '[min_inventory_quantity]', dataType: 'decimal', name: 'Min Inventory Quantity' });
      reportColumns.push({ code: '[max_inventory_quantity]', dataType: 'decimal', name: 'Max Inventory Quantity' });
      reportColumns.push({ code: '[min_order_quantity]', dataType: 'decimal', name: 'Min Order Quantity' });
      reportColumns.push({ code: '[max_order_quantity]', dataType: 'decimal', name: 'Max Order Quantity' });
      reportColumns.push({ code: '[fixed_order_quantity]', dataType: 'decimal', name: 'Fixed Order Quantity' });
      reportColumns.push({ code: '[reorder_cycle]', dataType: 'number', name: 'Reorder Cycle' });
      if (measureCode === 'replenishment_settings') {
        reportColumns.push({ code: '[replenishment_exclusion]', dataType: 'number', name: 'Replenishment Exclusion' });
      }
      
      // Analysis columns
      reportColumns.push({ code: '[average_daily_units_sold_param]', dataType: 'number', name: 'Units Sold Per Day' });
      reportColumns.push({ code: '[sold_quantity_param]', dataType: 'number', name: 'Units Sold' });
      reportColumns.push({ code: '[stock_days_inventory_planning]', dataType: 'number', name: 'Inventory Remaining Days' });
      reportColumns.push({ code: '[inventory_replenishment]', dataType: 'number', name: 'Reorder Quantity' });
      reportColumns.push({ code: '[reorder_date]', dataType: 'number', name: 'Reorder Date' });
      reportColumns.push({ code: '[incoming_inventory_quantity]', dataType: 'number', name: 'Incoming Inventory' });
      reportColumns.push({ code: '[buying_list_quantity]', dataType: 'number', name: 'Buying List Quantity' });


      
      report.reportColumns = reportColumns;

      const filterColumns = new FilterColumnList();
      // Add initial filter columns
      for (const filterColumn of initialFilterColumns.items) {
        filterColumns.add(filterColumn);
      }

      // Only filter by supplier if operator is provided
      if (operator !== 'all') {
        
        const supplierFilter = new FilterColumnModel();
        supplierFilter.mapData({
          name: 'SupplierId',
          code: 'supplier_id', 
          value: [this.id],
          operator: operator,
          dataType: 'number'
        });
        filterColumns.add(supplierFilter);
      }
      report.filterColumns = filterColumns;

      // Get report data
      await report.getReport(false);
      
      return report;

    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  public async fetchProductsWithOptions(measureCode: string = 'replenishment_settings', operator?: 'eq' | 'ne' | 'all', options: any = {}): Promise<ReportModel> {
    try {
      const { initialFilterColumns, pageOptions, dateRange } = options;
      const report = new ReportModel();

      // Set up measure
      report.measure = new MeasureModel({ code: measureCode });
      
      // Set up dimension
      report.dimension = new DimensionModel({ code: 'by_variant' });
      

      // Set report type
      report.isDetail = true;
      report.isMetric = false;
      if (pageOptions.page) {
        report.page = pageOptions.page;
      }
      if (pageOptions.itemsPerPage) {
        report.perPage = pageOptions.itemsPerPage;
      }
      if (pageOptions.sortBy) {
        report.sortBy = pageOptions.sortBy;
      }
      if (pageOptions.sortDesc) {
        report.sortDesc = pageOptions.sortDesc;
      }
      if (dateRange) {
        report.filterTimerange = [dateRange.start, dateRange.end];
      }

      // Set report columns
      const reportColumns: any = [];
      reportColumns.push({ code: 'variant_id', dataType: 'number', name: 'Variant ID' });
      reportColumns.push({ code: 'supplier', dataType: 'string', name: 'Supplier' });
      reportColumns.push({ code: 'supplier_id', dataType: 'number', name: 'Supplier ID' });
      reportColumns.push({ code: '[average_daily_units_sold_param]', dataType: 'number', name: 'Units Sold Per Day' });
      reportColumns.push({ code: '[sold_quantity_param]', dataType: 'number', name: 'Units Sold' });
      reportColumns.push({ code: '[stock_days_inventory_planning]', dataType: 'number', name: 'Inventory Remaining Days' });
      reportColumns.push({ code: '[inventory_replenishment]', dataType: 'number', name: 'Reorder Quantity' });
      reportColumns.push({ code: '[reorder_date]', dataType: 'number', name: 'Reorder Date' });
      reportColumns.push({ code: 'product_barcode', dataType: 'string', name: 'Barcode' });
      reportColumns.push({ code: '[incoming_inventory_quantity]', dataType: 'number', name: 'Incoming Inventory' });
      reportColumns.push({ code: '[buying_list_quantity]', dataType: 'number', name: 'Buying List Quantity' });

      report.reportColumns = reportColumns;

      const filterColumns = new FilterColumnList();
      // Add initial filter columns
      for (const filterColumn of initialFilterColumns.items) {
        filterColumns.add(filterColumn);
      }

      // Only filter by supplier if operator is provided
      if (operator !== 'all') {
        
        const supplierFilter = new FilterColumnModel();
        supplierFilter.mapData({
          name: 'SupplierId',
          code: 'supplier_id', 
          value: [this.id],
          operator: operator,
          dataType: 'number'
        });
        filterColumns.add(supplierFilter);
      }
      report.filterColumns = filterColumns;

      // Get report data
      await report.getReport(false);
      
      return report;

    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  public async countProductsWithOptions(measureCode: string = 'replenishment_settings', operator?: 'eq' | 'ne' | 'all', options: any = {}): Promise<any> {
    try {
      const { initialFilterColumns, pageOptions } = options;
      const report = new ReportModel();

      // Set up measure
      report.measure = new MeasureModel({ code: measureCode });
      
      // Set up dimension
      report.dimension = new DimensionModel({ code: 'by_variant' });
      
      // Set report type
      report.isDetail = true;
      report.isMetric = false;
      report.page = -1;
      
      // Set report columns
      const reportColumns: any = [];
      reportColumns.push({ code: 'variant_id', dataType: 'number', name: 'Variant ID' });
      reportColumns.push({ code: 'supplier', dataType: 'string', name: 'Supplier' });
      reportColumns.push({ code: 'supplier_id', dataType: 'number', name: 'Supplier ID' });
      reportColumns.push({ code: '[average_daily_units_sold_param]', dataType: 'number', name: 'Units Sold Per Day' });
      reportColumns.push({ code: '[sold_quantity_param]', dataType: 'number', name: 'Units Sold' });
      reportColumns.push({ code: '[stock_days_inventory_planning]', dataType: 'number', name: 'Inventory Remaining Days' });
      reportColumns.push({ code: '[inventory_replenishment]', dataType: 'number', name: 'Reorder Quantity' });
      reportColumns.push({ code: '[reorder_date]', dataType: 'number', name: 'Reorder Date' });
      reportColumns.push({ code: 'product_barcode', dataType: 'string', name: 'Barcode' });
      reportColumns.push({ code: '[incoming_inventory_quantity]', dataType: 'number', name: 'Incoming Inventory' });
      reportColumns.push({ code: '[buying_list_quantity]', dataType: 'number', name: 'Buying List Quantity' });

      report.reportColumns = reportColumns;

      const filterColumns = new FilterColumnList();
      // Add initial filter columns
      for (const filterColumn of initialFilterColumns.items) {
        filterColumns.add(filterColumn);
      }

      // Only filter by supplier if operator is provided
      if (operator !== 'all') {
        
        const supplierFilter = new FilterColumnModel();
        supplierFilter.mapData({
          name: 'SupplierId',
          code: 'supplier_id', 
          value: [this.id],
          operator: operator,
          dataType: 'number'
        });
        filterColumns.add(supplierFilter);
      }
      report.filterColumns = filterColumns;

      // Get report data
      await report.getCount(false);
      
      return {totalItems: report.total};

    } catch (error) {
      console.error('Error fetching supplier products:', error);
      throw error;
    }
  }

  public async addProducts(products: any[], filterData: any): Promise<void> {
    try {
      // 1. First save to replenishment_settings (existing flow)
      const report = new ReportModel();
      report.measure = new MeasureModel({code: 'replenishment_settings'});
      report.dimension = new DimensionModel({code: 'by_variant'});
      report.isDetail = true;
      
      // Create filter columns based on selection type
      const filterColumns = new FilterColumnList();
      
      if (products.length > 0) {
        // Case 1: Individual products selected
        const variantFilter = new FilterColumnModel({
          name: 'Variant Id',
          code: 'variant_id',
          operator: 'eq',
          dataType: 'number',
          value: products.map(p => p.variant_id)
        });
        filterColumns.add(variantFilter);
      } else {
        // Case 2: Using report filters
        for (const filter of filterData.filterColumns.items) {
          filterColumns.add(filter);
        }
      }
      
      report.filterColumns = filterColumns;

      // Create and save inventory param with supplier id
      const inventoryParam = new InventoryParam();
      inventoryParam.supplierId = this.id;
      
      // Save using report params
      const reportParams = report.generateParams();
      const cloneInventoryParam = JSON.parse(JSON.stringify(inventoryParam));
      const cloneFilterColumns = JSON.parse(JSON.stringify(filterColumns));
      const currentDatetime = dateFormat(new Date().toISOString(), 'yyyy-mm-dd, HH:MM');
      const setting: SettingHistory = {
        date: currentDatetime,
        productSegment: new ProductSegmentModel(),
        filterColumns: cloneFilterColumns,
        inventoryParam: cloneInventoryParam,
      };

      // 2. Create supplier-product relationships in parallel
      const variants = products.map(product => ({
        variantId: product.variant_id,
        supplierSku: product.product_sku,
        cost: product['[variant_unit_cost]'] || 0,
        replenishmentExclusion: product['[replenishment_exclusion]'] || false,
        leadTime: product['[vendor_lead_time]'] || 0,
        orderCycle: product['[reorder_cycle]'] || 0,
        fixOrderQuantity: product['[fixed_order_quantity]'] || 0,
        minimumOrderQuantity: product['[min_order_quantity]'] || 0,
        maxOrderQuantity: product['[max_order_quantity]'] || 0,
        productInfo: {
          productName: product['Item[Item Description]'] || '',
          variantTitle: product.variant || '',
          sku: product.product_sku || '',
          barcode: product.product_barcode || '',
          vendor: product['Vendor Name'] || '',
          imageUrl: product.variant_image || ''
        },
        productSetting: {
          minInventoryQuantity: product['[min_inventory_quantity]'] || 0,
          maxInventoryQuantity: product['[max_inventory_quantity]'] || 0,
          minStockCoverDays: product['[min_stock_cover_days]'] || 0,
          maxStockCoverDays: product['[max_stock_cover_days]'] || 0,
        }
      }));

      // Execute both operations in parallel
      await Promise.all([
        // Save to replenishment settings
        inventoryParam.saveSupplierProduct(reportParams, setting),
        
        // Create supplier-product relationships
        post('/supplier-products/supplier/add-variants', {
          supplierId: this.id,
          variants
        })
      ]);

      // Update product count after successfully adding products
      await post('/suppliers/update_product_count', {
        supplierId: this.id,
        change: products.length
      });

    } catch (error) {
      console.error('Error adding products:', error);
      throw error;
    }
  }

  public async removeProducts(products: any[]): Promise<void> {
    if (!products || products.length === 0) {
      return;
    }
    try {
      // Create a new report model to generate params
      const report = new ReportModel();
      report.measure = new MeasureModel({code: 'replenishment_settings'});
      report.dimension = new DimensionModel({code: 'by_variant'});
      report.isDetail = true;
      
      // Create filter columns based on selection type
      const filterColumns = new FilterColumnList();
      
      const variantFilter = new FilterColumnModel({
        name: 'Variant Id',
        code: 'variant_id',
        operator: 'eq',
        dataType: 'number',
        value: products.map(p => p.variant_id)
      });
      filterColumns.add(variantFilter);
      report.filterColumns = filterColumns;

      // Create and save inventory param with supplier id
      const inventoryParam = new InventoryParam();
      inventoryParam.supplierId = this.id;
      
      // Save using report params
      const reportParams = report.generateParams();
      const resetFields = ['supplier_id'];
      await inventoryParam.reset(resetFields, reportParams);

      // Update product count after successfully adding products
      post('/suppliers/update_product_count', {
        supplierId: this.id,
        change: products.length // Increment by number of products added
      });

    } catch (error) {
      console.error('Error adding products:', error);
      throw error;
    }
  }

  static async getById(id: number): Promise<SupplierModel> {
    // Implementation to fetch supplier by ID
    // Example:
    const response: any = await get(`/suppliers/${id}`);
    const data = response.data;
    return new SupplierModel(data);
  }
}

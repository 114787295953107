
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ReorderSuggestionExplain extends Vue {
  @Prop({ required: true }) item!: any;
  @Prop({ default: false }) value!: boolean;

  private dialog = false;
  private showHelp = false;

  public formatNumber(value: number | string): string {
    if (value === undefined || value === null || isNaN(Number(value))) {
      return '0.00';
    }
    return Number(value).toFixed(2);
  }

  public getStepColor(index: number): string {
    const colors = ['primary', 'success', 'info', 'warning', 'error', 'secondary'];
    
    // If this is the reorder date step and immediate restock is needed, use error color
    if (index === 4) { // Index 4 is the Reorder Date step
      const currentStock = this.item['[Actual Inventory Quantity]'] || 0;
      const reorderPoint = this.item['[reorder_point]'] || 0;
      if (currentStock < reorderPoint) {
        return 'error';
      }
    }
    
    return colors[index % colors.length];
  }

  public get helpItems() {
    const baseItems = [
      {
        title: 'Average Daily Sales',
        description: 'Calculated from historical sales data to predict future demand.'
      },
      {
        title: 'Safety Stock',
        description: 'Extra inventory kept to prevent stockouts due to uncertainties.'
      },
      {
        title: 'Reorder Point',
        description: 'The inventory level at which you should place a new order.'
      },
      {
        title: 'Reorder Date',
        description: 'The date when you should place your next order based on current stock levels and sales rate.'
      },
      {
        title: 'Lead Time',
        description: 'The time between placing an order and receiving the inventory.'
      }
    ];

    return baseItems;
  }

  public get productName() {
    return this.item['Item[Item Description]'] || 'Unknown Product';
  }

  public get calculationSteps() {
    const avgDailySales = Number(this.item['[average_daily_units_sold_param]']) || 0;
    const adjustedTrend = Number(this.item['adjusted_trend']) || 1;
    const leadTime = Number(this.item['[vendor_lead_time]']) || 0;
    const safetyStock = Number(this.item['[safety_stock_level]']) || 0;
    const currentStock = Number(this.item['[Actual Inventory Quantity]']) || 0;
    const reorderPoint = Number(this.item['[reorder_point]']) || 0;
    const reorderCycle = Number(this.item['[reorder_cycle]']) || 0;
    const incomingInventory = Number(this.item['[incoming_inventory_quantity]']) || 0;
    const adjustedDailySales = Number(this.item['[average_daily_units_sold_sales_trend]']) || 0;
    const minOrderQty = Number(this.item['[min_order_quantity]']) || 0;
    const maxOrderQty = Number(this.item['[max_order_quantity]']) || 0;
    const fixedOrderQty = Number(this.item['[fixed_order_quantity]']) || 0;
    const calculatedQty = Number(this.item['[inventory_replenishment]']) || 0;

    const steps = [
      {
        title: 'Average Units Sold per day',
        formula: `[Total Units Sold] / [Days in Period]`,
        result: `${this.formatNumber(avgDailySales)} units/day`
      },
      {
        title: 'Adjusted Units Sold per day',
        formula: `[Average Units Sold per day (${this.formatNumber(avgDailySales)})] x [Sales Trend (${this.formatNumber(adjustedTrend)})]`,
        result: `${this.formatNumber(adjustedDailySales)} units/day`
      },
      {
        title: 'Safety Stock Level',
        formula: 'Using Minimum Inventory Quantity',
        result: `${this.formatNumber(safetyStock)} units`
      },
      {
        title: 'Reorder Point',
        formula: `[Adjusted Units Sold (${this.formatNumber(adjustedDailySales)})] x [Lead Time (${this.formatNumber(leadTime)})] + [Safety Stock (${this.formatNumber(safetyStock)})]`,
        result: `${this.formatNumber(reorderPoint)} units`
      },
      {
        title: 'Reorder Date',
        formula: this.getReorderDateFormula(currentStock, reorderPoint, adjustedDailySales),
        result: this.formatDate(this.item['[reorder_date]'])
      },
      {
        title: this.shouldShowFinalStep(calculatedQty, minOrderQty, maxOrderQty, fixedOrderQty) ? 'Initial Reorder Quantity' : 'Reorder Quantity',
        formula: this.getInitialQuantityFormula(adjustedDailySales, reorderCycle, leadTime, safetyStock, incomingInventory),
        result: `${this.formatNumber(this.calculateInitialQuantity(adjustedDailySales, reorderCycle, leadTime, safetyStock, incomingInventory))} units`
      }
    ];

    // Only add final step if constraints changed the value
    if (this.shouldShowFinalStep(calculatedQty, minOrderQty, maxOrderQty, fixedOrderQty)) {
      steps.push({
        title: 'Final Reorder Quantity',
        formula: this.getFinalQuantityFormula(calculatedQty, minOrderQty, maxOrderQty, fixedOrderQty),
        result: `${this.formatNumber(this.item['[inventory_replenishment]'])} units`
      });
    }

    return steps;
  }

  private getInitialQuantityFormula(adjustedDailySales: number, reorderCycle: number, leadTime: number, safetyStock: number, incomingInventory: number): string {
    return `[Adjusted Units Sold (${this.formatNumber(adjustedDailySales)})] x [${reorderCycle ? 'Reorder Cycle' : 'Lead Time'} (${reorderCycle || leadTime})] + [Safety Stock (${this.formatNumber(safetyStock)})] - [Incoming Inventory (${this.formatNumber(incomingInventory)})]`;
  }

  private calculateInitialQuantity(adjustedDailySales: number, reorderCycle: number, leadTime: number, safetyStock: number, incomingInventory: number): number {
    const result = (adjustedDailySales * (reorderCycle || leadTime)) + safetyStock - incomingInventory;
    return Number(result.toFixed(2));
  }

  private getFinalQuantityFormula(calculated: number, min: number, max: number, fixed: number): string {
    const initialQty = this.formatNumber(calculated);
    const currentStock = Number(this.item['[Actual Inventory Quantity]']) || 0;
    const leadTime = Number(this.item['[vendor_lead_time]']) || 0;
    const adjustedDailySales = Number(this.item['[average_daily_units_sold_sales_trend]']) || 0;
    const maxInventory = Number(this.item['[max_inventory_quantity]']) || 0;
    const minInventory = Number(this.item['[min_inventory_quantity]']) || 0;
    
    if (fixed > 0) {
      return `Using Fixed Order Quantity: ${this.formatNumber(fixed)} units`;
    }
    
    // Calculate estimated inventory at arrival time
    const estimatedInventoryAtArrival = Math.max(0, currentStock - (adjustedDailySales * leadTime));
    let formula = `Initial Quantity: ${initialQty} units\n`;
    
    if (maxInventory > 0) {
      const maxAllowableOrder = Math.max(0, maxInventory - estimatedInventoryAtArrival);
      formula += `\nInventory constraints:\n`;
      formula += `• Current stock: ${this.formatNumber(currentStock)} units\n`;
      formula += `• Estimated sales during lead time (${leadTime} days): ${this.formatNumber(adjustedDailySales * leadTime)} units\n`;
      formula += `• Estimated inventory at arrival: ${this.formatNumber(estimatedInventoryAtArrival)} units\n`;
      formula += `• Maximum inventory level: ${this.formatNumber(maxInventory)} units\n`;
      formula += `• Maximum allowable order: ${this.formatNumber(maxAllowableOrder)} units\n`;
      
      // If max allowable order is smaller than max order quantity, it takes precedence
      if (maxAllowableOrder < max || max === 0) {
        max = maxAllowableOrder;
      }
    }
    
    if (minInventory > 0) {
      const minRequiredOrder = Math.max(0, minInventory - estimatedInventoryAtArrival);
      if (!formula.includes("Inventory constraints")) {
        formula += `\nInventory constraints:\n`;
        formula += `• Current stock: ${this.formatNumber(currentStock)} units\n`;
        formula += `• Estimated sales during lead time (${leadTime} days): ${this.formatNumber(adjustedDailySales * leadTime)} units\n`;
        formula += `• Estimated inventory at arrival: ${this.formatNumber(estimatedInventoryAtArrival)} units\n`;
      }
      formula += `• Minimum inventory level: ${this.formatNumber(minInventory)} units\n`;
      formula += `• Minimum required order: ${this.formatNumber(minRequiredOrder)} units\n`;
      
      // If min required order is larger than min order quantity, it takes precedence
      if (minRequiredOrder > min) {
        min = minRequiredOrder;
      }
    }
    
    if (min > 0 || max > 0) {
      formula += `\nOrder quantity constraints:\n`;
      
      if (min > 0) {
        formula += `• Minimum order quantity: ${this.formatNumber(min)} units\n`;
      }
      if (max > 0) {
        formula += `• Maximum order quantity: ${this.formatNumber(max)} units\n`;
      }
      
      formula += `\nFinal = CONSTRAIN(${initialQty}, ${this.formatNumber(min || 0)}, ${max ? this.formatNumber(max) : '∞'})`;
      return formula;
    }
    
    return `Using calculated quantity: ${initialQty} units`;
  }

  private shouldShowFinalStep(calculated: number, min: number, max: number, fixed: number): boolean {
    if (fixed > 0) {
      return true;
    }
    
    const currentStock = Number(this.item['[Actual Inventory Quantity]']) || 0;
    const leadTime = Number(this.item['[vendor_lead_time]']) || 0;
    const adjustedDailySales = Number(this.item['[average_daily_units_sold_sales_trend]']) || 0;
    const maxInventory = Number(this.item['[max_inventory_quantity]']) || 0;
    const minInventory = Number(this.item['[min_inventory_quantity]']) || 0;
    
    // Calculate estimated inventory at arrival time
    const estimatedInventoryAtArrival = Math.max(0, currentStock - (adjustedDailySales * leadTime));
    
    // Check min/max inventory constraints
    if (maxInventory > 0) {
      const maxAllowableOrder = Math.max(0, maxInventory - estimatedInventoryAtArrival);
      if (calculated > maxAllowableOrder) {
        return true;
      }
    }
    
    if (minInventory > 0) {
      const minRequiredOrder = Math.max(0, minInventory - estimatedInventoryAtArrival);
      if (calculated < minRequiredOrder) {
        return true;
      }
    }
    
    // Check min/max order quantity constraints (existing logic)
    if (min > 0 && calculated < min) {
      return true;
    }
    
    if (max > 0 && calculated > max) {
      return true;
    }
    
    return false;
  }

  private formatFormula(formula: string): string {
    return formula.split('\n').join('<br>');
  }

  private getReorderDateFormula(currentStock: number, reorderPoint: number, adjustedDailySales: number): string {
    // Check if immediate restock is needed
    if (Number(currentStock) < Number(reorderPoint)) {
      const stockoutRisk = adjustedDailySales > 0 ? 
        Number(currentStock / adjustedDailySales).toFixed(2) : 
        '0.00';
        
      return `⚠️ Immediate restock recommended!\n\n` +
             `[Current Stock (${this.formatNumber(currentStock)})] is below ` +
             `[Reorder Point (${this.formatNumber(reorderPoint)})]\n\n` +
             `Risk Assessment:\n` +
             `• Current stock covers ~${this.formatNumber(stockoutRisk)} days at current sales rate\n` +
             `• Lead time is ${this.item['[vendor_lead_time]'] || 0} days\n` +
             `• Risk of stockout is HIGH`;
    }

    // Normal case - calculate days until reorder needed
    const daysUntilReorder = adjustedDailySales > 0 ? 
      Number((currentStock - reorderPoint) / adjustedDailySales).toFixed(2) : 
      '0.00';
      
    return `Today + ([Current Stock (${this.formatNumber(currentStock)})] - [Reorder Point (${this.formatNumber(reorderPoint)})])` +
           ` / [Adjusted Units Sold per day (${this.formatNumber(adjustedDailySales)})]` +
           `\n= Today + ${daysUntilReorder} days`;
  }

  private formatDate(value: any): string {
    if (!value) return 'Not calculated';
    
    try {
      const date = new Date(value);
      if (isNaN(date.getTime())) return 'Invalid date';
      
      return date.toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Error formatting date';
    }
  }

  private getReorderDateHelpText(): string {
    const currentStock = this.item['[Actual Inventory Quantity]'] || 0;
    const reorderPoint = this.item['[reorder_point]'] || 0;
    
    if (Number(currentStock) < Number(reorderPoint)) {
      return 'URGENT: Current stock is below reorder point. Immediate action recommended to prevent stockouts.';
    }
    
    return 'The calculated date when you should place your next order based on current stock levels and sales rate.';
  }

  @Watch('value')
  private onValueChange(val: boolean) {
    this.dialog = val;
  }

  @Watch('dialog') 
  private onDialogChange(val: boolean) {
    this.$emit('input', val);
  }

  private close() {
    this.dialog = false;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import { EventHandler } from "@/modules/events";
import HeaderTitle from "./HeaderTitle.vue";
import ChatWithUs from "./ChatWithUs.vue";
import { LegacySupplierModel } from "@/models/legacy_supplier";
import { LegacySupplierList } from "@/collections/legacy_suppliers";
import { StoreModel } from "@/models/store";
import FeatureLockedNotify from "@/components/FeatureLockedNotify.vue";
import ActionButton from "@/components/ActionButton.vue";
import EmptyStage from "@/components/EmptyStage.vue";
import { save } from "@shopify/app-bridge/actions/ContextualSaveBar";
import { EventBus } from "@/main";

@Component({
  components: {
    HeaderTitle,
    ChatWithUs,
    FeatureLockedNotify,
    ActionButton,
    EmptyStage,
  },
})
export default class ManageSuppliers extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public success: boolean = false;
  public error: boolean = false;
  public isLoading: boolean = false;
  public suppliers: LegacySupplierList = new LegacySupplierList();
  public deletingSupplier: LegacySupplierModel = new LegacySupplierModel();
  public dialogDelete: boolean = false;
  public headers: any = [];
  @Prop({ default: false })
  public enableAthena!: boolean;
  @Prop({ default: false })
  public isDataReady!: boolean;
  @Prop({ default: false })
  public isAthenaReady!: boolean;
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  public deleteLoading: boolean = false;
  public saveLoading: boolean = false;
  public newSupplierDialog: boolean = false;
  public newSupplier: LegacySupplierModel = new LegacySupplierModel();
  public async created() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`view supplier listing page`);
    } catch (e) {
      // ignore this
    }
    this.isLoading = true;

    this.headers = [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "contactEmail",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        width: '150px',
      },
    ];
    await this.fullLoadData();
    this.isLoading = false;
  }

  public async saveSupplier() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`save supplier`, {});
    } catch (e) {
      // ignore this
    }
    this.saveLoading = true;
    if (this.newSupplier.id) {
      await this.newSupplier.update();
      EventBus.$emit('show-snackbar', 'Supplier updated successfully!');
    } else {
      await this.newSupplier.save();
      EventBus.$emit('show-snackbar', 'Supplier added successfully!');
    }
    this.newSupplier = new LegacySupplierModel();
    this.saveLoading = false;
    this.newSupplierDialog = false;
    await this.fullLoadData();
  }

  public canManageSupplier() {
    return true;
  }

  public async fullLoadData() {
    this.isLoading = true;
    this.suppliers = new LegacySupplierList();
    await this.suppliers.fetch();
    this.isLoading = false;
  }

  public editSupplier(supplier: LegacySupplierModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`click edit supplier`);
    } catch (e) {
      // ignore this
    }
    this.newSupplier = supplier;
    this.newSupplierDialog = true;
  }

  public confirmDelete(supplier: LegacySupplierModel) {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`confirm delete supplier`);
    } catch (e) {
      // ignore this
    }
    this.deletingSupplier = supplier;
    this.dialogDelete = true;
  }

  public async deleteItem() {
    try {
      const eventHandler = new EventHandler({
        store: this.store,
      });
      eventHandler.track(`delete supplier`);
    } catch (e) {
      // ignore this
    }
    this.deleteLoading = true;
    let index: number = -1;
    for (const item of this.suppliers.items) {
      index = index + 1;
      if (item.id === this.deletingSupplier.id) {
        this.suppliers.items.splice(index, 1);
        break;
      }
    }
    await this.deletingSupplier.remove();
    EventBus.$emit('show-snackbar', 'Supplier deleted successfully!');
    this.deleteLoading = false;
    this.dialogDelete = false;
  }
}

import dateFormat from 'dateformat';
import moment from 'moment';

export function convertTimeRange(timerange: string) {
  const now = moment();
  let startDate;
  let endDate;
  switch (timerange) {
    case 'today':
      startDate = now;
      endDate = now;
      break;
    case 'yesterday':
      const yesterday = now.clone().subtract(1, 'days');
      startDate = yesterday;
      endDate = yesterday;
      break;
    case '7d':
      startDate = now.clone().subtract(7, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '14d':
      startDate = now.clone().subtract(14, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '3m':
      startDate = now.clone().subtract(3, 'months');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '6m':
      startDate = now.clone().subtract(6, 'months');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '12m':
      startDate = now.clone().subtract(1, 'years');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '2y':
      startDate = now.clone().subtract(2, 'years');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '3y':
      startDate = now.clone().subtract(3, 'years');
      endDate = now.clone().subtract(1, 'days');
      break;
    case 'week-to-date':
      startDate = now.clone().startOf('week');
      endDate = now.clone().endOf('day');
      break;
    case 'month-to-date':
      startDate = now.clone().startOf('month');
      endDate = now.clone().endOf('day');
      break;
    case 'year-to-date':
      startDate = now.clone().startOf('year');
      endDate = now.clone().endOf('day');
      break;
    case 'last-week':
      startDate = now.clone().subtract(1, 'week').startOf('week');
      endDate = now.clone().subtract(1, 'week').endOf('week');
      break;
    case 'last-month':
      startDate = now.clone().subtract(1, 'month').startOf('month');
      endDate = now.clone().subtract(1, 'month').endOf('month');
      break;
    case 'last-year':
      startDate = now.clone().subtract(1, 'year').startOf('year');
      endDate = now.clone().subtract(1, 'year').endOf('year');
      break;
    case 'this-fiscal-year':
      const fiscalYear = getFiscalYearTimestamps();
      startDate = fiscalYear.start;
      endDate = fiscalYear.end;
      break;
    case '7d-last-year':
      startDate = now.clone().subtract(1, 'year').subtract(7, 'days');
      endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
      break;
    case '14d-last-year':
      startDate = now.clone().subtract(1, 'year').subtract(14, 'days');
      endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
      break;
    case '30d-last-year':
      startDate = now.clone().subtract(1, 'year').subtract(30, 'days');
      endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
      break;
    case '90d-last-year':
      startDate = now.clone().subtract(1, 'year').subtract(90, 'days');
      endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
      break;
    case '180d-last-year':
      startDate = now.clone().subtract(1, 'year').subtract(180, 'days');
      endDate = now.clone().subtract(1, 'year').subtract(1, 'days');
      break;
    case '30d':
      startDate = now.clone().subtract(30, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '90d':
      startDate = now.clone().subtract(90, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
    case '180d':
      startDate = now.clone().subtract(180, 'days');
      endDate = now.clone().subtract(1, 'days');
      break;
    case 'custom':
      break;
  }
  const range = {
    start: dateFormat(startDate?.toString(), 'yyyy-mm-dd'),
    end: dateFormat(endDate?.toString(), 'yyyy-mm-dd'),
  };
  return range;
}

function getFiscalYearTimestamps() {
  const fiscalYears: any = [
    {
      year: 2024,
      start: '2024-02-04',
      end: '2025-02-01',
    },
    {
      year: 2025,
      start: '2025-02-02',
      end: '2026-01-31',
    },
    {
      year: 2026,
      start: '2026-02-01',
      end: '2027-01-30',
    }
  ];
  const thisYear = moment().year();
  const fiscalYear = fiscalYears.find((fiscalYear: any) => {
    return thisYear === fiscalYear.year;
  });

  // Convert the start and end dates to moment date objects
  const start = moment(fiscalYear.start, 'YYYY-MM-DD');
  const end = moment(fiscalYear.end, 'YYYY-MM-DD');
  return { start, end };
}

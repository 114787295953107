import { get, post, put, del } from '@/services/http';

export class InventoryTransferRuleModel {
  public id: string = '';
  public scope: string = 'global';
  public multipleLocationTransfer: boolean = false;
  public transferableQuantityCalculation: string = 'OVERSTOCKED'; //OVERSTOCKED, OVERSTOCKED_INCOMING, AVAILABLE, AVAILABLE_INCOMING
  public transferSourceSelection: string = 'QUANTITY'; //LOCATION_TYPE, DISTANCE, QUANTITY
  public receiveSourceSelection: string = 'FORECAST_SALES, FORECAST_INVENTORY_NEED'; //FORECAST_SALES, FORECAST_INVENTORY_NEED
  public locationId: string = '';
  public transferable: boolean = true;
  public receivable: boolean = true;
  public locationType: string = 'store';
  public locationName: string = '';
  public region: string = '';
  public priority: number = 1;
  public tags: string = '';
  public historicalPeriod: string = '90d';
  public forecastTime: number = 30;
  public salesTrends: string = '0%';

  constructor(data: any = {}) {
    if (!data) return;
    this.id = data.id;
    this.scope = data.scope;
    this.multipleLocationTransfer = data.multipleLocationTransfer;
    this.transferableQuantityCalculation = data.transferableQuantityCalculation;
    this.transferSourceSelection = data.transferSourceSelection;
    this.receiveSourceSelection = data.receiveSourceSelection;
    this.locationId = data.locationId;
    this.transferable = data.transferable;
    this.receivable = data.receivable;
    this.locationType = data.locationType;
    this.locationName = data.locationName;
    this.region = data.region;
    this.priority = data.priority;
    this.tags = data.tags;
    this.historicalPeriod = data.historicalPeriod;
    this.forecastTime = data.forecastTime;
    this.salesTrends = data.salesTrends;
  }

  public mapData(data: any = {}) {
    if (!data) return;
    if (data.id && data.id !== 'null') {
      this.id = data.id;
    } else {
      this.id = '';
    }
    if (data.scope && data.scope !== 'null') {
      this.scope = data.scope;
    } else {
      this.scope = 'global';
    }

    if (data.multiple_location_transfer && data.multiple_location_transfer !== 'null') {
      this.multipleLocationTransfer = data.multiple_location_transfer;
    } else {
      this.multipleLocationTransfer = false;
    }

    if (data.transferable_quantity_calculation && data.transferable_quantity_calculation !== 'null') {
      this.transferableQuantityCalculation = data.transferable_quantity_calculation;
    } else {
      this.transferableQuantityCalculation = 'OVERSTOCKED';
    }
    if (data.transfer_source_selection && data.transfer_source_selection !== 'null') {
      this.transferSourceSelection = data.transfer_source_selection;
    } else {
      this.transferSourceSelection = 'QUANTITY';
    }
    if (data.receive_source_selection && data.receive_source_selection !== 'null') {
      this.receiveSourceSelection = data.receive_source_selection;
    } else {
      this.receiveSourceSelection = 'FORECAST_SALES, FORECAST_INVENTORY_NEED';
    }
    if (data.location_id && data.location_id !== 'null') {
      this.locationId = data.location_id;
    } else {
      this.locationId = '';
    }
    if (data.transferable && data.transferable !== 'null') {
      this.transferable = data.transferable === 'true';
    } else {
      this.transferable = true;
    }
    if (data.receivable && data.receivable !== 'null') {
      this.receivable = data.receivable === 'true';
    } else {
      this.receivable = true;
    }
    if (data.location_type && data.location_type !== 'null') {
      this.locationType = data.location_type;
    } else {
      this.locationType = 'store';
    }
    if (data.location_name && data.location_name !== 'null') {
      this.locationName = data.location_name;
    } else {
      this.locationName = '';
    }
    if (data.region && data.region !== 'null') {
      this.region = data.region;
    } else {
      this.region = '';
    }
    if (data.priority && data.priority !== 'null') {
      this.priority = data.priority;
    } else {
      this.priority = 1;
    }
    if (data.tags && data.tags !== 'null') {
      this.tags = data.tags;
    } else {
      this.tags = '';
    }
    if (data.historical_period && data.historical_period !== 'null') {
      this.historicalPeriod = data.historical_period;
    } else {
      this.historicalPeriod = '90d';
    }
    if (data.forecast_time && data.forecast_time !== 'null') {
      this.forecastTime = data.forecast_time;
    } else {
      this.forecastTime = 30;
    }
    if (data.sales_trends && data.sales_trends !== 'null') {
      this.salesTrends = data.sales_trends;
    } else {
      this.salesTrends = '0%';
    }
  }
}
    

import List from './list';
import { GoogleSpreadsheetFileModel } from '@/models/google_spreadsheet_file';
import { get } from '@/services/http'; // Assuming you have a get function in http service

export class GoogleSpreadsheetFileList extends List<GoogleSpreadsheetFileModel> {
  public async fetch(params: any): Promise<void> {
    try {
      const response: any = await get('/google_spreadsheet/file', { ...params });
      this.mapData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  public mapData(data: any[]) {
    for (const item of data) {
      const googleSpreadsheetFile = new GoogleSpreadsheetFileModel(item);
      googleSpreadsheetFile.mapData(item);
      this.add(googleSpreadsheetFile);
    }
  }
}
